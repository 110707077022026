import $ from 'jquery';
import * as func from '../components/onlyExport';

export class SingleEvent {
    constructor(){
        $('html').click(function(e) {
            //console.error('single-e:', e.target);
            $('.show-sub-menu nav ul li ul').hide();
            $('.show-sub-menu nav ul li').removeClass('selectedLink');
            $('.show-sub-menu nav ul li').removeClass('active-white-bg');
            if(func.deviceMode() == 'tablet'){
              if($(e.target).hasClass('nav-mobile')){
                $('.js-navbar-toggle-menu').trigger( "click" );
              }
              else if($(e.target).hasClass('js-search-mobile-menu')){
                $('.mobile-serach-box-click, .mbl_search').trigger( "click" );
              }              
            }
        });
    }
}