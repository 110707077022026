import $ from 'jquery';
import * as allFunc from './newsroom';

//image download popup related html code
export function downloadTxtCode(link){
    var txtCode = `<div class="imgDownload-dialog ikea-img-download-popup">
                        <div>
                          <div class="modal-header">
                            <a href="javascript:void(0)" title="Close" class="close">
                              <div class="hover-effect"></div>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="
								http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M12 13.4142L16.9497 18.3639L18.3639 16.9497L13.4142 12L18.3639 7.0502L16.9497 5.63599L12 10.5857L7.0502 5.63599L5.63599 7.0502L10.5857 12L5.636 16.9497L7.05021 18.3639L12 13.4142Z" fill="#111111"></path>
								</svg>
                            </a>
                          </div>
                          <div class="modal-body">
                            <div class="image-download-info">
                              <div class="backArrow">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99902 11.9997L11.999 20.0008L13.4133 18.5867L7.82744 13.0001H19.9999V11.0001H7.82729L13.4144 5.41328L12.0002 3.99902L3.99902 11.9997Z" fill="#111111"></path>
                                </svg>
                              </div>
                              <div class="dw-info">
                                <div class="h3">Download</div>
                                <div class="h2">USAGE RESTRICTION</div>
                                <p class="h4">THE FOLLOWING RESTRICTIONS APPLY</p>
                                <p class="small"> © Inter IKEA Systems B.V. 2019 All material, including photos and videos on this site is allowed to use for non-commercial purpose only.</p>
                                <div class="gdpr-text">
                                  <div class="field checkboxInput">
                                    <input type="checkbox" name="donotAsk" id="donotAsk">
                                    <span class="checkbox-icon"></span>
                                    <span class="checkbox-label">
                                        <label for="donotAsk"> Don't ask me again</label>
                                    </span>
                                  </div>
                                </div>
                                <div class="btn-down">
                                  <a href=`+link+` class="btn btn-primary-link btn-download">
                                     I agree
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>`
    return txtCode;
}

//An indication for content below the banner image -sprint-150
export function animatedScrollArrow(){
    var downArrowHtml = `<div class="downArrowBtn"><span class="downArrow"></span></div>`;
    var $firstEl = $('.cspace-home main > .component').find('.img-carousel-container, .ikea-card-sixty-forty-layout, .header-banner__desktop').first();
        $firstEl.parent().css("position",'relative');
        $firstEl.after(downArrowHtml);
    //screen size 
    var screenSize = $(window).width();
    $(window).on('scroll', function(){
        if(screenSize > 992){
            var nowScrollTop = $(this).scrollTop();
            if(nowScrollTop >= 100){
                $('.cspace-home').find('.downArrowBtn').css("display","none");
            }
        }
    });
};

//Serp page image and video download sp-151
export function downloadAssert($this,$pThis,e){
        if(localStorage.getItem('useRestriction') === 'true'){
            allFunc.getHref($this,e);
            allFunc.showToast('Downloading...');
        }else{
            e.preventDefault();
            $pThis.find(".ikea-img-download-popup.imgDownload-dialog").show().css("display","flex");
            $("body").css('overflow','hidden');
        }
        $pThis.find('.ikea-img-download-popup.imgDownload-dialog .btn-down > a').click(function(e){
            var isCheck =  $pThis.find('.ikea-img-download-popup.imgDownload-dialog .gdpr-text #donotAsk').is(':checked');
            if(isCheck === true){
                localStorage.setItem('useRestriction', true);
            }
            allFunc.getHref($(this),e);
            allFunc.showToast('Downloading...');
            allFunc.closeImgPopup();
    });
};


