import $ from 'jquery';
import * as func from '../components/onlyExport';


class BackToTop {
    constructor(selectors) {
        this.start = this.start.bind(this);
        this.manageBTT = this.manageBTT.bind(this);
        var cThis = this;
        var setTimer;
        this.start();
        $(window).resize(func.debounce(function(){
            cThis.start();
        },200));
        $(window).scroll(cThis.manageBTT);
        if($(".component.search-results").length > 0){
            setTimer = setInterval(function(){
                if($(".back-to-top").length == 0){
                    cThis.start();
                } else {
                    clearInterval(setTimer);
                }
            }, 1000);
        }
    }
    start(){
        var _that = this;
        var docH = $(document).height();
        var winH = func.deviceDimensions().winHeight;
        if($(".back-to-top").length > 0){
            $(".back-to-top").remove();
        }
        if(winH * 4 < docH && ($(".back-to-top").length == 0) ){
            var _btnHtml = this.bttButton();
            $(_btnHtml).appendTo("#wrapper > main");
            _that.manageBTT();
            $(".back-to-top button").click(function(){
                $("html, body").animate({ scrollTop: 0 }, 200);
                return false;
            });
        }
    }

    manageBTT(){
        if($(".back-to-top").length > 0){
            var scrollPos = $(window).scrollTop();
            if(scrollPos > func.deviceDimensions().winHeight)
                $(".back-to-top").css('display','block'); 
            else 
                $(".back-to-top").css('display','none');
        } 
    }

    bttButton(){
        return '<div class="back-to-top component"><div>' +
        '<button type="button" class="btn btn-secondary btn-leading-icon btn-expanding">' +
            '<span class="btn__copy btn-inner">' +
                '<svg focusable="false" viewBox="0 0 24 24" class="svg-icon btn__icon" aria-hidden="true">' +
                    '<path fill-rule="evenodd" clip-rule="evenodd" d="m12.0001 3.999-8.001 8 1.414 1.4143L11 7.8272v12.1727h2V7.8275l5.5866 5.5869 1.4142-1.4142-8.0007-8.0012z"></path>' +
                '</svg>' +
                '<span class="btn__label">Back to top</span>' +
            '</span>' +
        '</button>' +
        '</div></div>';
    }

    // bttButtonLink(){
    //     return '<a class="btn btn-secondary-link btn-small btn-trailing-icon ikea_arrow-right_spriteicon sprite-icon-right" href="javascript:void(0)">' +
    //     'Back to top' +
    //     '<em>' +
    //         '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg-icon">' +
    //             '<path d="M21,10V21H3V10H5v9H19V10Zm-5.29,1.29L14.29,9.88,13,11.17V3.88H11v7.29L9.71,9.88,8.29,11.29,12,15Z">' +
    //             '</path>' +
    //         '</svg>' +
    //     '</em>' +
    //     '</a>';
    // }
    
}

export default BackToTop;