import $ from 'jquery';
import * as func from '../components/onlyExport';

export class MembersPage {
    constructor() {
        this.auth_header = new Headers();
        this.auth_header.append('Accept', 'application/json');
        this.suggester_auth_token = studioConfig.connector.suggester_auth_token;
        this.search_auth_type = 'token';
        const _authToken = this.search_auth_type === 'token' ? `Token ${this.suggester_auth_token}` : `Basic ${authorization}`;
        this.auth_header.append('Authorization', _authToken);
        this.ss_url = studioConfig.connector.url;
        this.pagenum = 0;
        this.pageLast = 0;
        this._loadingSym = '<div class="ikea-loader column-content"><div class="wrap"><div class="ball"></div><div class="text">Loading your results</div></div></div>';
        this.loadType = 'ready';
        this.selectedItems = [];
        this.profUIDs = [];
        this.start();
        const _this = this;
        $(window).resize(func.debounce(function(){
            _this.start('resize');
        },200));
    }
    start(type){        
        var _that = this;

        //146 vintst membeers
        $(".ise-members .search-box input").keypress(function (e) {
            var key = e.which;
            if(key == 13)
             {
                _that.isReady();
             }
        });

        $(".search-member-filter").css('top',func.getBannersHeight('sticky')); 
        $(".selected-member-filters").css('top',func.getBannersHeight('sticky') + ($(".search-member-filter").outerHeight()));
        if(type == 'resize'){            
            return;
        }
        $('body').append(_that.appendDialog());
        $(".members-list > .component-content").html(this._loadingSym);
        setTimeout(function(){
            _that.getData();
        },300);
        $(".members-list + .load-more").click(function(){
            _that.loadType = 'noready';
            _that.pagenum += _that.pageLast;
            _that.getData();
        });
        $(".search-member input").keypress(function (e) {
            var key = e.which;
            if(key == 13)
             {
                _that.isReady();
             }
        });
        $(".search-member-filter .filter-list a").click(function(){
            var _isActive = $(this).hasClass('active');
            var _l = $(".search-member-filter .filter-list a.active").length;
            $(".search-member-filter .filter-list a.active").removeClass('active');
            if(_l == 0 || (_l == 1 && !_isActive))$(this).addClass('active');
        });   
        
    
        
    }
    appendDialog(){
        return '<div class="ikea-modal-dialog" id="expand-member-card">'+
        '<div>  '+
          '<div class="modal-header">'+
            '<a href="#" title="Close" class="close">'+
              '<div class="hover-effect"></div>'+
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.43,16.35,12,12.71l3.68,3.61.71-.71L12.74,12l3.61-3.65-.7-.7L12,11.29,8.39,7.68l-.71.71L11.33,12,7.72,15.65Z"/></svg>'+
            '</a>'+
          '</div> '+
          '<div class="modal-body"></div>'+
          '<div class="modal-footer"></div>'+
        '</div>'+
      '</div>';
    }

    
    isValid(v){
        if(typeof v == 'object')return v.join(', ');
        return (typeof v != 'undefined' && v.length > 0) ? v : '';
    }
    desktopIcons(type){
        if(type == 'left')
        return '<div class="side-nav-icon left"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.40039 12.0001L14.1854 17.7858L15.5997 16.3717L11.2286 12L15.5997 7.62835L14.1854 6.21423L8.40039 12.0001Z" fill="#111"></path></svg></a></div>';
        else 
        return '<div class="side-nav-icon right"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5996 11.9999L9.81456 17.7857L8.40026 16.3716L12.7714 11.9999L8.40026 7.62823L9.81457 6.21411L15.5996 11.9999Z" fill="#111"></path></svg></a></div>';
    }
    getData(ct){ 
        var _that = this;
        var isStart = (this.pagenum == 0) ? '' : '&start='+this.pagenum;
        var _query = ($(".search-member input").val().trim().length > 0) ? $(".search-member input").val().trim() : '*';
        $(".members-list + .load-more").addClass('running');
        $(".search-member-filter .filter-list a.active").removeClass('active');
        if(ct != 'chk'){
            $(".search-member-filter.show").removeClass('show');
        } else {
            $(".selected-member-filters .filter-list.show").removeClass('show');
        }
        var _fq = '';
        this.selectedItems.forEach((e) => {
            _fq += '&fq='+e.name+':\"'+encodeURIComponent(e.label)+'\"';
            $(".selected-member-filters a[data-fv="+e.name+"] label").text(e.label).closest('.filter-list').addClass('show');
        });
        fetch(this.ss_url+'?q='+_query+'&&hl.fragsize=200&fq=_language:%22en%22&model=Entrepreneur&language=en'+isStart+_fq, {
            method: 'GET',
            headers: this.auth_header,
            credentials: 'same-origin'
        }).then(response => response.json())
        .then(function(res){
            $(".members-list + .load-more").removeClass('running').find('input').val('More entrepreneurs');
            $(".members-list > .component-content > .ikea-loader").hide();
            $(".search-member-filter").addClass('show');
            if(_that.selectedItems.length > 0)
                $(".selected-member-filters").addClass('show');
            else 
                $(".selected-member-filters.show").removeClass('show');
            if(res.response.docs.length == 0){
                $(".members-list > .component-content").html('<h3 class="noData">No data found.</h3>');
            }
            _that.pageLast = parseInt(res.responseHeader.params.rows);
            _that.profUIDs = [];
            res.response.docs.forEach(element => {
                if(typeof element.profile_picture_t != 'undefined')
                _that.profUIDs.push(element.profile_picture_t);
                $(".members-list > .component-content").append(_that.getTemplate(element));
            });
            //console.log(_that.profUIDs);
            _that.getProfilePics();
            $('.members-list > .component-content .ikea-card').unbind('click');
            $(".members-list > .component-content .ikea-card").click(function(e){
                var _html = $(this).clone();
                $("#expand-member-card .modal-body").html(_html).closest('.ikea-modal-dialog').show();
                var _scLinks = _that.getSocialLinks($(this).data());
                $(_scLinks).insertAfter("#expand-member-card .modal-body .ikea-card h3");
            });
            if(_that.loadType == 'ready'){
                _that.renderFilters(res.facet_counts.facet_fields);
            }
            if(res.response.numFound <= _that.pagenum + _that.pageLast){
                $(".members-list + .load-more.show").removeClass('show');
            } else {
                $(".members-list + .load-more").addClass('show');
            }
        }).catch(error => console.log(error));
    }
    getProfilePics(){
        var allUIDs = this.profUIDs.toString();
        func.callAjax(
            window.location.href,
            {scController: "MemberProfile", scAction: "GetProfileImageUrl", userID: allUIDs},
            'json',
            'POST',
            function(res,data){
                data = JSON.parse(data);
                data.forEach(function(cv){
                    if($.trim(cv.ImageURL) != ''){
                        $(".imgurl_"+chkUserID(cv.UserId)).attr('src',cv.ImageURL);
                    }
                });
            }
        );
    }
    renderFilters(f){
        var _that = this;
        (async() => {
            await _that.repeatData(f.country_sm,'entrepreneurcountry_s');
            await _that.repeatData(f.sector_sm,'sector_sm');    
            await _that.repeatData(f.targetgroup_sm,'targetgroup_sm');   
            await _that.repeatData(f.programmes_sm,'programmes_sm');
            _that.selectedItems.forEach( (e) => {
                $("a[data-fv="+e.name+"] + .dropdown-list input").each(function(){
                    if($(this).val() == e.label)$(this).prop('checked',true);
                });
            });
        })();
        
    }
    repeatData(arr,elem){
        var _htm = '';
        var _that = this;
        for(let i= 0; i<arr.length; i++){
            _htm += _that.getRowData(arr[i], arr[i+1],elem);
            i++;
            if( i+1 == arr.length){
                _htm += '<div class="row close-ddl"><object class="component link btn btn-small btn-primary-link">Close</object></div>';
            }
        }
        $("a[data-fv="+elem+"] + .dropdown-list").html(_htm).find('input,.close-ddl').click(function(e){
            if( $(e.currentTarget).hasClass('close-ddl') ){
                $(".search-member-filter .filter-list a.active").click();
                return;
            }         
            
            _that.selectedItems = [];          
            $(".checkboxInput input").each(function(){                
                if($(this).prop('checked')){
                    _that.selectedItems.push({ name: this.name, label: this.value });
                }
            });       
            _that.isReady('chk');
            _that.moveToElem($(".search-member-filter"));
        });        
    }
    handleSelectedPillIcons(){
        if(func.deviceMode() == 'desktop'){
            setTimeout(function(){
                if( $("#f_firstfilter").length == 0)return true;
                var _wrapper = $(".selected-member-filters").width();
                var _ww = func.deviceDimensions().winWidth;
                var _checkL = (_ww - _wrapper) / 2;  
                var _checkR = _checkL + _wrapper - ( $("#f_clear_all").outerWidth() );  
                var _left = $("#f_firstfilter").offset().left;
                var _right = $("#f_clear_all").offset().left;
                $(".selected-member-filters > .component-content .side-nav-icon").removeClass('show');
                if( _left < _checkL){
                    $(".selected-member-filters > .component-content .side-nav-icon.left").addClass('show');
                }
                if((_right - 1) > _checkR){
                    $(".selected-member-filters > .component-content .side-nav-icon.right").addClass('show');
                }
            },600);
        }
    }
    moveToElem($elem){
        var _that = this;
        $(".selected-member-filters > .component-content").html(_that.desktopIcons('left'));
        this.selectedItems.forEach((si,i) => {
            $(".selected-member-filters > .component-content").append(_that.appendSelectedItem(si,i));
        });
        $(".selected-member-filters > .component-content").append(_that.appendSelectedItem({name : 'clear_all', label : 'Clear all'}));
        $(".selected-member-filters > .component-content").append(_that.desktopIcons('right'));
        _that.handleSelectedPillIcons();        
        $(".selected-member-filters > .component-content .filter-list, .selected-member-filters > .component-content .side-nav-icon").unbind('click');
        $(".selected-member-filters > .component-content .filter-list").click(function(){                      
            const newArr = _that.selectedItems.filter(object => {
                return object.label !== $(this).data('fv');
            });
            _that.selectedItems = newArr;
            if($(this).data('fn') == 'clear_all')_that.selectedItems = [];
            _that.isReady('chk');
            _that.moveToElem($(".search-member-filter"));
        });
        $(".selected-member-filters > .component-content .side-nav-icon").click(function(){
            var _isLeft = $(this).hasClass('left');
            if(!_isLeft){
                $('.selected-member-filters > .component-content').animate({
                    scrollLeft: 500
                }, 'slow');
            } else {
                $('.selected-member-filters > .component-content').animate({
                    scrollLeft: -500
                }, 'slow');
            }
            _that.handleSelectedPillIcons();
        });
        $([document.documentElement, document.body]).animate({
            scrollTop: ($elem.offset().top) - 400
        }, 500);
    }

    isReady(ct){
        this.pagenum = 0;
        $(".members-list > .component-content").html(this._loadingSym);
        this.loadType = 'ready';
        $(".members-list + .load-more.show").removeClass('show');
        this.getData(ct);
    }
    appendSelectedItem(e,i){
        var _id = (i == 0) ? 'f_firstfilter' : 'f_'+e.name;
        return '<div class="filter-list" id="'+_id+'" data-fn="'+e.name+'" data-fv="'+e.label+'">'+
                '<a class="btn btn-secondary-link btn-small btn-trailing-icon" data-fv="'+e.name+'" href="javascript:void(0)">'+
                    '<label>'+e.label+'</label>'+
                    '<em>'+
                        '<svg focusable="false" class="svg-icon " width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">'+
                            '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 13.4144L16.9499 18.3642L18.3642 16.9499L13.4144 12.0002L18.3642 7.05044L16.95 5.63623L12.0002 10.586L7.05044 5.63623L5.63623 7.05044L10.586 12.0002L5.63624 16.9499L7.05046 18.3642L12.0002 13.4144Z"></path>'+
                        '</svg>'+
                    '</em>'+
                '</a>'+
                '</div>';
    }

    getRowData(lbl,num,ename){
        return '<div class="row">'+
        '<label for="chk'+lbl+'">'+lbl+'</label>'+
        '<label for="chk'+lbl+'">'+num+'</label>'+
        '<div class="checkboxInput">'+
            '<input type="checkbox" id="chk'+lbl+'" name="'+ename+'" value="'+lbl+'"/>'+
            '<span class="check-black checkbox-icon"></span>'+
        '</div>   '+                     
        '</div>';
    }
    getSocialLinks(data){
        var _ret = '';
        if(data.lin != 'undefined' && typeof data.lin !== 'undefined'){
            _ret += '<li class="item0 odd">'+
                '<div class="link-list__link field-link">'+
                    '<div>'+
                        '<a class="icon icon--linkden sprite-icon-left ikea_linkedin-share-icon_spriteicon" href="'+data.lin+'" target="_blank" title=""> <i>'+
                            '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.23 17H7.16v-6.68h2.08V17zM8.2 9.41c-.67 0-1.2-.54-1.2-1.2C6.99 7.54 7.53 7 8.2 7a1.206 1.206 0 0 1 0 2.41zM17.01 17h-2.07v-3.25c0-.77-.01-1.77-1.08-1.77-1.08 0-1.25.84-1.25 1.72V17h-2.07v-6.68h1.99v.91h.03c.28-.53.95-1.08 1.96-1.08 2.1 0 2.49 1.38 2.49 3.18V17z"></path></svg>'+
                        '</i></a> '+
                    '</div>'+
                '</div>'+
            '</li>';
        }
        if(data.email != 'undefined' && typeof data.email !== 'undefined'){
            _ret += '<li class="item1 even">'+
                '<div class="link-list__link field-link">'+
                    '<div>'+
                        '<a class="icon icon--email sprite-icon-left ikea_email_spriteicon" href="mailto:'+data.email+'" target="" title=""> <i>'+
                            '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2,5V19H22V5ZM6.37,7H17.63L12,10.8ZM4,17V7.87l8,5.33,8-5.33V17Z"></path></svg>'+
                            '</i> '+
                        '</a>'+
                    '</div>'+
                '</div>'+
            '</li>';
        }
        if(data.web != 'undefined' && typeof data.web !== 'undefined'){
            _ret += '<li class="item2 odd">'+
                '<div class="link-list__link field-link">'+
                    '<div>'+
                        '<a class="icon icon--linkden sprite-icon-left ikea_linkedin-share-icon_spriteicon" href="'+data.web+'" target="_blank" title=""> <i>'+
                            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">'+
                            '<path fill-rule="evenodd" clip-rule="evenodd" d="M13.7467 18.1766C12.9482 19.7737 12.2151 20 12 20c-.2151 0-.9482-.2263-1.7467-1.8234-.3065-.6131-.5745-1.3473-.7831-2.1766h5.0596c-.2086.8293-.4766 1.5635-.7831 2.1766zM14.8885 14h-5.777A17.7354 17.7354 0 0 1 9 12c0-.6949.0392-1.3641.1115-2h5.777c.0723.6359.1115 1.3051.1115 2 0 .6949-.0392 1.3641-.1115 2zm1.6955 2c-.2658 1.2166-.6492 2.307-1.1213 3.2138A8.0347 8.0347 0 0 0 18.9297 16H16.584zm3.164-2H16.9c.0656-.6462.1-1.3151.1-2 0-.6849-.0344-1.3538-.1-2h2.848A8.0156 8.0156 0 0 1 20 12a8.0156 8.0156 0 0 1-.252 2zm-.8183-6a8.035 8.035 0 0 0-3.467-3.2138c.4721.9068.8555 1.9972 1.1213 3.2138h2.3457zm-4.3999 0c-.2086-.8293-.4766-1.5635-.7831-2.1766C12.9482 4.2264 12.2151 4 12 4c-.2151 0-.9482.2263-1.7467 1.8234-.3065.613-.5745 1.3473-.7831 2.1766h5.0596zM7.416 8c.2658-1.2166.6491-2.307 1.1213-3.2138A8.035 8.035 0 0 0 5.0703 8H7.416zm-3.164 2A8.0147 8.0147 0 0 0 4 12c0 .6906.0875 1.3608.252 2H7.1a19.829 19.829 0 0 1-.1-2c0-.6849.0344-1.3538.1-2H4.252zm3.164 6H5.0704a8.0347 8.0347 0 0 0 3.467 3.2138C8.0651 18.307 7.6818 17.2166 7.4161 16zM22 12c0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10 5.5228 0 10-4.4772 10-10z" fill="#111"/>'+
                            '</svg>'+
                        '</i></a> '+
                    '</div>'+
                '</div>'+
            '</li>';
        }

        if(_ret != ''){
            return '<div class="component link-list social-share-link-list">'+
                        '<div class="component-content">'+
                            '<ul>'+_ret+'</ul>'+
                        '</div>'+
                    '</div>';
        } else {
            return '';
        }
    }

    getTemplate(d){
        var _article = '';
        _article += '<article class="component ikea-card" style="background:#F5F5F5" data-email="'+d.workemail_t+'" data-web="'+d.website_t+'" data-lin="'+d.linkedin_profile_t+'">'+
        '<div class="component-content">'+
            '<a title="'+this.isValid(d.fullname_t)+'">'+            
                '<figure><img src="/~/media/avatar-placeholder" class="imgurl_'+chkUserID(d.profile_picture_t)+'"></figure>'+
                '<div class="card-content">'+
                    '<p class="tag">'+this.isValid(d.job_title_t)+' at '+this.isValid(d.company_t)+'</p>'+
                    '<h3>'+this.isValid(d.fullname_t)+'</h3>';
                    if(this.isValid(d.about_me_t) != ''){
                        _article +='<p class="description field-about-me">'+this.isValid(d.about_me_t)+'</p>';
                    }                    
                    if(this.isValid(d.country_sm) != ''){
                        _article += '<p class="country"><strong>Country</strong><label>'+this.isValid(d.country_sm)+'</label></p>';
                    }
                    if(this.isValid(d.sector_sm) != ''){
                        _article += '<p class="sector"><strong>Sector</strong><label>'+this.isValid(d.sector_sm)+'</label></p>';
                    }
                    if(this.isValid(d.targetgroup_sm) != ''){
                        _article += '<p class="target"><strong>Target group</strong><label>'+this.isValid(d.targetgroup_sm)+'</label></p>';
                    }
                    if(this.isValid(d.programmes_sm) != ''){
                        _article += '<p class="programme"><strong>Programme</strong><label>'+this.isValid(d.programmes_sm)+'</label></p>';
                    }
                    _article += '<object class="component link btn btn-small btn-primary-link">Read more</object>'+
                '</div>'+
            '</a>'+
        '</div>'+
        '</article>';
        return _article;
    }   
}
function chkUserID(uid){
    if(typeof uid != undefined && uid != null && uid.length > 0){
        return uid.split(".").join("");
    } else {
        return;
    }
}
export class RecentlyMembers {
    constructor() {        
        this.profUIDs = [];
        this.start();
        this.ti;
    }
    start(){
        var $elem = $(".search-results.entrepreneur-members");
        var _that = this;
        this.ti = setInterval(function(){
            _that.isMembersLoaded($elem);
        }, 1000);
    }
    isMembersLoaded($elem){
        var _that = this;
        if( $elem.find('img').length > 0){
            $elem.find('.ikea-card').each(function(){
                var _uid = $(this).find('.memeberid .field-profile-picture').text().trim();
                if(_uid.length > 0){
                    _that.profUIDs.push(_uid);
                    $(this).find('img').addClass('getProfile_'+chkUserID(_uid));
                }
            });
            _that.getProfUrls(_that.profUIDs);
            clearInterval(_that.ti);
        }
    }
    getProfUrls(uids){
        
        if(uids.length == 0){
            console.log('No memeber ids found.');
            return;
        }
        var allUIDs = uids.toString();
        func.callAjax(
            window.location.href,
            {scController: "MemberProfile", scAction: "GetProfileImageUrl", userID: allUIDs},
            'json',
            'POST',
            function(res,data){
                
                data = JSON.parse(data);
                data.forEach(function(cv){
                    if(cv.ImageURL.trim() != ''){
                        $(".getProfile_"+ chkUserID(cv.UserId)).attr('src',cv.ImageURL);
                    }
                });
            }
        );
    }

}
$(document).on('click', '.facet-single-selection-list .component-content .facet-heading', function(e) {
     var _isActive = $(this).hasClass('active');
     var _l = $(".facet-single-selection-list .component-content .facet-heading.active").length;
     $(".facet-single-selection-list .component-content .facet-heading.active").removeClass('active');
     if(_l == 0 || (_l == 1 && !_isActive))$(this).addClass('active');
 });

 $(document).on('click', '.search-result-list > li .ikea-card', function(e) {
    //e.preventDefault();
    if($('#mempopup').length > 0){
        var _html = $(this).clone();
        $(".ise-members #mempopup .modal-body").html(_html).closest('.ikea-modal-dialog').css('display','flex');
        $("body").css('overflow','hidden');
    }
    
});

$(function() {
    $(".ise-members .facet-summary > .component-content, .ise-members .search-facets > .component-content, .enterprises-page .facet-summary.facet-list-summary > .component-content, .enterprises-page .search-facets > .component-content").prepend(desktopIcons('left'));
    $(".ise-members .facet-summary > .component-content, .ise-members .search-facets > .component-content, .enterprises-page .facet-summary.facet-list-summary > .component-content, .enterprises-page .search-facets > .component-content").append(desktopIcons('right'));  
    function desktopIcons(type){
        if(type == 'left')
        return '<div class="side-nav-icon ise-ss-icon left"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.40039 12.0001L14.1854 17.7858L15.5997 16.3717L11.2286 12L15.5997 7.62835L14.1854 6.21423L8.40039 12.0001Z" fill="#111"></path></svg></a></div>';
        else 
        return '<div class="side-nav-icon ise-ss-icon right"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5996 11.9999L9.81456 17.7857L8.40026 16.3716L12.7714 11.9999L8.40026 7.62823L9.81457 6.21411L15.5996 11.9999Z" fill="#111"></path></svg></a></div>';
    }
    function appendpopDialog() {
        return `<div class="ikea-modal-dialog" id="mempopup">
                    <div>
                        <div class="modal-header">
                            <h3>Entrepreneur profile</h3>
                            <a href="#" title="Close" class="close"><div class="hover-effect"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.43,16.35,12,12.71l3.68,3.61.71-.71L12.74,12l3.61-3.65-.7-.7L12,11.29,8.39,7.68l-.71.71L11.33,12,7.72,15.65Z"/></svg>
                            </a>
                        </div>
                        <div class="modal-body"></div>
                        <div class="modal-footer"></div>
                    </div>
                </div>`;
      }

      if( $(".ise-members").length > 0 && $("#mempopup").length == 0){
        $('body').append(appendpopDialog());
    }
    $(document).on('click', function(event) {
        if (!$(event.target).closest('.facet-single-selection-list .component-content .facet-heading.active').length) {
            $('.facet-single-selection-list .component-content .facet-heading.active').removeClass('active');
        }
    });
    $(".enterprises-page .facets-pills").css('top',func.getBannersHeight('sticky')); 
    $(window).resize(func.debounce(function(){
        $(".enterprises-page .facets-pills").css('top',func.getBannersHeight('sticky')); 
    },200));
    
});
