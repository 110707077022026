import $ from 'jquery';
import createFocusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { StateClasses, ESC_KEYCODE } from './constants';
import * as func from '../components/onlyExport';

function isEmpty($e) {
  return $e && $e.length === 0;
}

export default class NavMenu {
  constructor(selectors) {
    this.selectors = selectors;
    this.$navbar = $(this.selectors.navbar);
    this.$focusTrap = this.$navbar.find(this.selectors.focusTrap);

    this.isVisible = false;
    this.isSearchVisible = false;

    // Create a backdrop element
    this.$backdrop = $(`<div class="${selectors.backdrop.replace('.', '')}">`);
    const $body = $('body');
    $body.append(this.$backdrop);

    this.handleToggleModal = this.handleToggleModal.bind(this);

    this.handlesearchToggleModal = this.handlesearchToggleModal.bind(this);

    if (!isEmpty(this.$focusTrap)) {
      this.focusTrap = createFocusTrap(this.$focusTrap[0], {
        escapeDeactivates: false,
        clickOutsideDeactivates: true
      });
    }
    $(window).resize(func.debounce(this.ifMoreItems,200));
    this.memberLogin();
  }

  memberLogin(){
    if($(".mob-member-login a").length > 0){
      $(".desk-member-login svg").click(function(){
        window.location.href = $(".desk-member-login a").attr('href');
      });
      $('.mob-member-login').clone().addClass('menu-extends').insertBefore('.nav-mobile .navigation');
    }
  }

  ifMoreItems(){
    $(".nav-desktop-sections.if-more-items").removeClass('if-more-items');
    $(".js-navbar.no-animate").removeClass('no-animate');
    const clientWidth = ($(".nav-desktop-sections > .component-content").outerWidth()) + 5;
    //console.error('ow: '+clientWidth);
    let allItemsWidth = 0;
    $(".nav-desktop-sections nav > ul > li").each(function(){
      //console.error($(this).outerWidth());
      allItemsWidth = allItemsWidth + $(this).outerWidth();
    });
    //console.error(' == '+allItemsWidth);
    if(clientWidth <  allItemsWidth){
      $(".nav-desktop-sections").addClass('if-more-items');
      $(".js-navbar").addClass('no-animate');
    }   
  }

  handleToggleModal() {
    const $mobileMenu = this.$navbar.find(this.selectors.mobileMenu);
    const $toggleMenu = this.$navbar.find(this.selectors.toggleMenu);
   
    this.isVisible = !this.isVisible;

    $mobileMenu.toggleClass(StateClasses.IS_VISIBLE, this.isVisible);
   
    $toggleMenu.attr({ 'aria-expanded': this.isVisible ? 'true' : 'false' });
    
    this.$backdrop.toggleClass(StateClasses.IS_VISIBLE, this.isVisible);
    this.$backdrop.removeClass(StateClasses.IS_SEARCHVISIBLE, this.isSearchVisible);

    if (this.isVisible) {
      disableBodyScroll($mobileMenu[0]);
      this.focusTrap && this.focusTrap.activate();
    } else {
      enableBodyScroll($mobileMenu[0]);
      this.focusTrap && this.focusTrap.deactivate();
    }

  }

  handlesearchToggleModal() {
    const $searchMenu = this.$navbar.find(this.selectors.searchMenu);
    const $toggleSearch = this.$navbar.find(this.selectors.toggleSearch);
    
    this.isSearchVisible = !this.isSearchVisible;

    $searchMenu.toggleClass(StateClasses.IS_SEARCHVISIBLE, this.isSearchVisible);

    $toggleSearch.attr({ 'aria-expanded': this.isSearchVisible ? 'true' : 'false' });
    
    this.$backdrop.toggleClass(StateClasses.IS_SEARCHVISIBLE, this.isSearchVisible);
    this.$backdrop.removeClass(StateClasses.IS_VISIBLE, this.isVisible);

    if (this.isSearchVisible) {
      disableBodyScroll($searchMenu[0]);
      this.focusTrap && this.focusTrap.activate();
    } else {
      enableBodyScroll($searchMenu[0]);
      this.focusTrap && this.focusTrap.deactivate();
    }
  }

  initializeA11yAttributes() {
    const {
      mobileMenu,
      searchMenu,
      toggleMenu,
      toggleSearch,
      primaryNavLabel,
      secondaryNavLabel,
      sectionsNav,
      subsectionsNav
    } = this.selectors;

    const $mobileMenu = this.$navbar.find(mobileMenu);
    const $searchMenu = this.$navbar.find(searchMenu);
    const $toggleMenu = this.$navbar.find(toggleMenu);
    const $toggleSearch = this.$navbar.find(toggleSearch);

    if (isEmpty($mobileMenu) || isEmpty($searchMenu) || isEmpty($toggleMenu) || isEmpty($toggleSearch)) return;

    // Set the aria-controls property for the toggle
    const menuId = $toggleMenu.attr('aria-controls');
    $mobileMenu.attr('id', menuId);

    const searchId = $toggleSearch.attr('aria-controls');
    $searchMenu.attr('id', searchId);

    // Set the aria-label to the nav tags
    const primaryLabelValue = this.$navbar.data(primaryNavLabel);
    const secondaryLabelValue = this.$navbar.data(secondaryNavLabel);
    if (primaryLabelValue) {
      this.$navbar
        .find(`${sectionsNav} nav`)
        .attr('aria-label', primaryLabelValue);
      $mobileMenu.find('nav').attr('aria-label', primaryLabelValue);
      $searchMenu.find('nav').attr('aria-label', primaryLabelValue);
    }
    if (secondaryLabelValue) {
      this.$navbar
        .find(`${subsectionsNav} nav`)
        .attr('aria-label', secondaryLabelValue);
    }
  }
 
  start() {
    if (isEmpty(this.$navbar)) {
      console.log('Missing navbar item');
      return;
    }

    this.initializeA11yAttributes();
    
    this.$navbar.on('click', this.selectors.toggleSearch, this.handlesearchToggleModal);
    
    this.$navbar.on('click', this.selectors.toggleMenu, this.handleToggleModal);

    
    // Need to listen for touchstart on iOS
    $(document).on(
      'click touchstart',
      this.selectors.backdrop,
      this.handleToggleModal,
      this.handlesearchToggleModal
    );
    $(document).keyup(e => {
      if (e.keyCode === ESC_KEYCODE && this.isVisible && this.isSearchVisible) {
        this.handleToggleModal();
        this.handlesearchToggleModal();
      }
      if(e.keyCode === 9){
        if(func.afCloseGet()){
          $('.ikea-modal-dialog.side-panel.showSheet a.close').focus();
          func.afCloseSet(false);
        }
      }
    });
  }
}

/***************************************************  Main Navigation with search results **********************************************************************/

$(function() { 
  
  if($('.navigation.nav-mobile.js-navbar-mobile-menu').hasClass('show-mobile-sub-menu')){
    //leave it as expected
  }else{
    $(".nav-mobile.js-navbar-mobile-menu nav > ul > li ul").remove();
  }
  //hide the below code in sprint 39 for removing sub navigation
 
  $(".show-sub-menu nav > ul > li:has(> ul) > a").attr("href", "javascript:void(0)");

  
    
  $(".show-mobile-sub-menu nav > ul > li:has(> ul) > button a").attr("href", "javascript:void(0)");

  $(".show-mobile-sub-menu nav > ul > li:not(:has(> ul)) > button svg").remove();
  //$(".nav-mobile nav > ul > li ul").remove();
  //$(".nav-mobile.show-mobile-sub-menu nav > ul > li ul").add();

  $('.show-sub-menu nav > ul > li > a').click(function(e) {
    $(this).parent().addClass('selectedLink').siblings().removeClass('selectedLink');
    $(this).siblings('.show-sub-menu nav ul li ul').toggle();
    $('.show-sub-menu nav ul li ul').not($(this).siblings()).hide();

    //Set other active tab should be white background 
    if($('.show-sub-menu nav > ul').parent().find( 'li.level1.active' ).length != 0){
      $('.show-sub-menu nav > ul').parent().find( 'li.level1.selectedLink' ).removeClass('selectedLink').addClass('active-white-bg').siblings().removeClass('active-white-bg');
      $('.show-sub-menu nav > ul').parent().find( 'li.level1.active.active-white-bg' ).removeClass('active-white-bg');
      $(".show-sub-menu nav > ul > li.active-white-bg:not(:has(> ul))").css({"line-height":"4.4375rem"});
      $(".js-navbar.is-sticky .show-sub-menu nav > ul > li.active-white-bg:not(:has(> ul))").css({"line-height":"3rem"});
      
      
    }
    e.stopPropagation();
  });

  // $('html').click(function(e) {
  //   $('.show-sub-menu nav ul li ul').hide();
  //   $('.show-sub-menu nav ul li').removeClass('selectedLink');
  //   $('.show-sub-menu nav ul li').removeClass('active-white-bg');
  // });

  //hide the below code in sprint 39 for removing sub navigation
  /*
    $('.main-nav-desktop nav > ul > li > a').click(function(e) {
      $(this).parent().addClass('selectedLink').siblings().removeClass('selectedLink');
      $(this).siblings('.main-nav-desktop nav ul li ul').toggle();
      $('.main-nav-desktop nav ul li ul').not($(this).siblings()).hide();

      //Set other active tab should be white background 
      if($('.main-nav-desktop nav > ul').parent().find( 'li.level1.active' ).length != 0){
        $('.main-nav-desktop nav > ul').parent().find( 'li.level1.selectedLink' ).removeClass('selectedLink').addClass('active-white-bg').siblings().removeClass('active-white-bg');
      }
      e.stopPropagation();
    });

    $('html').click(function() {
      $('.main-nav-desktop nav ul li ul').hide();
      $('.main-nav-desktop nav ul li').removeClass('selectedLink');
      $('.main-nav-desktop nav ul li').removeClass('active-white-bg');
    });
    
    $(".main-nav-desktop nav > ul > li:has(> ul) > a").attr("href", "javascript:void(0)");
  */
  
  //menu search bar visible with content
  $('.expand-serach-box-click').click(function(e) {
    $('.expand-serach-box').show().find('.tt-input').focus();
    $('.expand-serach-box').find('.input-wrap.input-wrap--filled-blue-search').addClass('input-wrap--focus');
    $('.nav-desk-expand-serach-box, .expand-serach-box-click, .expand-search-show, .desk-member-login a').hide();
    e.preventDefault();
  });

  $(".expand-serach-box .search-box-input").focusout(function(event){
    if(event.relatedTarget == null){
      //$(".expand-serach-box .search-dropdown").hide();
    }
  }).focusin(function(){
    var chkLength = $(".tt-input").val();
    if( $.trim(chkLength).length == 0){
      $(".expand-serach-box .search-dropdown").show();
    }

  });

  $('.expand-serach-box .js-search-clear, .js-search-mobile-menu .js-search-clear').click(function(){
    $(".tt-input").val('');
    $(this).closest('.input-wrap--filled-blue-search').removeClass('has-text-value');
    $('.search-actions').css({'display':'none'}); 
    $('.expand-serach-box').show().find('.tt-input').focus();
    $('.js-search-mobile-menu').find('.search-dropdown').show();
    $('.js-search-mobile-menu').find('.tt-input').focus();
  })
 
 

  //Detect click outside of block from navigation search
  $(document).mousedown(function(e){
    var $target = $(e.target);
    var $target_dropdown = $target.closest('.search-dropdown');
    var $target_input = $target.closest('.search-box-input');
    var $target_block = $target.closest('.expand-serach-box-click');
    
    if($target_dropdown.length ==0 && $target_input.length ==0){
      $('.expand-serach-box .search-dropdown').hide();
    }
  });

  //Set close the search section
  $('.search-close').click(function(e){
    $('.nav-desk-expand-serach-box, .expand-serach-box-click, .expand-search-show, .desk-member-login a').show();
    $('.expand-serach-box').hide();
  });

  
  $('.expand-serach-box .search-box-input ').on('keyup',function(event){
    if($(this).val().length == 0){
      $('.expand-serach-box .search-dropdown').show();
    }else{
      $('.expand-serach-box .search-dropdown').hide();
    }
  });

  function svgSpriteURL(){
    var cLoc = window.location.hostname;
    if(cLoc == 'localhost'){
      return '/assets/aboutikea/svg/symbol/svg-sprite.svg';
    } else {
      return '/-/media/Themes/NL/AboutIKEA/AboutIKEA/svg/symbol/svg-sprite.svg';
    }
  }

  function appendIconToAnchorLink(svgType){
    var svgUrl = svgSpriteURL();
    var _html = '<i> <svg class="svg-icon">';    
    _html += '<use xlink:href="'+svgUrl+'#'+svgType+'"></use>';
    _html += '</svg></i>';
    return _html;
  }  

  
  function checkIsAppendedExtIcon($elem, clsName) {
    $($elem).each(function(){
      if ($(this).find('i').length != 0 || $(this).find('em').length > 0) {
      } else {
      $(this).append(appendIconToAnchorLink(clsName));
      }
    })
  } 
  //Set dynamically Added the external link right arrow
  checkIsAppendedExtIcon($('.arrow-right'),'arrow-right');
  checkIsAppendedExtIcon($('.external-icon-rte'),'external-link');

  //Search button disabled
  $(function() {    
    serInputCount();   
});
  
  function serInputCount(){
    $('.tt-input').on('keyup',function(event){
      if($(this).val().length !=0){
        $(this).closest('.input-wrap--filled-blue-search').addClass('has-text-value');
        $('.search-actions').css({'display':'block'});    
      }
      else{
        $(this).closest('.input-wrap--filled-blue-search').removeClass('has-text-value');
          $('.search-actions').css({'display':'none'}); 
      }
    });
  }    

  //set if search box suggestion empty  
  var str = $('.search-dropdown  .suggestion-list').text();
  if($.trim(str).length == 0) {
     $('.search-dropdown .suggestion-list').hide();
     $('.search-dropdown').css({'padding-top':'0'});
   }

  
  /************************* Tab and Mobile related search with results *******************************************/
  $('.js-search-mobile-menu .search-box-input').on('keyup input',function(event){
    if($(this).val().length == 0){
        $('.js-search-mobile-menu .search-dropdown').show();
        $(this).closest('.input-wrap--filled-blue-search').removeClass('has-text-value');
        
    }else{
        $('.js-search-mobile-menu .search-dropdown').hide();
        $(this).closest('.input-wrap--filled-blue-search').addClass('has-text-value');
    }
  });

  $('.mobile-serach-box-click, .mbl_search').click(function(){
    var checkL = $(".nav-mobile.is-visible").length;  
    if(checkL > 0){
      $('.js-navbar-toggle-menu').trigger( "click" );
    } 
  });

  $('.js-navbar-toggle-menu').click(function(){
    var checkL = $(".mobile-search-tab .navbar__mobile-region .nav-mobile-1.is-searchVisible").length;  
    if(checkL > 0){
      $('.mobile-serach-box-click, .mbl_search').trigger( "click" );
    } 
  });

  
 

  /* set search watermark */
  function handleSearchPlaceholder(len,labelClass){
    // console.log(len);
    if(len > 0){
      $(labelClass).text('');
    } else {
      $(labelClass).text('Search');
    }
  }
 
  //Main Navigation Search clear
  $(".expand-serach-box .input-wrap--filled-blue-search input[type='search']").keyup(function(){
    var lblClass = ".expand-serach-box .input-wrap label.input-wrap__placeholder";
    handleSearchPlaceholder(this.value.length,lblClass);
  }).focusout(function(){
    var lblClass = ".input-wrap label.input-wrap__placeholder";
    handleSearchPlaceholder(this.value.length,lblClass);
    $('.expand-serach-box').find('.input-wrap.input-wrap--filled-blue-search').removeClass('input-wrap--focus input-wrap--labelled');
  }).focusin(function(){
    $('.expand-serach-box').find('.input-wrap.input-wrap--filled-blue-search').addClass('input-wrap--focus');
  });

  //Mobile Navigation Search clear
  $(".mobile-search-tab .navbar__mobile-region input[type='search']").keyup(function(){
    var lblClass = ".mobile-search-tab .navbar__mobile-region .nav-mobile-1 .input-wrap label";
    handleSearchPlaceholder(this.value.length,lblClass);
  }).focusout(function(){
    var lblClass = ".mobile-search-tab .navbar__mobile-region .nav-mobile-1 .input-wrap label";
    handleSearchPlaceholder(this.value.length,lblClass);
    $('.mobile-search-tab').find('.input-wrap.input-wrap--filled-blue-search').removeClass('input-wrap--focus input-wrap--labelled');
  }).focusin(function(){
    $('.mobile-search-tab').find('.input-wrap.input-wrap--filled-blue-search').addClass('input-wrap--focus');
  });

  //Navigation Search clear
  $(".search-box--sturdy .input-wrap--filled-blue-search input[type='search']").keyup(function(){
    var lblClass = ".search-box--sturdy .input-wrap label.input-wrap__placeholder";
    handleSearchPlaceholder(this.value.length,lblClass);
  }).focusout(function(){
    var lblClass = ".input-wrap label.input-wrap__placeholder";
    handleSearchPlaceholder(this.value.length,lblClass);
    $('.search-box--sturdy').find('.input-wrap.input-wrap--filled-blue-search').removeClass('input-wrap--focus input-wrap--labelled');
  }).focusin(function(){
    $('.search-box--sturdy').find('.input-wrap.input-wrap--filled-blue-search').addClass('input-wrap--focus');
  });

  $(".search-page-main-body .input-wrap--filled-blue-search input[type='search']").keyup(function(){

  });

  $(".search-page-main-body .search-box--sturdy input[type='search']").on('keyup focusin focusout', function(e) {
    $(".search-page-main-body").find('.no-results').html('');
});
  
}); 

$('.search-box--sturdy .search-actions .js-search-clear').click(function(){
  $(".tt-input").val('');
  $(this).closest('.input-wrap--filled-blue-search').removeClass('has-text-value');
  $('.search-actions').css({'display':'none'}); 
 $('.search-box--sturdy').find('.tt-input').focus();
  if($('.search-page').length > 0){
    window.location.hash = '';
  }
  if($('.ise-members').length > 0){
   let hash = window.location.hash.substring(1);
   let params = new URLSearchParams(hash);
   params.delete("search_q");
   window.location.hash = params.toString();
  }
});

$(function () {
  if( $('.tt-input').length > 0){
    var text_val = $('.tt-input').val();
    //if (text_val !== undefined || text_val !== null || text_val !== '') {
    if (text_val.length > 0) {
        $('.search-box--sturdy .input-wrap--filled-blue-search').addClass('has-text-value');
        $('.search-actions').css({'display':'block'});
    } else {
      $('.search-box--sturdy .input-wrap--filled-blue-search').removeClass('has-text-value');
      $('.search-actions').css({'display':'none'});
    }
    //}
  }
});