import $ from 'jquery';
import * as func from '../components/onlyExport';

export class SlideShow {
    constructor(selectors) {
        this.start = this.start.bind(this);
        this.manageSlides = this.manageSlides.bind(this);
        this.handleIcons = this.handleIcons.bind(this);
        this.centerIcons = this.centerIcons.bind(this);
        this.hoverEffects = this.hoverEffects.bind(this);
        var _this = this;
        this.touch = {l:false,r:false};
        $(window).resize(func.debounce(function(){
            _this.centerIcons();
        },400)); 
        this.start();
        this.centerIcons();
    }
    start(){
        var _that = this;
        $(".slide-show:not(\".slide-show-autoplay\")").each(function(){
            let sl = $(this).find('.slides').length;
            let $root = $(this);
            _that.handleIcons($root,1,sl);
            $(this).find('.icons').click(function(){
                var isOne = ( $(this).hasClass('prev') ) ? -1 : 1;
                _that.manageSlides($root,isOne,sl);
            });
            $(this).find('.track span').each(function(i){
                $(this).click(function(){
                    _that.manageSlides($root,(i+1),sl,true);
                });
            });
            func.touchDetect(this, function(swipedir){
				if (swipedir =='left' && _that.touch.l)
                    _that.manageSlides($root,1,sl);
				if (swipedir =='right' && _that.touch.r)
                    _that.manageSlides($root,-1,sl);
			});
        });
        this.hoverEffects();
    }
    centerIcons(){        
        $(".slide-show").each(function(){
            var activeL = $(this).find('.slides.active').length;
            var imgH = (activeL > 0) ? $(this).find('.slides.active img').height() : $(this).find('img').eq(0).height();
            if(func.deviceMode() != 'desktop'){
                $(this).find('.icons').css('top', (imgH / 2)+'px');
            } else {
                $(this).find('.icons').css('top', '50%');
            }
        });        
    }
    hoverEffects(){
        $(".slide-show").each(function(){
            $(this).find('.track').mouseover(function() {
                $(this).addClass('hover');
            }).mouseout(function() {
                $(this).removeClass('hover');
            });
            $(this).find('.track').mousedown(function() {
                $(this).removeClass('hover');
                $(this).addClass('grab');
            });
            $(this).find('.track').mouseup(function() {
                $(this).removeClass('grab');
            });
        });
    }
    manageSlides($elem,v,sl,$track = false){
        $elem.find('.slides.active').removeClass('active');
        var newCs = (!$track) ? $elem.data('cs') + (v) : v;
        $elem.data('cs',newCs);
        $elem.find('.slides').eq(newCs - 1).addClass('active');
        if(newCs == 1){
            $elem.find('.slides').eq(0).removeClass('deactive');
        } else {
            $elem.find('.slides').eq(0).addClass('deactive');
        }
        this.handleIcons($elem,newCs,sl);
    }
    handleIcons($elem,cs,sl){
        $elem.find('.icons.active').removeClass('active');
        $elem.find('.track span.active').removeClass('active');
        $elem.find('.track span').eq(cs - 1).addClass('active');
        this.touch = {l:false,r:false};
        if( (cs == 1 && sl > 1) || (cs < sl && cs != sl) ){
            $elem.find('.icons.next').addClass('active');
            this.touch.l = true;
        }
        if( (cs == sl && sl > 1) || (cs <= sl && cs != 1)){
            $elem.find('.icons.prev').addClass('active');
            this.touch.r = true;
        }
    }
}

export class NavigationCarousel {
    constructor() {
        this.start = this.start.bind(this);
        this.handleIcons = this.handleIcons.bind(this);
        this.start('ready');
        var _this = this;
        $(window).resize(func.debounce(function(){
            _this.start('rs');
        },200));

        // $(document).ready(func.debounce(function(){
        //     if($('.search-page.results-not-found').length > 0){
        //         _this.start('rs');
        //     }
        // },500));

        $(document).ready(func.debounce(function(){
            if($('.ise-small-bubble-map').length > 0){
                _this.start('rs');
            }
        },300));
    }
    start(type){ 
        var _that = this;       
        $(".navigation-carousel").each(function(){
            var $nc = $(this); 
            var $inc = 0;
            var $ncItems = $nc.find('.nc-carousel-items');
            var $elenaItems = $nc.hasClass('enhanced-nc-carousel');
            var $threeNc = $nc.hasClass('three-nc-carousel');
            var numInc = 4;
            let elena;
            if( $nc.find('.ikea-card').length > 0 ){
                elena = $nc.find('.ikea-card').width();
            } else if( $nc.find('.page-entrance').length > 0 ){
                elena = $nc.find('.component.page-entrance').width();
            }
            if(screen.width < 767){
                numInc = 6;
            }

            var containerWidth = $nc.width();
            var slidesPerPage = 0;
            $(this).find('.icons').unbind('click');
            $(this).find('.icons').click(function(){
                window.addEventListener("resize", checkWidth);

            function checkWidth() {
                containerWidth = $nc.width();
                setParams(containerWidth);
            }
            if($threeNc){
                var nocard = 3;
            }else{
                var nocard = 4;
            }
            function setParams(w) {
                if (w < 551) {                    
                    slidesPerPage = Math.round(elena + 16)  ;
                } else {
                    if (w < 790) {
                        slidesPerPage = (elena * 2) + (16 * 2);
                    } else {      
                        slidesPerPage = (elena * nocard) + (16 * nocard);
                    }
                }
            }
            setParams(containerWidth);
                if($(this).hasClass('prev')){
                     if($elenaItems == true){                       
                        $inc -= slidesPerPage;
                        $ncItems.stop().animate({scrollLeft: $inc},300);
                     }else{
                        if($('.ise-small-bubble-map').length > 0){
                            $inc -= ($ncItems.width() + numInc);
                        }else{
                            $inc -= ($ncItems.width() + 16);
                        }
                        $ncItems.stop().animate({scrollLeft: $inc},300);
                    }                    
                } else {
                    if( $elenaItems == true){                        
                        $inc += slidesPerPage;
                        $ncItems.stop().animate({scrollLeft: $inc},300);
                     }else{
                        if($('.ise-small-bubble-map').length > 0){
                            $inc += ($ncItems.width() + numInc);
                        }else{
                            $inc += ($ncItems.width() + 16);
                        }
                       
                        $ncItems.stop().animate({scrollLeft: $inc},300);
                     }
                }
                if($inc < 0)$inc = 0;          
            });            
            _that.handleIcons($nc,type);
            $nc.find('.nc-carousel-items').unbind('scroll');
            $nc.find('.nc-carousel-items').scroll(function(){
                _that.handleIcons($nc,'scroll');         
            });
            var Timer;
            $nc.find('.nc-carousel-items').on("scroll",function() 
            {
                $(this).addClass('pressed'); 
                var _that = $(this);
                clearTimeout(Timer);
                Timer = setTimeout(function()
                {
                    _that.removeClass('pressed'); 
                },50);
            });            
        });
    }
    handleIcons($elem,type){
        var newScrollLeft = $elem.find('.nc-carousel-items').scrollLeft();
        if(type == 'rs' && newScrollLeft > 0){
            $elem.find('.nc-carousel-items').stop().animate({scrollLeft: 0},0);
            newScrollLeft = 0;
        }
        var width=$elem.find('.nc-carousel-items').width(),
        scrollWidth=$elem.find('.nc-carousel-items').get(0).scrollWidth;
        //console.log('NC....scrolllll....', newScrollLeft, width, scrollWidth);
        //console.log(scrollWidth- newScrollLeft-width);
        $elem.find('.nc-carousel-track').removeClass('hide');
        $elem.find('.icons').removeClass('show');
        var _noScroll = 0;
        if (scrollWidth- newScrollLeft-width > 0.5) {
            $elem.find('.icons.next').addClass('show');
            _noScroll++;
        }
        if (newScrollLeft > 0) {
            $elem.find('.icons.prev').addClass('show');
            _noScroll++;
        }
        if(_noScroll == 0)$elem.addClass('noscroll');
    }
}