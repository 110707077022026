import $ from 'jquery';

function toggleSectionCollapse($li) {
  if ($li.length === 0) return;

  const $button = $li.find('button');
  const $ul = $li.find('ul');
  const $icon = $li.find('.svg-icon');
  //const isExpanded = $button.attr('aria-expanded').toLowerCase() === 'true';

  $ul.toggle();
  //$button.attr('aria-expanded', isExpanded ? 'false' : 'true');
  $icon.toggleClass('svg-icon--rotate-45');
}

export default class NavMenuCollapse {
  constructor({ selectors, showActive = false }) {
    this.$menu = $(selectors.menu);
    this.showActive = showActive;

    this.handleToggleCollapse = this.handleToggleCollapse.bind(this);
  }

  initializeA11yAttributes() {
    const $lis = this.$menu.find('li.level1');
    $lis.each((i, li) => {
      const $li = $(li);
      const subsectionsIds = `nav-mobile-subsections-${i}`;
      $li.find('> button').attr('aria-controls', subsectionsIds);
      $li.find('> ul').attr('id', subsectionsIds);
    });
  }

  closeAllCollapses() {
    this.$menu.find('li.level1 > ul').hide();
    this.$menu.find('li.level1 > button').attr('aria-expanded', 'false');
    if (this.showActive) {
      toggleSectionCollapse(this.$menu.find('li.level1.active'));
    }
  }

  handleToggleCollapse(evt) {
    const $li = $(evt.target).closest('li.level1');
    toggleSectionCollapse($li);
  }

  start() {
    this.closeAllCollapses();
    this.initializeA11yAttributes();

    this.$menu.on('click', 'li.level1 > button', this.handleToggleCollapse);
  }
}
