import $ from 'jquery';
import * as func from '../components/onlyExport';


class VbsPage {
    constructor() {
        this.start = this.start.bind(this);
        var cThis = this;
        this.start();
        $(window).resize(func.debounce(function(){
            cThis.start();
        },200));
    }
    start(){
        var winH = func.deviceDimensions().winHeight;
        if($(".vbs-page").length > 0){
            var _headerH = $(".vbs-page #wrapper > header").innerHeight();
            var _footerH = 0;
            if(func.deviceMode() == 'desktop')_footerH = $(".vbs-page #wrapper > footer").innerHeight();
            var reqH = winH - (_headerH + _footerH + 10);
            $(".vbs-page #wrapper #body iframe").css('height',reqH+'px');
        }
    } 
}

export default VbsPage;