export const StateClasses = {
  IS_ZOOMED: 'is-zoomed',
  IS_VISIBLE: 'is-visible',
  IS_SEARCHVISIBLE: 'is-searchVisible',
  IS_STICKY: 'is-sticky',
  IS_ACCORDION_ITEM_ACTIVE: 'ikea-accordion__item--active',
  IS_ACCORDION_ICON_ROTATED: 'svg-icon--rotate-minus-45'
};

export const ESC_KEYCODE = 27;
export const MIN_NAVBAR_STICKY_THRESHOLD = 400;
export const STICKY_LISTENER_DEBOUNCE = 300;
