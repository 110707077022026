import $ from 'jquery';
import { StateClasses } from './constants';
import * as func from './onlyExport';

/**
 * Map that stores the lazyly initialized accordions
 *ll
 * Accordions are only initialized when clicked. This way we can also handle
 * AJAXed accordions.
 *
 * @type {Map<HTMLElement, Accordion>}
 */
const PAGE_ACCORDIONS = new WeakMap();

/**
 * Retrieves the Accordion widget instanced associated to given DOM node.
 * It will create an instance if one does not exist.
 *
 * @param { HTMLElement } node
 * @param { Object } selectors
 * @return {Accordion}
 */
function getOrInitializeAccordionInstance(node, selectors) {
  let accordion = PAGE_ACCORDIONS.get(node);
  if (!accordion) {
    accordion = new Accordion({ node, selectors });
    PAGE_ACCORDIONS.set(node, accordion);
  }
  return accordion;
}

/**
 * Represents an accordion item (combination of button and content)
 */
class AccordionItem {
  constructor($node, selectors) {
    this.$node = $node;
    this.$button = this.$node.find(selectors.button);
    this.$panel = this.$node.find(selectors.panel);
    this.$icon = this.$node.find(selectors.icon);

    this.id = this.$node.attr('id');
    this.isExpanded = this.$node.hasClass(
      StateClasses.IS_ACCORDION_ITEM_ACTIVE
    );
  }

  expand() {
    if (this.isExpanded) return;
    this.isExpanded = true;
    this.render();
  }

  collapse() {
    if (!this.isExpanded) return;
    this.isExpanded = false;
    this.render();
  }

  toggle() {
    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  render() {
    requestAnimationFrame(() => {
      const isExpanded = this.isExpanded;

      this.$node.toggleClass(StateClasses.IS_ACCORDION_ITEM_ACTIVE, isExpanded);
      this.$icon.toggleClass(
        StateClasses.IS_ACCORDION_ICON_ROTATED,
        isExpanded
      );
      this.$button.attr('aria-expanded', isExpanded ? 'true' : 'false');
    });
  }
}

/**
 * Represents an accordion instance. Handles all update-related logic
 */
class Accordion {
  constructor({ node, selectors }) {
    this.$root = $(node);
    this.selectors = selectors;

    const properties = this.$root.data('properties');
    //console.log(properties);
    this.canToggle = !!properties.canToggle;
    this.canOpenMultiple = !!properties.canOpenMultiple;
    this.expandOnHover = !!properties.expandOnHover;
    this.expandedByDefault = !!properties.expandedByDefault;

    this.accordionItems = this.$root
      .find(selectors.item)
      .map((i, li) => new AccordionItem($(li), selectors))
      .toArray();

    this.handleClick = this.handleClick.bind(this);
    this.handleButtonHover = this.handleButtonHover.bind(this);
  }

  toggleItem(itemId) {
    this.accordionItems.forEach(accordionItem => {
      if (accordionItem.id === itemId) {
        if (this.canToggle) {
          accordionItem.toggle();
        } else {
          accordionItem.expand();
        }
        return;
      }

      // closes other accordion items if multiple accordion is not alllowed
      if (!this.canOpenMultiple) {
        accordionItem.collapse();
      }
    });
  }

  handleClick(event) {
    const $target = $(event.target);
    const currentId = $target.closest(this.selectors.item).attr('id');
    this.toggleItem(currentId);
  }

  handleButtonHover(evt) {
    if (this.expandOnHover) {
      this.handleClick(evt);
    }
  }

  start() {
    // autoscroll and expand if # value in URL - US: #30569 - sprint40
    var getAccordionID = window.location.hash.substring(1);
    var bannerH = func.getBannersHeight();    
    if(getAccordionID.length > 0){
      var elemPosition = $("#"+getAccordionID).offset().top;
      var getHeaderHeight = $(".navbar__main-bar").outerHeight();
      this.expandedByDefault = false;
      this.toggleItem(getAccordionID);
      $('html, body').stop().animate({ scrollTop: (elemPosition - getHeaderHeight) - bannerH}, 500);
    }

    // open first item if expanded by default
    if (this.expandedByDefault) {
      const firstItemId = this.accordionItems[0] && this.accordionItems[0].id;
      this.toggleItem(firstItemId);
    }
  }
}

/**
 * Sets up accordion event listeners.
 * Lazyly initializes accordion widgets if they are not created.
 */
function setUpDelegatedListeners({ selectors }) {
  $(document).ready(function() {
    function handleEvents(evt) {
      // preventDefault only for <a> elements (which change the URL) not inside the collapsible element
      if (event.target.tagName === 'A') {
        event.preventDefault();
      }

      const $root = $(evt.target).closest(selectors.accordion);
      const rootDomNode = $root.get(0);

      const accordion = getOrInitializeAccordionInstance(
        rootDomNode,
        selectors
      );
      if (evt.type === 'click') {
        accordion.handleClick(evt);
      } else if (evt.type === 'mouseenter') {
        accordion.handleButtonHover(evt);
      }
    }

    $(document).on('click focus mouseenter', selectors.button, handleEvents);
  });
}

/** 
 * copy link feature 
*/
function accordionCopyLink(){
  resetNotificationAlignment();
  resetHrefForPrint();
  $(".ikea-accordion__item .ikea-accordion__copylink .copylink-click").click(
    function(){
      var elemID = $(this).closest('li').attr('id');
      var currentURL = func.curLoc().onlyUrl+'#'+elemID;
      var isCopied = func.copyToClipboard(currentURL);
      var $popup = $(this).closest('li').find('.copylink-notification');
      if(isCopied){
        $popup.fadeIn(500); // slideDown("slow");
        setTimeout(autoCloseNotification,func.autoHideDur,$(this));
      }
    }
  );
  $(".ikea-accordion__item .ikea-accordion__copylink .close-icon").click(function(){
    autoCloseNotification($(this));
  });
}

function autoCloseNotification($elem){  
  $elem.closest('li').find('.copylink-notification').fadeOut(500);
}

function resetNotificationAlignment(){
  $(".ikea-accordion__item .ikea-accordion__copylink .copylink-click").each(function(){
    var pos = func.elementDimensions($(this));
    $(this).closest('li').find('.copylink-notification').css({right: pos.right});
  });
}

function resetHrefForPrint(){
  $(".ikea-accordion__item").each(function(){
    var hrefVal = func.curLoc().onlyUrl+'#'+$(this).attr('id');
    $(this).find('.ikea-accordion__copylink__print a').attr('href',hrefVal);
  });
}

/**
 * Initializes accordion widgets.
 *
 * The accordions are stored on a ES6 Map where the key is the root HTMLElement
 * of the accordion. This way, the instance is persisted.
 */
export function initAccordions({ selectors }) {
  $(document).ready(function() {
    $(selectors.accordion).each((i, element) => {
      const accordion = getOrInitializeAccordionInstance(element, selectors);
      accordion.start();
    });

    setUpDelegatedListeners({ selectors });
    accordionCopyLink();
  });
  $( window ).resize(function() {
    resetNotificationAlignment();
  });
}
