import $ from 'jquery';
import throttle from 'lodash.throttle';
import {
  MIN_NAVBAR_STICKY_THRESHOLD,
  STICKY_LISTENER_DEBOUNCE,
  StateClasses
} from './constants';

function isEmpty($e) {
  return $e && $e.length === 0;
}

const $window = $(window);

export default class StickyNavbar {
  constructor(selectors) {
    this.selectors = selectors;
    this.$navbar = $(this.selectors.navbar);

    this.isSticky = false;

    this.handleStickyness = throttle(
      this.handleStickyness.bind(this),
      STICKY_LISTENER_DEBOUNCE,
      { trailing: true }
    );
  }

  handleStickyness() {
    const stickynessThreshold = Math.max(
      this.$navbar.height() * 3,
      MIN_NAVBAR_STICKY_THRESHOLD
    );

    const hasScrolledEnough = $window.scrollTop() > stickynessThreshold;

    if (!this.isSticky && hasScrolledEnough) {
      this.isSticky = true;
      this.$navbar.toggleClass(StateClasses.IS_STICKY, this.isSticky);
      return;
    }

    if (this.isSticky && !hasScrolledEnough) {
      this.isSticky = false;
      this.$navbar.toggleClass(StateClasses.IS_STICKY, this.isSticky);
    }
  }

  start() {
    if (isEmpty(this.$navbar)) {
      console.log('Missing navbar item');
      return;
    }

    //$window.scroll(this.handleStickyness);
  }
}
