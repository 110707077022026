import $ from 'jquery';

export default class ContentNavigation {
  constructor({ selectors }) {
    this.$root = $(selectors.root);
  }

  start() {
    // If no content nav is present, don't try to initialize it
    if (this.$root.length === 0) return;

    // Use event delegation
    // TODO: extract it to a listener on the document
    this.$root.on('click', 'a[href*="#"]:not([href="#"])', function(e) {
      if (this.hash == '' || !this.hash) return;

      const hash = this.hash;

      // Accounts for the navigation header
      // TODO: calculate real height
      const scrollLocation =
        $(document)
          .find(hash)
          .offset().top - 75;

      $('html, body')
        .stop()
        .animate({ scrollTop: scrollLocation }, 1000, () => {
          window.location.hash = hash;
        });
    });
  }
}
