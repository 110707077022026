import $ from 'jquery';
import * as func from './onlyExport';

function defineColumns($elem,idxArr,className){
    idxArr.forEach(function(idx){
        if(func.checkPreviewMode()){
            $elem.find(".article-card-collection__list > .article-card:nth-child("+idx+")").addClass(className);
        } else {
            $elem.find(".article-card-collection__list > div:nth-child("+idx+")").addClass(className);
        }        
    });    
}

function autosetFeturedNewsColumns(){
    
    var singleColumn = "ikea-col-md-12 ikea-col-xl-12";
    var twoColumn = "ikea-col-md-6 ikea-col-xl-6";
    var threeColumn = "ikea-col-md-6 ikea-col-xl-4";

    $(".featured-news").each(function(){
        var _this = $(this);
        var numItems = "";
        if(func.checkPreviewMode()){
            numItems = $(this).find(".article-card-collection__list > .article-card").length;
        } else {
            numItems = $(this).find(".article-card-collection__list > div").length;
        }
        if(numItems > 7)numItems = 7;
        switch(numItems){
            case 1:
                defineColumns(_this,[1],singleColumn);
                break;
            case 2:
                defineColumns(_this,[1,2],twoColumn);
                break;
            case 3:
                defineColumns(_this,[1],singleColumn);
                defineColumns(_this,[2,3],twoColumn);
                break;
            case 4:
                defineColumns(_this,[1],singleColumn);
                defineColumns(_this,[2,3,4],threeColumn);                
                break;
            case 5:
                defineColumns(_this,[1],singleColumn);
                defineColumns(_this,[2,3,4,5],twoColumn);
                break;
            case 6:
                defineColumns(_this,[1],singleColumn);
                defineColumns(_this,[2,3],twoColumn);
                defineColumns(_this,[4,5,6],threeColumn);
                break;
            case 7:
                defineColumns(_this,[1],singleColumn);
                defineColumns(_this,[2,3,4,5,6,7],threeColumn);
                break;
        }
        if(numItems != 2){            
            if(func.checkPreviewMode()){
                $(this).find(".article-card-collection__list > .article-card:first-child").addClass('apply-60-40');
            } else {
                $(this).find(".article-card-collection__list > div:first-child > .article-card").addClass('apply-60-40');
            }
        }
    });
}
function autosetFeturedNewsColumnsGrid(){
    $(".ikea-card-featured-news-layout,.ikea-card-cluster-layout").each(function(){
        var _countArticles = $(this).find('article').length;
        var _classname = (_countArticles >= 7) ? "arrange-by-7" : "arrange-by-"+_countArticles;
        $(this).addClass(_classname); // For Icon arranging...
        $(this).find('article').each(function(){
            $(this).addClass(_classname);
        });
    });
    if(!func.checkPreviewMode()){
        $(".ikea-card-featured-news-layout > .component-content, .ikea-card-cluster-layout > .component-content").each(function(){
            $(this).css('display','block');            
            $(this).find('article').each(function(i){
                if(i == 0)return;
                $(this).css('width','33%');
                $(this).css('display','inline-block');
                if(i > 6){
                    $(this).css('display','none');
                }
            });
        });
        $(".ikea-card-three-layout > .component-content").each(function(){
            $(this).css('display','block');            
            $(this).find('article').each(function(i){
                $(this).css('width','33%');
                $(this).css('display','inline-block');
            });
        });
        $(".ikea-card-four-layout > .component-content").each(function(){
            $(this).css('display','block');            
            $(this).find('article').each(function(i){
                $(this).css('width','23%');
                $(this).css('display','inline-block');
            });
        });
        $(".ikea-card-two-layout > .component-content").each(function(){
            $(this).css('display','block');            
            $(this).find('article').each(function(i){
                $(this).css('width','45%');
                $(this).css('display','inline-block');
            });
        });
    }
}

export function initFeaturedNewsColumns(){
    $(document).ready(function() {
        autosetFeturedNewsColumns();
        autosetFeturedNewsColumnsGrid();
        const objCtaIcon = new setCtaIcon();
    }); 
}

export class setCtaIcon{
    constructor(){
        this.start = this.start.bind(this);
        this.start('ready');
        $(window).resize(func.debounce(this.start,200));
    }
    start(pageLoad){
        const allvariants = ['.ikea-card-featured-news-layout','.ikea-card-cluster-layout','.news-topics-results','.enterprise-results','.ikea-card-two-layout','.ikea-card-three-layout','.ikea-card-four-layout','.ikea-row'];
        // $('article.ikea-card').each(function(){
        //     var _hasStyle = $(this).find('.card-content').attr('style');
        //     if(_hasStyle && pageLoad == 'ready'){
        //         var _clr = $(this).find('.card-content').css("color");
        //         $(this).find('.card-content').find("p,h2,h3,h4,h5,h6,a").css('color',_clr);
        //         $(this).find('.card-content .svg-icon').css('fill',_clr+' !important');
        //     }            
        //     $(this).find('.card-content').removeAttr('style');
        // });
        // $('.page-entrance').each(function(){
        //     var _hasStyle = $(this).find('.page-entrance-card-content').attr('style');
        //     if(_hasStyle && pageLoad == 'ready'){
        //         var _clr = $(this).find('.page-entrance-card-content').css("color");
        //         $(this).find('.page-entrance-card-content').find("p,h2,h3,h4,h5,h6,a").css('color',_clr);
        //         $(this).find('.page-entrance-card-content .svg-icon').css('fill',_clr);
        //     }  
        //     $(this).find('.page-entrance-card-content').removeAttr('style');
        // });
        if(func.deviceMode() != 'mobile'){
            allvariants.forEach((e)=>{
                this.findHeight(e);            
            });
        }
    }
    findHeight(compElem){
        const _that = this;
        if(compElem == '.ikea-row'){
            _that.pageEntrance();
            return;
        }
        $(compElem).each(function(){
            const _artCnt = $(this).find('article.ikea-card').length;
            //console.log('Component:--- '+compElem+' ,count: '+_artCnt);
            if(_artCnt > 1){
                switch(compElem){
                    case '.ikea-card-two-layout':
                        _that.calcRowWise(2,$(this),compElem);
                        break;
                    case '.ikea-card-three-layout':
                        _that.calcRowWise(( (func.deviceMode() == 'desktop') ? 3 : 2),$(this),compElem);
                        break;
                    case '.ikea-card-four-layout':
                    case '.news-topics-results, .enterprise-results':
                        _that.calcRowWise(( (func.deviceMode() == 'desktop') ? 4 : 2),$(this),compElem);
                        break;
                    case '.ikea-card-featured-news-layout':
                    case '.ikea-card-cluster-layout':
                        _that.forFeatureCluster($(this));
                        break;
                }
            }
        }); 
    }

    pageEntrance(){
        $('.ikea-row').each(function(){
            let _maxHeight = 0;
            $(this).find('.page-entrance.with-bgcolor').each(function(){
                let _eH = $(this).find('.page-entrance-card-content').outerHeight();
                _maxHeight = ( _maxHeight < _eH) ? _eH : _maxHeight;
            });
            $(this).find('.page-entrance.with-bgcolor').each(function(){
                $(this).find('.page-entrance-card-content').css('height',_maxHeight);
            });
        });
    }

    forFeatureCluster(elem){
        const _that = this;
        if(elem.hasClass('arrange-by-2')){
            _that.calcRowWise(2,elem);
        } else if(elem.hasClass('arrange-by-3')){
            _that.calcRowWise(2,elem,'',true);
        } else if(elem.hasClass('arrange-by-4')){
            _that.calcRowWise(3,elem,'',true);
        } else if(elem.hasClass('arrange-by-5')){
            _that.calcRowWise(2,elem,'',true);
        } else if(elem.hasClass('arrange-by-6')){
            _that.calcRowWise(2,elem,'',true);
            _that.calcRowWise(3,elem,'',true,3);
        } else if(elem.hasClass('arrange-by-7')){
            _that.calcRowWise(( (func.deviceMode() == 'desktop') ? 3 : 2),elem,'',true);
        }
    }

    calcRowWise(rowCnt,comp,clsName,isDynamic,startAt){
        //console.log(rowCnt,comp,isDynamic);
        let oCnt = [];
        let _maxV;
        const _that = this;
        comp.find('article.ikea-card').each(function(i){
            if(isDynamic && i == 0)return;
            if(isDynamic && startAt == i)oCnt = [];
            oCnt.push($(this).find('.card-content').outerHeight());
            _maxV = oCnt.reduce((acc, v) => acc = acc > v ? acc : v, 0);
            _that.setHeight($(this),_maxV);
            if(oCnt.length == 2){
                _that.setHeight($(this).prev(),_maxV);
                if(clsName == '.news-topics-results, .enterprise-results'){
                    _that.setHeight($(this).parent().prev(),_maxV);
                }
            }
            if(oCnt.length == 3){
                _that.setHeight($(this).prev(),_maxV);
                _that.setHeight($(this).prev().prev(),_maxV);
            }
            if(oCnt.length == 4){
                _that.setHeight($(this).prev(),_maxV);
                _that.setHeight($(this).prev().prev(),_maxV);
                _that.setHeight($(this).prev().prev().prev(),_maxV);
            }
            if(oCnt.length == rowCnt){
                oCnt = [];
            }
        }); 
    }

    setHeight(e,v){
        e.find('.card-content').css('height',v);
    }
}
