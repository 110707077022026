import $ from 'jquery';
import * as func from '../components/onlyExport';
//import { isElemInViewPort } from './isInViewPort';


class MarketingArea {
    constructor(selectors) {
        this.start();
    }
    start(){  
        var _that = this;
        if( $(".component.marketing-area").length == 1 && func.checkPreviewMode()){
            this.appendHtml();
            $(window).resize(func.debounce(function(){
                _that.changeDesign();
            },200));
        }        
    }
    appendHtml(){
        $("<li class='marketing-links'></li>").insertAfter( $(".mega-menu-navigation .level1.submenu > ul > a.close") );
        $(".component.marketing-area").clone().appendTo(".marketing-links");
        $(".mega-menu-navigation .level1.submenu > ul").addClass('extends'); // temp
        this.changeDesign();
    }
    changeDesign(){
        $(".marketing-links.one_article").removeClass('one_article');
        if( $(".marketing-area").first().find('article').length == 1){
            $(".marketing-links").addClass('one_article');
        }
    }
}

export default MarketingArea;