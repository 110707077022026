import $ from 'jquery';
//import 'jquery-ui';
import * as func from './onlyExport';
import { isElemInViewPort } from './isInViewPort';
let sideNavScrollVall = 250;
var sideNaviGlobal = {
    isScrollable: true,
    totalSubTitles: 0,
    pActiveLink: 0,
    nActiveLink: 0,
    anchorTopVal: [],
    minTopVal: 80,
    barHeight: 0
};

function appendSideNavSubTitles(){
    var _html = ''; //<div>On this page:</div>';
    let cnt = 0;
    $(".side-navigation-subtitle").each(function(){
        var subTitle = $.trim($(this).data('subtitle'));
        if(subTitle != ''){
            //$('.side-navigation .scroll').eq(0).show();
            if(func.checkPreviewMode())$(this).html('');
            var topPos = $(this).offset().top;
            sideNaviGlobal.anchorTopVal.push(topPos);
            var defaultActive = "";
            if(cnt == 0)defaultActive = 'class="active"';
            _html += '<div data-srno="'+cnt+'" '+defaultActive+'><a><span>'+subTitle+'</span></a></div>';
            cnt++;
        }        
    });
    $('.side-navigation .links').html(_html);
    $('<div class="heading"><span>On this page:</span></div>').insertBefore($('.side-navigation .scroll'));
    appendForMobile();
    calculateSideNavScroll('ready');
    syncWithSideNav();
    $('.side-navigation:not(.horizontal) + .component.content').addClass('mt-minus');
}

function appendForMobile(){
    $('.side-navigation').clone().appendTo('body');
    $(".side-navigation").eq(1).addClass('horizontal');
    var $mob_elem = $(".side-navigation.horizontal");

    // add arrow icons for horizontal - sprint 57
    var _leftIcon = '<div class="side-nav-icon left"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.40039 12.0001L14.1854 17.7858L15.5997 16.3717L11.2286 12L15.5997 7.62835L14.1854 6.21423L8.40039 12.0001Z" fill="#111"/></svg></a></div>';
    var _rightIcon = '<div class="side-nav-icon right"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5996 11.9999L9.81456 17.7857L8.40026 16.3716L12.7714 11.9999L8.40026 7.62823L9.81457 6.21411L15.5996 11.9999Z" fill="#111"/></svg></a></div>';
    $(_leftIcon).appendTo('body');
    $(_rightIcon).appendTo('body'); 
    showHideSideNavIcons();
    $(".side-navigation.horizontal").scroll(function(){
        showHideSideNavIcons();
    });   
    $("body").on("click",".side-nav-icon",function(e){
        e.preventDefault();
        if( $(this).hasClass('left') ){
            sideNavScrollVall -= 230;
        } else {
            sideNavScrollVall += 230;   
        }
        $mob_elem.animate({
            scrollLeft: sideNavScrollVall
        }, 'slow');
        
    });
}

function showHideSideNavIcons(){
    var $mob_elem = $(".side-navigation.horizontal");
    if($mob_elem.length == 0)return;
    var $width = $mob_elem.outerWidth()
    var $scrollWidth = $mob_elem.get(0).scrollWidth; 
    var $scrollLeft = $mob_elem.scrollLeft();
    if ($scrollWidth - $width === $scrollLeft){
        $(".side-nav-icon.right").css('display','none');
    } else { $(".side-nav-icon.right").css('display','block'); }
    if ($scrollLeft===0){
        $(".side-nav-icon.left").css('display','none');
    } else { $(".side-nav-icon.left").css('display','block'); }
}
  
function scrollToSideNavSubTitle(_that){
    sideNaviGlobal.nActiveLink = _that.data('srno');
    calculateSideNavScroll();
    var subTitle = _that.text();
    const forDesk = (func.deviceDimensions().winWidth > 1200) ? 64 : 0;
    $('a.side-navigation-subtitle').filter(function() {
        if($(this).data('subtitle') == subTitle){
            $(window).scrollTop($(this).offset().top - sideNaviGlobal.minTopVal - forDesk);
            setTimeout(function(){ sideNaviGlobal.isScrollable = true; },300);
        }
    }); 
    syncWithSideNav();   
}

function resetActiveSideNavLink(srno){
    $('.side-navigation .links div.active').removeClass('active');
    $(".side-navigation .links div[data-srno='" + srno +"']").addClass('active'); 
}

function getSrnoHeight(srno){
    return $('.side-navigation .links div[data-srno='+srno+']').height()
}

function returnPxForOneDiff(prevElemH,nextElemH){
    var cntPx = 0;
    if(prevElemH == nextElemH){
        cntPx = prevElemH + 32; // 32 - 2rem as per css
    } else if(prevElemH < nextElemH){
        cntPx = nextElemH + 32;
        if(sideNaviGlobal.pActiveLink > sideNaviGlobal.nActiveLink){
            cntPx -= 21;
        }
    } else if(prevElemH > nextElemH){
        cntPx = nextElemH + 32;
        if(sideNaviGlobal.pActiveLink > sideNaviGlobal.nActiveLink){
            cntPx += 21;
        }
    }
    return cntPx;
}

function calculateSideNavScroll(dur = 500){
    var diffSize = Math.abs(sideNaviGlobal.nActiveLink - sideNaviGlobal.pActiveLink);
    if(dur == 'ready'){
        var firstH = $('.side-navigation .links div[data-srno=0]').height();
        sideNaviGlobal.barHeight = firstH;
        var cntPx = firstH;
        // animation removed - sp - 57
        //$('.side-navigation .scroll div').css({'height':cntPx+'px'});
    } else if(diffSize == 1){
        var prevElemH = getSrnoHeight(sideNaviGlobal.pActiveLink);
        var nextElemH = getSrnoHeight(sideNaviGlobal.nActiveLink);
        var cntPx = returnPxForOneDiff(prevElemH,nextElemH);
        //resetHeightOfBar(nextElemH);
        sideNaviGlobal.barHeight = nextElemH;
        if(sideNaviGlobal.pActiveLink < sideNaviGlobal.nActiveLink)
            animateSideNavScroll('-',cntPx,500);
        else 
            animateSideNavScroll('+',cntPx,500);
    } else {
        var finalH = 0;
        var tarH = 0;
        if(sideNaviGlobal.pActiveLink < sideNaviGlobal.nActiveLink){            
            for(var j=sideNaviGlobal.pActiveLink; j<sideNaviGlobal.nActiveLink; j++){
                var curH = getSrnoHeight(j);
                var curNextH = getSrnoHeight(j+1);
                tarH = curNextH;
                finalH += returnPxForOneDiff(curH,curNextH);                
            }
            //resetHeightOfBar(tarH);
            sideNaviGlobal.barHeight = tarH;
            animateSideNavScroll('-',finalH,500);            
        } else if(sideNaviGlobal.pActiveLink > sideNaviGlobal.nActiveLink){
            for(var k=sideNaviGlobal.pActiveLink; k>sideNaviGlobal.nActiveLink; k--){
                var curH = getSrnoHeight(k);
                var curNextH = getSrnoHeight(k-1);
                tarH = curNextH;
                finalH += returnPxForOneDiff(curH,curNextH);    
            }
            //resetHeightOfBar(tarH);
            sideNaviGlobal.barHeight = tarH;
            animateSideNavScroll('+',finalH,500);
        }
             
    }
}


  
function autoCenterandActiveMobile(){
    if($(".side-navigation").length == 0) return;
    var lengthM = $('.side-navigation.horizontal .links div.active').length;
    if(lengthM == 0)return false; 
    var diva = $('.side-navigation.horizontal .links div.active').offset().left; 
    var divOw = $('.side-navigation.horizontal .links div.active').outerWidth(true)/2;
    var linksLeft = $('.side-navigation.horizontal').scrollLeft();
    var linksLefthalf = $('.side-navigation.horizontal').width()/2;
    var myScrollPos = diva + divOw + linksLeft - linksLefthalf;
    $('.side-navigation.horizontal').scrollLeft(myScrollPos);
    //.animate( { scrollLeft: myScrollPos }, 300);
    //.scrollLeft(myScrollPos);
}

function animateSideNavScroll(direction,pixels,dur){
    // $(".side-navigation .scroll > div").animate(
    //     {bottom: direction+"="+pixels},
    //     {duration: dur, queue: false }
    // );
    // $('.side-navigation .scroll div').animate(
    //     {height:sideNaviGlobal.barHeight},
    //     {duration: dur, queue: false }
    // );    
    resetActiveSideNavLink(sideNaviGlobal.nActiveLink);  
    sideNaviGlobal.pActiveLink = sideNaviGlobal.nActiveLink;
}

function syncWithSideNav(){     
    if( $(".side-navigation").length == 0)return false;
    if(!sideNaviGlobal.isScrollable)return false;   
    var scrollPos = $(window).scrollTop();
    var breakPoint = ($(".side-navigation").offset().top) + ($(".side-navigation").height()) - 85;
    //if(func.deviceMode() != 'desktop')sideNaviGlobal.minTopVal = 120;
    var dummyElemHeight = 0;
    if(func.deviceDimensions().winWidth <= 1199){
        dummyElemHeight = $(".side-navigation").eq(0).height();
        if(scrollPos >= breakPoint){
            $(".side-navigation.horizontal").css('display','inline-flex');
            $(".side-nav-icon").css('display','block');
            showHideSideNavIcons();
        }else{
            $(".side-navigation.horizontal, .side-nav-icon").hide();
        }
    }    
    // only for IE
    if(func.deviceDimensions().winWidth > 1199){
        var breakpointIE = sideNaviGlobal.anchorTopVal[0] - sideNaviGlobal.minTopVal;
        var reqClasses = "temp-sidenav-topbg";
        if(func.browserType() == 'Edge' || func.browserType() == 'IE'){
            reqClasses += " ie-side-navigation";
        }
        // fill above side navigation with white bg
        if(breakpointIE <= scrollPos)
            $(".side-navigation").eq(0).addClass(reqClasses);
        else 
            $(".side-navigation").eq(0).removeClass(reqClasses);
    }
    // only for IE -- End
    
    if(func.deviceDimensions().winWidth > 1199){        
        var lastIndex = sideNaviGlobal.anchorTopVal.length - 1;        
        if( (scrollPos + sideNaviGlobal.minTopVal ) <= sideNaviGlobal.anchorTopVal[0] ){
            sideNaviGlobal.nActiveLink = 0;
        } else if( (scrollPos + sideNaviGlobal.minTopVal ) >= sideNaviGlobal.anchorTopVal[ lastIndex ] ){
            sideNaviGlobal.nActiveLink = lastIndex;
        } else {
            sideNaviGlobal.anchorTopVal.forEach(function(pixel,i){
                if( ((scrollPos + sideNaviGlobal.minTopVal - dummyElemHeight) > pixel) ){
                    if( (scrollPos) < sideNaviGlobal.anchorTopVal[i+1] ){
                        sideNaviGlobal.nActiveLink = i;
                        return true;
                    }
                }
            });
        }
    } else { // for mobile and tablet
        sideNaviGlobal.minTopVal = 150;
        sideNaviGlobal.anchorTopVal.forEach(function(pixel2,j){
            if( ((scrollPos + sideNaviGlobal.minTopVal - dummyElemHeight) >= pixel2) ){
                sideNaviGlobal.nActiveLink = j;
                return true;
            }
        });
    }
    calculateSideNavScroll(500); 
    sidenavSpaceIssue();    
    if(func.deviceDimensions().winWidth < 1200){  
        var _hh = $(".navbar__main-bar").outerHeight();
        $(".side-navigation.horizontal").css('top',_hh);
        $(".side-nav-icon").css('top',_hh + 12);
    }
}
function sidenavSpaceIssue(){
    if(func.deviceDimensions().winWidth >= 1200){
        const _height = ( isElemInViewPort($("footer"), 8) ) ? 'auto' : '0px';
        $(".component.side-navigation").css('height', _height);
    }
}

function pageReadySideNavigation(){
    if(func.deviceDimensions().winWidth < 1200)sideNaviGlobal.minTopVal = 150;
    sideNaviGlobal.totalSubTitles = $("a.side-navigation-subtitle").length;
    if(sideNaviGlobal.totalSubTitles > 0){
        appendSideNavSubTitles();
        $('.side-navigation .links').on('click', 'div', function() {
            sideNaviGlobal.isScrollable = false;
            scrollToSideNavSubTitle( $(this) );
            if(func.deviceDimensions().winWidth < 1200)
            $(".side-navigation.horizontal").css('display','inline-flex');
        });
    } else {
        $(".side-navigation").hide();
    }
}

export function initSideNavigation(){
    $(document).ready(function() {
        pageReadySideNavigation();
    });

    $(window).on('scroll',syncWithSideNav);
    //$(window).on('resize',pageReadySideNavigation); 
}

$(window).on('scroll',function(){
    if(func.deviceDimensions().winWidth < 1200)
    setTimeout(autoCenterandActiveMobile, 1000);
});


