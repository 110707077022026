import $ from 'jquery';
import { StateClasses } from './constants';

function findZoomTarget(selectors, e) {
  const $hovered = $(e.target);
  const $root = $hovered.closest(selectors.widgetRoot);
  return $root.find(selectors.target);
}

function handleMouseEnter(selectors, e) {
  const $zoomTarget = findZoomTarget(selectors, e);
  $zoomTarget.addClass(StateClasses.IS_ZOOMED);
}

function handleMouseLeave(selectors, e) {
  const $zoomTarget = findZoomTarget(selectors, e);
  $zoomTarget.removeClass(StateClasses.IS_ZOOMED);
}

/**
 * Initializes a widget that will zoom over an element when hovering over another
 *
 * @param {Object} selectors
 */
export function initZoomOnHover(selectors) {
  $(document).on('mouseenter', selectors.hoverArea, e =>
    handleMouseEnter(selectors, e)
  );
  $(document).on('mouseleave', selectors.hoverArea, e =>
    handleMouseLeave(selectors, e)
  );
}
