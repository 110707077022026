import $ from 'jquery';
import * as func from '../components/onlyExport';
import * as d3 from 'd3';
//import { html } from 'd3';


class DonutChart {
    constructor(selectors) {
        this.start = this.start.bind(this);
        this.build_chart = this.build_chart.bind(this);
        this.build_ct = this.build_ct.bind(this);
        this.pathHoverClick = this.pathHoverClick.bind(this);
        this.arrangeNameValue = this.arrangeNameValue.bind(this);
        var _this = this;
        this.dimVal = "0.2";
        $(window).resize(func.debounce(function(){
            _this.start('resize');
        },200));
        //func.colorPicker();
    }
    start(pl){
        var _that = this; 
        //if( $(".content-list-donutchart").length > 0){
        $(".content-list-donutchart").each(function(i){
            $(this).find('.chart').html('');
            var _isSup = $(this).hasClass('unit-super-script');
            var _isSub = $(this).hasClass('unit-sub-script');
            var $rootElem = $(this); 
            if(pl != 'resize'){                  
                var data = { opttitle: $(this).data('optionaltitle'), opttext: $(this).data('optionaltext'), unit: $(this).data('unit'), values: [], values2: [], colors: [], names: [], percent: [] };
                $(this).find('.data .data-items .data-item').each(function(){
                    var _val = $(this).find('.value').text().replaceAll(/,/ig, '');
                    data.colors.push($(this).find('svg ellipse').attr('fill'));
                    data.values.push( Number(_val) );
                    data.values2.push( $(this).find('.value').text().replaceAll(/,/ig, 'C') );
                    data.names.push($(this).find('.name').text());
                    //$(this).find('.value').html(_val + "<span>" + data.unit+"</span>");
                });  
                $(this).attr('id', 'donutchart'+(i+1));
                $(this).attr('data-values',data.values.toString());
                $(this).attr('data-values2',data.values2.toString());
                $(this).attr('data-colors',data.colors.toString());
                $(this).attr('data-names',data.names.toString());
                var _totValues = data.values.reduce((total, current) => {
                    return total + current;
                }, 0);
                $(this).attr('data-total',_totValues);
                data.values.forEach( (v,i) => {
                    var _per = Math.ceil((100 * v) / _totValues);
                    v = data.values2[i].replaceAll(/C/ig,',');
                    var isPerorUnit = v+'<span>'+data.unit+'</span>';
                    if(data.unit == '%'){
                        isPerorUnit = _per+data.unit;
                    } else if(data.unit != '' && !isNaN(data.unit.charAt(data.unit.length-1))){
                        var _newUnitV = data.unit.substring(0, data.unit.length - 1);
                        var _innerTag = "<span>"+data.unit.charAt(data.unit.length-1)+"</span>";
                        _innerTag = (_isSup) ? '<sup>'+data.unit.charAt(data.unit.length-1)+'</sup>' : _innerTag;
                        _innerTag = (_isSub) ? '<sub>'+data.unit.charAt(data.unit.length-1)+'</sub>' : _innerTag;
                        isPerorUnit = v+"<span>"+_newUnitV+_innerTag+"</span>";
                    }
                    data.percent.push(_per);
                    $rootElem.find('.data-items .data-item:eq('+i+') .value').html(isPerorUnit);
                    if($rootElem.hasClass('dc-100')){
                        $rootElem.find('.data-items .data-item:eq('+i+') .value').html(v+data.unit);
                    }
                });
                $(this).attr('data-percents',data.percent.toString());
            }
            _that.arrangeNameValue('#donutchart'+(i+1));
            _that.build_chart('#donutchart'+(i+1));
            _that.build_ct('#donutchart'+(i+1));
            $(this).find('.arcGrp path, .data-items .data-item').hover(function(){
                _that.pathHoverClick('mo',$(this),$rootElem);
            },
            function(){
                _that.pathHoverClick('moout',$(this),$rootElem);
            });
        });
        //}      
    }

    arrangeNameValue(elem){
        var larItem = 0;
        var itemWidth = $(elem).find('.data-item').width();
        $(elem).find('.data-item').each(function(){
            larItem = ( larItem > $(this).find('.value').width() ) ? larItem : $(this).find('.value').width();
        });
        var setNameW = itemWidth - (larItem + 48);
        $(elem).find('.data-item').each(function(){
            //$(this).find('.value').css('width',larItem+'px');
            $(this).find('.name').css('width',setNameW+'px');
        });
    }

    pathHoverClick(mType,$ce,$elem){
        if(mType == 'mo'){
            $ce.closest('.arcGrp').find('path').css('opacity',this.dimVal);
            $ce.css('opacity',1);
            var _idx = $ce.index();
            var _idxValue = $elem.attr('data-values').split(",");
            var _idxValueC = $elem.attr('data-values2').split(",");
            var _idxName = $elem.attr('data-names').split(",");
            var _unit = $elem.attr('data-unit');
            var lastChar = _unit.charAt(_unit.length-1);
            var _iSup = $elem.hasClass('unit-super-script');
            var _iSub = $elem.hasClass('unit-sub-script');
            var _innElem = '<span>'+lastChar+'</span>';
            _innElem = (_iSup) ? '<sup>'+lastChar+'</sup>' : _innElem;
            _innElem = (_iSub) ? '<sub>'+lastChar+'</sub>' : _innElem;
            $elem.find('.data-items .data-item, .arcGrp path').css('opacity',this.dimVal);
            $elem.find('.data-items .data-item:eq('+_idx+'), .arcGrp path:eq('+_idx+')').css('opacity',1);
            var _hoverVal = _idxValueC[_idx].replaceAll(/C/ig,',');
            $elem.find('.ct .opt-text').html(_hoverVal);
            $elem.find('.ct .opt-title').html(_idxName[_idx]);
            if(_unit != '' && (!isNaN(lastChar)) ){
                var _newUnit = _unit.substring(0, _unit.length - 1);
                $elem.find('.ct .opt-text').html(_hoverVal+"<span>"+_newUnit+_innElem+"</span>");
            } else if(_unit != '' && _unit != '%' && (isNaN(lastChar)) ){
                $elem.find('.ct .opt-text').html(_hoverVal+"<span>"+_unit+"</span>");
            }  else if($elem.hasClass('dc-100')){ 
                $elem.find('.ct .opt-text').html(_hoverVal+_unit);
            }
        } else {
            //$ce.closest('.arcGrp').find('path').css('opacity',1);
            $elem.find('.data-items .data-item, .arcGrp path').css('opacity',1);
            $elem.find('.ct .opt-text').html($elem.data('optionaltext'));
            $elem.find('.ct .opt-title').html($elem.data('optionaltitle'));
        }
    }

    build_ct(elem){
        //var _tot = $(elem).data('total');
        var _optText = $(elem).data('optionaltext');
        var _optTitle = $(elem).data('optionaltitle');
        $(elem+' .container .chart').append('<div class="ct"><p class="opt-title '+$(elem).data('fonttitle')+'">'+_optTitle+'</p><p class="opt-text '+$(elem).data('fonttext')+'">'+_optText+'</p></div>');
    }
    
    build_chart(chart_selector){
        var chart_w_h = $(chart_selector+' .container .chart').width();
        var chart_clrs = $(chart_selector).attr('data-colors').split(',');
        var chart_values = $(chart_selector).attr('data-values').split(',');
        //var chart_names = $(chart_selector).data('names').split(',');
        var pathWidth = 60;
        if(func.deviceMode() == 'tablet'){
            pathWidth = 25;
        }
        var w = chart_w_h, //( (func.deviceMode() == 'mobile') ? 320 : chart_w_h), //$(chart_selector).width(),
        h = chart_w_h, //( (func.deviceMode() == 'mobile') ? 320 : chart_w_h), //$(chart_selector).width(),
        r = Math.min(w, h) / 2,
        //dur = 750,
        color = chart_clrs, //d3.scale.category10(),
        donut = d3.layout.pie().sort(null),
        arc = d3.svg.arc().innerRadius(r - pathWidth).outerRadius(r - 0);
        var svg = d3.select(chart_selector+' .container .chart').append("svg:svg").attr("class", "").attr("width", w).attr("height", h);
        var arc_grp = svg.append("svg:g").attr("class", "arcGrp").attr("transform", "translate(" + (w / 2) + "," + (h / 2) + ")");
        //var label_group = svg.append("svg:g").attr("class", "lblGroup").attr("transform", "translate(" + (w / 2) + "," + (h / 2) + ")");
        //var center_group = svg.append("svg:g").attr("class", "ctrGroup").attr("transform", "translate(" + (w / 2) + "," + (h / 2) + ")");
        //var pieLabel = center_group.append("svg:p").attr("dy", ".35em").attr("class", "chartLabel").attr("text-anchor", "middle").text(data.label);
        var arcs = arc_grp.selectAll("path").data(donut(chart_values));
        arcs.enter().append("svg:path")
            //.attr("stroke", "white")
            //.attr("stroke-width", 1.5)
            .attr("fill", function(d, i) {return color[i];})
            .attr("d", arc)
            .each(function(d){
                //await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
                this._current = d
        });
    }  
    
}

export default DonutChart;