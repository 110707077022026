import $ from 'jquery';
import * as allFunc from './newsroom';

export const autoHideDur = 2000;
export let afClose = false;


export function afCloseGet(){
    return afClose;
}

export function afCloseSet(_b){
    afClose = _b;
}

export function curLoc(){
    var loc = window.location;
    return {
        fullUrl: loc.href,
        host: loc.host,
        hostname: loc.hostname,
        origin: loc.origin,
        pathname: loc.pathname,
        onlyUrl: loc.origin+loc.pathname,
        hash: loc.hash
    }
}

export function getScreenWidth(){
    return $(window).outerWidth();
}

export function getViewPortWidth(){
    return $(window).width();
}

export function getScrollWidth(){
    return window.innerWidth-$(document).width();
}

export function urlParam(name){
    let searchParams = new URLSearchParams(window.location.search);
    return {
        has: searchParams.has(name),
        get: searchParams.get(name)
    }
}

export function hasVScrollbar(element) {
    const scrollHeight = element.get(0).scrollHeight;
    const clientHeight = element.get(0).clientHeight;
    return scrollHeight > clientHeight;
}

export function getBannersHeight(pos = null){
    let bannerH = 0;
    if($(".event-banner").length == 1){
        bannerH = $(".event-banner").outerHeight();
    }
    $('.ikea-header-banner').each(function(){
        bannerH += $(this).outerHeight();
    });
    if(pos == 'sticky'){
        bannerH += $(".navbar__main-bar").outerHeight();
    }
    return bannerH;
}

export function hexc(colorval) {
    var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    delete(parts[0]);
    for (var i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length == 1) parts[i] = '0' + parts[i];
    }
    return '#' + parts.join('');
}

export function debounce(func, time){
    var time = time || 200;
    var timer;
    return function(event){
        if(timer) clearTimeout(timer);
        timer = setTimeout(func, time, event);
    };
}

export function bytesConvertion(bytes){
    if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed() + " MB"; }
    else { bytes = (bytes / 1024).toFixed() + " KB"; }
    return bytes;
}

export function browserType(){
    if ( navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1 ) {
        return 'Edge';
    }
    else if( navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1 )
    {
        return 'Opera';
    }
    else if( navigator.userAgent.indexOf("Chrome") != -1 )
    {
        return 'Chrome';
    }
    else if( navigator.userAgent.indexOf("Safari") != -1)
    {
        return 'Safari';
    }
    else if( navigator.userAgent.indexOf("Firefox") != -1 ) 
    {
        return 'Firefox';
    }
    else if( ( navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true ) ) //IF IE > 10
    {
        return 'IE';
    }  
    else 
    {
        return 'unknown';
    }
}

export function downloadAsset(assetUrl,fName){
	fetch(assetUrl, {
		method: 'GET',
	})
	.then(resp => resp.blob())
	.then(blob => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = fName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	})
}

export function checkPreviewMode(){
    var isPreview = curLoc().fullUrl.search("sc_mode=edit");
    if(isPreview == -1)return true; else return false;
}

export function copyToClipboard(str){
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(str).select();
    document.execCommand("copy");
    $temp.remove();
    return true;
}

export function deviceDimensions(){
    var dw = $( window ).width();
    var dh = $( window ).height();
    return { winWidth: dw, winHeight: dh};
}

export function deviceMode(){
    var ww = deviceDimensions().winWidth;
    switch (true)
    {
        case (ww < 576):    return 'mobile';    // 0 - 575px -- mobile
        case (ww >= 576 && ww < 992):   return 'tablet';    // 576 - 991 -- Tablet
        default:    return 'desktop';   // 992 -     -- Desktop
    }
}

export function elementDimensions($this){
    var device = deviceDimensions();
    var pos = $this.position();
    var eleW = $this.width();
    var right = device.winWidth - (pos.left + eleW);
    return {left: pos.left, top: pos.top, right: right};
}

export function checkImageExists(imageUrl, callBack) {
    var imageData = new Image();
    imageData.onload = function() {
        callBack(true);
    };
    imageData.onerror = function() {
        callBack(false);
    };
    imageData.src = imageUrl;
}

export function callAjax(url,data,dataType,type,callback){
    $.ajax({
        type : type,
        //cache : false,
        url : url,        
        data: data,
        dataType : dataType,
        xhrFields: { withCredentials: true },
        success : function(data){
            callback(true,data);
        },
        error : function(jqXHR, exception) {
            //console.error('Something went wrong...');
            callback(false,'<h3>Error: '+jqXHR.statusText+' ['+jqXHR.status+'] </h3>');
        }
    });
}

export function getVideoDuration(elemId,tarElemId){
    var video = document.getElementById(elemId);
	var onDurationChange = function(){
		if(video.readyState){
            var ms = parseInt(video.duration / 60, 10);
            var ss = parseInt(video.duration % 60);
            if(ss == "0")ss = "00";
            tarElemId.text(ms+":"+ss);
		}
	};
	video.addEventListener('durationchange', onDurationChange);
	onDurationChange();
}

export function isValidEmailAddress(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

export function isTextLengthValidate(txt) {
    var txtValidate = txt.length >=30 && txt.length <=1000;
    return txtValidate; 
}

export function socialMinisite(){
    if($(".social-mini-site").length > 0){
        var setHeight = ($( window ).height()) - ( $("header").outerHeight() + $("footer").outerHeight() );
        $(".social-mini-site main .background").css('min-height',setHeight);
    }

    //new script for social mini site page scroll implemenation
    var headerHeight = $("header").outerHeight();
    $(".social-mini-site main").css('margin-top', headerHeight); 
    var headerEl = $('.social-mini-site header');
    $(window).scroll(function(){
        var lastScroll = $(window).scrollTop();
        if(lastScroll >=50){
            headerEl.find('.rich-text').slideUp('slow');//addClass("hidden-text");
        }else{
            headerEl.find('.rich-text').slideDown('fast'); //.removeClass("hidden-text");
        }
    });
}

export function touchDetect(el, callback){ 
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 150,
    restraint = 100,
    allowedTime = 300,
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){}

    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        //dist = 0
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime()
        //e.preventDefault()
    }, false)

    touchsurface.addEventListener('touchmove', function(e){
        //e.preventDefault() // prevent scrolling when inside DIV
    }, false)

    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX 
        distY = touchobj.pageY - startY 
        elapsedTime = new Date().getTime() - startTime 
        if (elapsedTime <= allowedTime){
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ 
                swipedir = (distX < 0)? 'left' : 'right' 
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ 
                swipedir = (distY < 0)? 'up' : 'down'
            }
        }
        handleswipe(swipedir)
        //e.preventDefault()
    }, false)
}

export function imageAutoSlider(){
    let slider = $('.slide-show');
    slider.each(function (ind) {
        var hasAutoSlide = $(this).hasClass('slide-show-autoplay');
        var _that = $(this);
        if(hasAutoSlide){
            let sl = $(this).find('.slides').length;
            let count = 0;
            let next = $(this).find('.next');
            let prev = $(this).find('.prev');
            let track = $(this).find('.track > span');

            $(this).find('.slides:first').addClass('active');
            $(this).find('.track > span:first').addClass('active');

            if(sl > 1){
                $(this).find('a.icons').css('display','flex')
                $(this).find('.track').css('display','flex')
            }else{
                $(this).find('a.icons').css('display','none')
                $(this).find('.track').css('display','none')
            }


            //Auto play slider
            var deletInterval = setInterval(timerFunc , 4000);
            var setIntervalFun = function(){
                clearInterval(deletInterval);
                deletInterval = setInterval(timerFunc , 4000);
            }
            function timerFunc(){
                if(sl > 1 && checkPreviewMode())
                    moveRight();
                else return false
            }

            // touch functionality
            touchDetect(this, function(swipedir){
                if (swipedir =='left')
                    next.trigger('click');
                if (swipedir =='right')
                    prev.trigger('click');
            });

            //next click function
            next.click(function(e){
                e.preventDefault();
                moveRight();
                setIntervalFun();
            })

            //prev click function
            prev.click(function(e){
                e.preventDefault();
                moveLeft();
                setIntervalFun();
            });

            function moveRight(){
                _that.find('.slides')[count].style.animation = 'next1 1s ease-in-out forwards';
                if(count >= sl-1){
                    count = 0;
                }else{
                    count++;
                }
                _that.find('.slides')[count].style.animation = 'next2 1s ease-in-out forwards';
                indicators(track,count);
            
            }
        
            function moveLeft(){
                _that.find('.slides')[count].style.animation = 'prev1 1s ease-in-out forwards';
                if(count == 0){
                    count = sl-1;
                }else{
                    count--;
                }
                _that.find('.slides')[count].style.animation = 'prev2 1s ease-in-out forwards';
                indicators(track, count);
            }
            
            function indicators(dots,count){
                for(let i = 0; i <dots.length; i++) {
                    dots[i].className = dots[i].className.replace('active', '');
                }
                dots[count].className  += 'active';
            }

            $(this).find('.track span').on('click', function(e,i){
                e.preventDefault();
                console.log(ind,  $(this), count);
                $(this).addClass('active').siblings().removeClass('active');
                var trackIndex = $(this).index();
                if(trackIndex > count){
                    _that.find('.slides')[count].style.animation = 'next1 1s ease-in-out forwards';
                    count = trackIndex;
                    _that.find('.slides')[count].style.animation = 'next2 1s ease-in-out forwards';
                    setIntervalFun();

                }else if(trackIndex == count){
                    count = trackIndex;
                    return;
                }else{
                    _that.find('.slides')[count].style.animation = 'prev1 1s ease-in-out forwards';
                    count = trackIndex;
                    _that.find('.slides')[count].style.animation = 'prev2 1s ease-in-out forwards';
                    setIntervalFun();
                }
            });
        }
       //Slide show image download US
        _that.find(".image-slide-show .slides").each(function(){
            var getThis = $(this);
            $(this).find('.figure-image__download a.download').click(function(e,i){
                if(localStorage.getItem('useRestriction') === 'true'){
                    allFunc.getHref($(this));
                    allFunc.showToast('Downloading...');
                }else{
                    e.preventDefault();
                    getThis.find(".ikea-img-download-popup.imgDownload-dialog").show().css("display","flex");
                    $("body").css('overflow','hidden');
                    if(hasAutoSlide) clearInterval(deletInterval);
                }
                getThis.find('.figure-image__download > .imgDownload-dialog .btn-down > a').click(function(e){
                    var isCheck = getThis.find('.figure-image__download > .imgDownload-dialog .gdpr-text #donotAsk').is(':checked');
                    if(isCheck === true){
                        localStorage.setItem('useRestriction', true);
                    }
                    allFunc.getHref($(this));
                    allFunc.showToast('Downloading...');
                    allFunc.closeImgPopup();
                    if(hasAutoSlide) setIntervalFun();
                });
                $(document).on('click','.ikea-img-download-popup, .ikea-img-download-popup .close',function(event){
                        allFunc.closeImgPopup();
                        if(hasAutoSlide) setIntervalFun();
                });
                $(document).on('keyup', function(event) {
                    if (event.key == "Escape") {
                        allFunc.closeImgPopup();
                        if(hasAutoSlide) setIntervalFun();
                    }
                });
            })
        });



    });

} 


export function colorPicker(){
    if( urlParam('cp').get == 'render'){
        var _clrsArr = ['#6B3D99','#E5456D','#CC0008','#F26C0C','#FFAA00','#FFDB00','#C7D900','#78BF30','#00873F','#009985','#00A1E5','#0058A3'];
        var _clrs12 = '<p class="sub-title">Standard colors</p><div class="ikea-clrs">';
        for(let i=0; i<_clrsArr.length; i++){
            _clrs12 += '<div class="clr-box" style="background:'+_clrsArr[i]+';border: 1px solid '+_clrsArr[i]+';"><div><label>'+_clrsArr[i]+'</label><a class="copy-code">Copy</a></div></div>';
        }
        _clrs12 += '</div>';
        var _html = '<div id="clr-picker"><h3>Pick a Color<span class="close">X</span></h3><label>Select Color</label><a class="copy-code">Copy</a><input type="color" id="txtColor"></input>'+_clrs12+'</div>';
        $(_html).appendTo('body');
        $(document).on('input','#txtColor',function(){
            var clrVal = $("#txtColor").val();
            $("#clr-picker > label").html(clrVal);
            $("#txtColor").css('background-color',clrVal);
        });
        if( urlParam('cp-dis').get == 'show'){
            setTimeout(function(){
                $("#clr-picker").addClass('show');
            },2000);            
        }
        if( urlParam('cp-ver').get == 'v2'){            
            $("#clr-picker").addClass('v2');                      
        }
        $(document).on('click','#clr-picker',function(e){
            if(e.target.className == 'close'){
                $("#clr-picker").removeClass('show');
            } else if(e.target.className == 'copy-code'){
                var _copyCode = $(e.target).siblings('label').text();
                if(_copyCode == 'Select Color'){
                    alert('Please Select Color.'); return true;
                } else if(copyToClipboard(_copyCode)){
                    $(e.target).text('Copied');
                    setTimeout(function(){
                        $(e.target).text('Copy');
                    },3000);
                }                
            }
        });
    }    
}


export function lightDarkColor(col,amt) {
    var usePound = false;
    if ( col[0] == "#" ) {
        col = col.slice(1);
        usePound = true;
    }
    var num = parseInt(col,16);
    var r = (num >> 16) + amt;
    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;
    var b = ((num >> 8) & 0x00FF) + amt;
    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;    
    var g = (num & 0x0000FF) + amt;
    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

/* Icons *****/
export function extIcon(clr = 'white'){
    return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9989 4.99611V3.99609L18.9989 3.99611L14.002 3.99621L14.002 5.9962L16.5847 5.99615L11.2887 11.2924L12.703 12.7065L17.9989 7.4104L17.9989 9.99908L19.9989 9.99908V4.99611ZM4.00195 19.9993V18.9993V14.9993V8.99926V4.99923V3.99923L5.00195 3.99923L8.19629 3.99923L12.002 3.99923L12.002 5.99923H8.19629L6.00195 5.99923L6.00195 8.99926L6.00195 14.9993V17.9993H8.19629L14.002 17.9993H18.002L18.002 11.9993H20.002V18.9993V19.9993H19.002H14.002H8.19629H5.00195H4.00195Z" fill="${clr}"/>
            </svg>`;
}