import $, { trim } from 'jquery';
import * as func from './onlyExport';
//let isRecaptcha;

function doSubscribe(){
    var $elem = $(".subscription-sign-up");
    if( func.isValidEmailAddress($elem.val()) ){
        //$(".subscription-page form").submit();
        alertErrorMessage(false,'none',$elem);
    } else {
        alertErrorMessage(true,'block',$elem);
    }
}

function alertErrorMessage(togg,dis,$elem){
    $elem.removeClass('invalid-emailaddress');
    if(togg)$elem.addClass('invalid-emailaddress').focus();
    $(".help-text").css('display',dis);
}

function errorIcon(){
    return '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">' +
    '<circle cx="12" cy="12" r="8" fill="#E00751"/>' +
    '<path d="M12.9482 13.1025H10.9447V7.10254H12.9447L12.9482 13.1025Z" fill="white"/>' +
    '<path d="M10.6982 15.8525C10.6982 15.1622 11.2579 14.6025 11.9482 14.6025C12.6386 14.6025 13.1982 15.1622 13.1982 15.8525C13.1982 16.5429 12.6386 17.1025 11.9482 17.1025C11.2579 17.1025 10.6982 16.5429 10.6982 15.8525Z" fill="white"/>' +
    '</svg>';
}

function checkRecaptcha(){
    if(typeof grecaptcha != 'undefined' && grecaptcha.getResponse().length > 0 && ($("#gdprPrivacy").is(':checked') == true) && ($(".subscription-sign-up").hasClass('valid-input')) )
        togButton(true);
    else 
        togButton(false);
}

function togButton(boool = false){
    $(".subscription-page .btn-emphasised").removeClass('enabled');
    if(boool){
        $(".subscription-page .btn-emphasised").addClass('enabled');
        //$(".subscription-page .googleRecaptchaClass").val(grecaptcha.getResponse());
        //console.error( $(".subscription-page .googleRecaptchaClass").length);
    }
}


function formValidate($elem,type, currentEl){
    $elem.removeClass("empty-error-state invalid-emailaddress valid-input"); 
    $('.subscription-page .help-text').hide();
    var ipData = $.trim($elem.val());
    var textData = $.trim($elem.val());
    togButton();
    
    if(ipData == "" && (type == 'b') ){
        $elem.addClass("empty-error-state");
        $('.subscription-page .help-text').css('display','flex').html(errorIcon() + ' <label>This field may not be empty</label>');
    } else if(ipData != "" && !func.isValidEmailAddress(ipData) && type == 'b'){
        $elem.addClass("invalid-emailaddress");
        $('.subscription-page .help-text').css('display','flex').html(errorIcon() + ' <label>Please enter a valid email address</label>');;
    } else if(ipData != "" && func.isValidEmailAddress(ipData)){        
        $elem.addClass("valid-input");
    }
    if(($("#gdprPrivacy").is(':checked') == true) && ($(".subscription-sign-up").hasClass('valid-input'))){
        //isRecaptcha = setInterval(checkRecaptcha,500);
        togButton(true);
    }
 
    //Contact form @ first name validation
    var nameRE = /^[a-zA-Z ]*$/;
    var queRE =  /^[a-zA-Z 0-9\&\@\$\(\)\/\'\?\-\_\;\"\:\%\.\!\,]*$/;

    if(ipData == "" && (type == 'na')){
        $elem.addClass("empty-error-state");
        currentEl.find('#name+.help-text').css('display','flex').find('label').text('This field may not be empty.');
    }else if( ipData != "" && (type == 'na' || type=='ka') && ((!nameRE.test(ipData)) || (ipData.length > 50)) ){
        $elem.addClass("empty-error-state");
       currentEl.find('#name+.help-text').css('display','flex').find('label').text('Only space and alphabets are allowed, Maximum characters 50.');
    } else if(ipData != "" && (type == 'na' || type=='ka') && (nameRE.test(ipData))){
        $elem.addClass("valid-input");
        $('.valid-input+.help-text').css('display','none');
    }
   
    //Contact form @ EMAIL validation
    if( ipData == "" && (type == 'em')){
        $elem.addClass("empty-error-state");
        currentEl.find('.empty-error-state+.help-text').css('display','flex');
        currentEl.find('.empty-error-state+.help-text+.help-text, .invalid-emailaddress+.help-text').css('display','none');
    }else if(ipData != "" && !func.isValidEmailAddress(ipData) && type == 'em'){
        $elem.addClass("invalid-emailaddress");
        currentEl.find('.empty-error-state+.help-text+.help-text, .invalid-emailaddress+.help-text').css('display','none');
        currentEl.find('.invalid-emailaddress+.help-text+.help-text').css('display','flex');
    }else if(ipData != "" && func.isValidEmailAddress(ipData)){        
        $elem.addClass("valid-input");  
        currentEl.find('.valid-input+.help-text, .valid-input+.help-text+.help-text').css('display','none');          
    }

    //Contact form @ Qustion textarea validation
    if( textData == "" && (type == 'qu')){
        $elem.addClass("invalid-question empty-error-state");
        currentEl.find('.invalid-question+.help-text+.help-text, .invalid-question+.help-text').css('display','none');
        currentEl.find('.invalid-question+.help-text').css('display','flex');
    } else if(textData != "" && (type == 'qu' || type=="ku") && !func.isTextLengthValidate(textData) && !queRE.test(textData) ){
        $elem.addClass("invalid-question empty-error-state");
        currentEl.find('.invalid-question+.help-text+.help-text, .invalid-question+.help-text').css('display','none');
        currentEl.find('.invalid-question+.help-text+.help-text').css('display','flex').find('label').text('Only few special characters and 30 - 1000 characters are allowed');
    } else if(textData != "" && (type == 'qu' || type=="ku") && !func.isTextLengthValidate(textData) ){
        $elem.addClass("invalid-question empty-error-state");
        currentEl.find('.invalid-question+.help-text+.help-text, .invalid-question+.help-text').css('display','none');
       currentEl.find('.invalid-question+.help-text+.help-text').css('display','flex').find('label').text('Minimum 30 and Maximum 1000 characters are allowed.');
    } else if(textData != "" && (type == 'qu' || type=="ku") && !queRE.test(textData) ){
        $elem.addClass("invalid-question empty-error-state");
        currentEl.find('.invalid-question+.help-text+.help-text, .invalid-question+.help-text').css('display','none');
        currentEl.find('.invalid-question+.help-text+.help-text').css('display','flex').find('label').text('Only few special characters are allowed.');
    }  else if(textData != "" && func.isTextLengthValidate(textData) && (type == 'qu' || type=="ku")){      
        $elem.addClass("valid-input");  
        currentEl.find('.valid-input+.help-text, .valid-input+.help-text+.help-text').css('display','none');          
    }

    if(type =='loc'){
        if( currentEl.find("#location").val() == '' ){
            currentEl.find("#location+.list-arrow+.help-text").css('display','flex');
            currentEl.find("#location").addClass('empty-error-state');
        } else if( currentEl.find("#location").attr('location') == ''){
            currentEl.find("#location+.list-arrow+.help-text+.help-text").css('display','flex');
            currentEl.find("#location").addClass('empty-error-state');
        } else {
            currentEl.find("#location").addClass('valid-input');        
        } 
    }
if(type == 'b' || type == 'na' || type == 'ka' || type == 'em' || type == 'qu' || type=="ku" || type =='loc' || type == "ch"){
    var formInputValid  =  ( (currentEl.find('#name').val() != "") && (currentEl.find('#name').val().length < 51) && ( nameRE.test(currentEl.find('#name').val()) ) && (currentEl.find('#location').attr('location') != "") &&
    (currentEl.find('#emailaddress').val() != "" && func.isValidEmailAddress(currentEl.find('#emailaddress').val())) &&
    (currentEl.find('#question').val() !=""  && ( queRE.test(currentEl.find('#question').val()) ) && func.isTextLengthValidate(currentEl.find('#question').val())) &&
    (currentEl.find("#chkPrivacy").is(':checked') == true));
    if(formInputValid ){
        btnform(true, currentEl)
    }else{
         btnform(false, currentEl)
    }
}
  

}

function btnform(boool = false, currentEl){
    currentEl.find(".btn").attr('disabled','true');
    if(boool){
        currentEl.find(".btn").removeAttr('disabled')
    };
}

function manageLocations(_b, $elem, currentEl){
    currentEl.find(".ddl-locations").css('display',_b);
    currentEl.find("#location+.list-arrow+.help-text, #location+.list-arrow+.help-text+.help-text").css('display','none');
    if(_b == 'block'){
        filterLocations($elem, currentEl);
        currentEl.find(".list-arrow").addClass('rotate');
    } else {
        formValidate($elem,'loc', currentEl);
        currentEl.find(".list-arrow").removeClass('rotate');
    }
}

function filterLocations($elem, currentEl){  
    var _lv = $elem.val();
    currentEl.find(".ddl-locations .item:not(.sticky)").each(function(){
        var _iv = ( $(this).attr('value') ).toLowerCase();
        if( _iv.startsWith(_lv.trim().toLowerCase()) || _lv.trim() == ''){
            $(this).css('display','block');
        } else {
            $(this).css('display','none');
        }
    })
}

export function initSubscription(){
    $(document).ready(function() {
        if( $(".subscription-page").length > 0){
            $(".subscription-sign-up").attr("required", true);
            $(".subscription-sign-up")
            .focus(function(){
                formValidate($(this),'f');
            })
            .blur(function(){                                     
                formValidate($(this),'b');
            })
            .keyup(function(){
                formValidate($(this),'k');
            });
            $("#gdprPrivacy").click(function(){
                formValidate($(this),'ch');
            });
            if( $(".subscription-page .help-text").length == 0){
                $("<label class='help-text'></label>").insertAfter($(".subscription-sign-up"));
                $(".subscription-page .btn-emphasised").click(doSubscribe);
            }
           
            //.attr("type", "button")
        }   
        
        //Contact form code

        $('.contact-form').each(function(i){
            var _thisForm = $(this);
            _thisForm.find("input[type=text],input[type=email],textarea,#chkPrivacy").attr("required", true);
            _thisForm.find("#name")
                .focus(function(){
                    formValidate($(this),'fa', _thisForm);
                })
                .blur(function(){                                     
                    formValidate($(this),'na', _thisForm);
                })
                .keyup(function(){
                    formValidate($(this),'ka', _thisForm);
                });
                
            _thisForm.find("#emailaddress")
                .focus(function(){
                    formValidate($(this),'fm', _thisForm);
                })
                .blur(function(){                                     
                    formValidate($(this),'em', _thisForm);
                })
                .keyup(function(){
                    formValidate($(this),'km', _thisForm);
                });
    
            _thisForm.find("#location")
                .focus(function(){
                    manageLocations('block', $(this), _thisForm);
                })
                .blur(function(){ 
                    var _that = $(this);
                    setTimeout(() => {
                        manageLocations('none', _that, _thisForm);
                    },300);
                })
                .keyup(function(){
                    $(this).attr('location','');
                    filterLocations($(this), _thisForm);
                });
    
            _thisForm.find(".ddl-locations  .item").click(function(){
                    var _sv = $(this).attr('value');
                    _thisForm.find("#location").val(_sv).attr('location',_sv);
                    manageLocations('none', _thisForm.find("#location"), _thisForm);
                })
            
            _thisForm.find("#question")
                .focus(function(){
                    formValidate($(this),'fu', _thisForm);
                })
                .blur(function(){                                     
                    formValidate($(this),'qu', _thisForm);
                })
                .keyup(function(){
                    formValidate($(this),'ku', _thisForm);
                });
            
            _thisForm.find("#chkPrivacy").click(function(){
                    formValidate($(this),'ch', _thisForm);
                });
    
            _thisForm.find(".btn").click(function(){
                    var _that = $(this);
                    var _elemPos = $(this).parent().closest('.contact-form').offset().top;
                    var backtoFormEl = "<div class='backToForm'>Go back to contact form </div>"
                    $(this).addClass('btn-loading');
                    var $elem =  _thisForm.find("form");
                    var clearForm = function(){
                        $elem[0].reset();
                        $elem.show();
                        $elem.find('#name').removeClass('valid-input');
                        $elem.find('#emailaddress').removeClass('valid-input');
                        $elem.find('#location').removeClass('valid-input');
                        $elem.find('#question').removeClass('valid-input');
                        $elem.find(".btn").attr('disabled','true');
                    }
                    var _data = {
                        //FirstName: $elem.find('#firstname').val(),
                        //LastName: $elem.find('#lastname').val(),
                        Name: $elem.find('#name').val(),
                        EmailAddress: $elem.find('#emailaddress').val(),
                        Location: $elem.find('#location').attr('location'),
                        Query: $elem.find('#question').val(),
                        FormItemGuid: $elem.find('[name="FormItemGuid"]').val()
                    };
                    var _postData = {scController: "ContactForm", scAction: "SubmitContactForm", data: _data, Name: _data.Name, EmailAddress: _data.EmailAddress, Location: _data.Location, Query: _data.Query, FormItemGuid: _data.FormItemGuid};
                    func.callAjax(
                        window.location.href,
                        _postData,
                        'json',
                        'POST',
                        function(res,data){
                            _that.removeClass('btn-loading');
                            //responseData = JSON.parse(data);
                            if(data.length == 2)
                            {
                                if(data[0] == "false"){
                                        if($(".error-msg").length == 0){
                                            _thisForm.find(".description").after(data[1])
                                        }
                                }else{
                                    _thisForm.find(".component-content").find('form').after(data[1]);
                                    _thisForm.find(".component-content").find('form').hide();
                                    _thisForm.children().append(backtoFormEl);
                                    _thisForm.find(".backToForm").click(function(){
                                        _thisForm.find(".component-content").find('form').nextAll().remove();
                                        clearForm();
                                    });
                                }
                            }else{
                                _thisForm.find(".component-content").html(data[0]);
                            }
                            $('html, body').stop().animate({
                                scrollTop: _elemPos - 200
                            }, 0); 
                        }
                    );
                });
        });
    });    
}

 