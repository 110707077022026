import $ from 'jquery';
import * as func from './onlyExport';

var getWindowOptions = function () {
    var width = 700;
    var height = 350;
    var left = (window.innerWidth / 2) - (width / 2);
    var top = (window.innerHeight / 2) - (height / 2);

    return [
        'resizable,scrollbars,status',
        'height=' + height,
        'width=' + width,
        'left=' + left,
        'top=' + top
    ].join();
};

function socialShare(event){
    //var win = window.open(event.data.url, event.data.title, getWindowOptions());
    window.open(event.data.url);
    //win.opener = null;
}

function iconPaths(){
    var fbPath = '<path d="M12 21.976c-5.51 0-9.976-4.466-9.976-9.976 0-5.51 4.466-9.976 9.976-9.976 5.51 0 9.976 4.466 9.976 9.976 0 5.51-4.466 9.976-9.976 9.976zm.589-4.726v-4.51h1.505l.225-1.757h-1.73V9.862c0-.508.14-.855.866-.855h.925V7.435c-.16-.021-.71-.07-1.348-.07-1.335 0-2.248.82-2.248 2.323v1.296h-1.51v1.757h1.51v4.509h1.805z"/>';
    var twPath = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 3 30 30" fill="none"><circle cx="20.5" cy="20.5" r="20.5" fill="#111111"></circle><path d="M21.3691 20.0853L26.6042 14H25.3636L20.818 19.2839L17.1875 14H13L18.4901 21.9901L13 28.3715H14.2407L19.041 22.7916L22.8752 28.3715H27.0626L21.3689 20.0853H21.3693H21.3691ZM19.6698 22.0604L19.1136 21.2648L14.6876 14.9339H16.5931L20.165 20.0431L20.7213 20.8387L25.3642 27.48H23.4587L19.67 22.0606V22.0603L19.6698 22.0604Z" fill="white"></path></svg>';
    var liPath = '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.23 17H7.16v-6.68h2.08V17zM8.2 9.41c-.67 0-1.2-.54-1.2-1.2C6.99 7.54 7.53 7 8.2 7a1.206 1.206 0 0 1 0 2.41zM17.01 17h-2.07v-3.25c0-.77-.01-1.77-1.08-1.77-1.08 0-1.25.84-1.25 1.72V17h-2.07v-6.68h1.99v.91h.03c.28-.53.95-1.08 1.96-1.08 2.1 0 2.49 1.38 2.49 3.18V17z"/>';
    var emailPath = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-10.5 -10.5 45 45" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.00391 4H2.00391V5V19V20H3.00391H21.0015H22.0013L22.0015 19.0002L22.0039 5.00018L22.0041 4H21.0039H3.00391ZM4.00391 7.20815V18H20.0016L20.0035 7.21109L12.5819 12.3878L12.0101 12.7866L11.4381 12.3881L4.00391 7.20815ZM18.2439 6H5.76842L12.0095 10.3486L18.2439 6Z" fill="white"></path></svg>';
    var clPath = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 35 35" fill="none"><circle cx="20.5" cy="20.5" r="20.5" fill="#111111"></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M24.5353 17.879L18.8785 23.5358L17.4643 22.1216L23.1211 16.4648L24.5353 17.879Z" fill="white"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M25.9496 15.0506C24.778 13.879 22.8785 13.879 21.7069 15.0506L20.2927 16.4648L18.8785 15.0506L20.2927 13.6363C22.2453 11.6837 25.4112 11.6837 27.3638 13.6363C29.3164 15.589 29.3164 18.7548 27.3638 20.7074L25.9496 22.1216L24.5353 20.7074L25.9496 19.2932C27.1211 18.1216 27.1211 16.2221 25.9496 15.0506Z" fill="white"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.0501 24.95C17.2216 26.1216 19.1211 26.1216 20.2927 24.95L21.7069 23.5358L23.1211 24.95L21.7069 26.3643C19.7543 28.3169 16.5885 28.3169 14.6359 26.3643C12.6832 24.4116 12.6832 21.2458 14.6359 19.2932L16.0501 17.879L17.4643 19.2932L16.0501 20.7074C14.8785 21.879 14.8785 23.7785 16.0501 24.95Z" fill="white"></path></svg>';

    return {
        fb : fbPath,
        tw : twPath,
        lin : liPath,
        em : emailPath,
        cl : clPath
    }
}

function appendPaths(){
    $('.ikea_facebook-icon_spriteicon svg').html(iconPaths().fb);
    $('.ikea_twitter-icon_spriteicon svg').html(iconPaths().tw);
    $('.ikea_linkedin-share-icon_spriteicon svg').html(iconPaths().lin);
    $('.ikea_email_spriteicon svg').html(iconPaths().em);
    $('.ikea_copylink_spriteicon svg').html(iconPaths().cl);
}

function setMailOptions(){
    const emailSub = "Sharing this page";
    let emailBody = "Hi there, I wanted to share it with you. Check it out";
    emailBody += '%0D%0A%0D%0A'+func.curLoc().onlyUrl+'%0D%0A%0D%0A';
    const emailHref = "mailto:?subject="+emailSub+"&body="+emailBody;
    $('.ikea_email_spriteicon').attr('href',emailHref);
}

function copyLinkinShare(){
    var ctc = func.copyToClipboard(func.curLoc().onlyUrl);
    var elemPos = func.elementDimensions($(this));
    if(func.deviceMode() == 'mobile'){
        var styExtend = 'right:1rem;';
        elemPos.top = 160;
    } else {
        var styExtend = 'left:'+elemPos.left+'px;';
    }
    var styleAttr = 'style="position:absolute;display:block;top:'+(elemPos.top)+'px;'+styExtend+'"';  
    var getElem = generateTooltip(styleAttr);
    $("body").append(getElem);
    setTimeout(removeNotify,2000);
}

function removeNotify(){
    //if( $(".ikea_copylink_notify").length > 0)$(".ikea_copylink_notify").remove();
    if( $(".copylink-notification.when-socialshare-only").length > 0)$(".copylink-notification.when-socialshare-only").remove();
}

function generateTooltip(styleAttr){
    // var _html = '<div class="ikea_copylink_notify" '+styleAttr+'>';
    // _html += '<span>Copied to clipboard</span>';
    // _html += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">';
    // _html += '<path d="M13.45,12,16.71,8.7,15.29,7.3,12,10.59,8.74,7.32,7.33,8.75,10.62,12,7.36,15.3l1.42,1.4L12,13.41l3.32,3.26,1.41-1.42Z"/>';
    // _html += '</svg>';
    // _html += '</div>';
    var _html = '<span class="copylink-notification when-socialshare-only" '+styleAttr+'>' +
    '<svg class="info-icon"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
        '<path d="M12 9.5c.6904 0 1.25-.5596 1.25-1.25S12.6904 7 12 7s-1.25.5596-1.25 1.25S11.3096 9.5 12 9.5zM11 11v6h2v-6h-2z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10 5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10zM4 12c0-4.4183 3.5817-8 8-8s8 3.5817 8 8-3.5817 8-8 8-8-3.5817-8-8z"></path>' +
    '</svg>' +
    '<span class="heading">Copied Link</span>' +
    '<span class="message">You have successfully copied link</span>   ' +
    '<span class="close">' +
        '<svg class="close-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="m12.0006 13.4148 2.8283 2.8283 1.4142-1.4142-2.8283-2.8283 2.8283-2.8283-1.4142-1.4142-2.8283 2.8283L9.172 7.7578 7.7578 9.172l2.8286 2.8286-2.8286 2.8285 1.4142 1.4143 2.8286-2.8286z"></path>' +
        '</svg>' +
    '</span>' +
    '</span>';
    return _html;
}


export function initSocialLinks() {
    $(document).ready(function() {
        const locURL = window.location.href;
        const fbURL = 'https://www.facebook.com/sharer/sharer.php?u='+locURL;
        const twitterURL = 'https://twitter.com/intent/tweet?url='+locURL;
        const linkedinURL = 'http://www.linkedin.com/shareArticle?mini=true&url='+locURL;
        $('.ikea_facebook-icon_spriteicon').attr('href',fbURL);
        $('.ikea_twitter-icon_spriteicon').attr('href',twitterURL);
        $('.ikea_linkedin-share-icon_spriteicon').attr('href',linkedinURL);
        appendPaths();
        setMailOptions();
        $(".ikea_copylink_spriteicon").click(copyLinkinShare);        
    });
    $(document).on("click", ".copylink-notification.when-socialshare-only svg.close-icon" ,removeNotify);

    $(".icon--facebook").on("click",function(){
        if(typeof _satellite != 'undefined'){
            _satellite.track('sharearticle',{
                share:'facebook',
                page:digitalData.page_name
            });
        }
       
    });
    $(".icon--email").on("click",function(){
        if(typeof _satellite != 'undefined'){
            _satellite.track('sharearticle',{
                share:'email',
                page:digitalData.page_name
            });
        }
    });
    $(".icon--twitter").on("click",function(){
        if(typeof _satellite != 'undefined'){
            _satellite.track('sharearticle',{
                share:'twitter',
                page:digitalData.page_name
            });
        }
        
    });
    $(".icon--copylink").on("click",function(){
        if(typeof _satellite != 'undefined'){
            _satellite.track('sharearticle',{
                share:'link',
                page:digitalData.page_name
            });
        }
    });      
    $(".icon--linkden").on("click",function(){
        if(typeof _satellite != 'undefined'){
            _satellite.track('sharearticle',{
                share:'linkedin',
                page:digitalData.page_name
            });
        }
        
    });
}