import $ from 'jquery';
import * as func from '../components/onlyExport';
import { isElemInViewPort } from './isInViewPort';


class LikeShare {
    constructor(selectors) {
        this.start = this.start.bind(this);
        var cThis = this;
        this.pageid = $(".breadcrumb__list-item.last").data('breadcrumbid');
        this.handleLikeShare = this.handleLikeShare.bind(this);
        this.pageLikes = JSON.parse(localStorage.getItem("myfavourites") || "[]");
        if($(".article-page").length > 0){
            this.start();
            $(window).scroll(cThis.handleLikeShare);
        }
    }
    start(){   
        var _that = this;     
        var _btnHtml = this.forDesktop();
        var _mobHtml = this.forMobTab();
        //$(_btnHtml).insertBefore("#wrapper > main > div:first-child");
        $("#wrapper > main").prepend(_btnHtml);
        $(_mobHtml).insertAfter(".field-title");
        this.updateLikes();
        this.handleLikeShare();
        this.likeAction('GetLikeCount');
        if(func.checkPreviewMode()){
            $(".like-share svg, .article-social-like svg").click(function(){
                if($(this).hasClass('like_icon')){
                    if($(this).hasClass('liked')){
                        _that.likeAction('DecrementLikeCount');
                        _that.removeLike();
                    } else {
                        _that.likeAction('IncrementLikeCount');
                        _that.addLike();
                    }                
                } else if($(this).hasClass('email_icon')){
                    let emailSub = "Sharing this page";
                    let emailBody = "Hi there, I wanted to share it with you. Check it out";
                    emailBody += '%0D%0A%0D%0A'+func.curLoc().onlyUrl+'%0D%0A%0D%0A';
                    window.location.href = "mailto:?subject="+emailSub+"&body="+emailBody;
                }
            });
        }
    }
    updateView(){
        (this.pageLikes.includes(this.pageid)) ? $(".like_icon").addClass('liked') : $(".like_icon.liked").removeClass('liked');
    }
    addLike(){
        if(!this.pageLikes.includes(this.pageid))
        this.pageLikes.push(this.pageid);
        this.updateLikes();
    }
    removeLike(){
        this.pageLikes = this.pageLikes.filter(item => item !== this.pageid);
        this.updateLikes();
    }
    updateLikes(){
        let string = JSON.stringify(this.pageLikes);
        localStorage.setItem("myfavourites", string);
        this.updateView();
    }
    handleLikeShare(){       
        var scrollPos = $(window).scrollTop();
        var imgBanH = ($(".header.article-header").length > 0) ? 600 : 0;
        //var imgBanH = $(".image-banner").height();
        var bcH = $(".breadcrumb.navigation-title").outerHeight();
        var _top = parseInt(func.getBannersHeight('sticky')) + 20;
        if( func.deviceDimensions().winWidth > 991 && func.deviceDimensions().winWidth < 1200 && ($(".side-navigation.horizontal .links > div").length > 0) ){
            _top = _top + 30;
        }
        if(scrollPos > (parseInt(imgBanH) + parseInt(bcH) + 48)){
            $(".like-share").addClass('show').css('top', _top+'px');
            if( isElemInViewPort($("footer"), 5) ){
                $(".like-share").removeClass('show');
            }
        } else {
            $(".like-share").removeClass('show');
        }            
    }
    forDesktop(){
        return '<div class="component like-share"><div>' + this.likeIcon(48, '0 0 48 48') + this.emailIcon(48, '0 0 48 48') +
        '</div></div>';
    }
    forMobTab(){
        return `<div class="component like-share mobile"><div>${this.likeIcon(26, '13 14 22 22')}<p class="count">xxxx</p>${this.emailIcon(26, '13 14 22 22')}</div></div>`;
    }
    likeIcon(wh,vbox){
        return `<svg class="like_icon" xmlns="http://www.w3.org/2000/svg" width="${wh}" height="${wh}" viewBox="${vbox}" fill="none">
            <circle cx="24" cy="24" r="23.5" fill="white" stroke="black"/>
            <path class="default" fill-rule="evenodd" clip-rule="evenodd" d="M31.205 17.5989C32.1591 18.553 32.6195 19.8778 32.6241 21.2127C32.6241 24.2784 30.4213 26.9386 28.4874 28.7142C27.2718 29.8303 25.933 30.8535 24.5061 31.6871L24 32.001L23.499 31.6922C22.5245 31.1296 21.6112 30.4649 20.7335 29.7626C19.5942 28.8509 18.2743 27.6347 17.2318 26.2095C16.1943 24.7912 15.3724 23.0846 15.3721 21.2138C15.3696 19.9627 15.7657 18.6244 16.7911 17.5989C18.6887 15.7014 21.7652 15.7014 23.6627 17.5989L23.9974 17.9336L24.3334 17.5989C26.2062 15.7267 29.3323 15.7262 31.205 17.5989ZM23.9981 29.6506C24.6676 29.2206 25.9083 28.3671 27.1347 27.241C29.0133 25.5163 30.6231 23.3708 30.6241 21.2146C30.6204 20.3657 30.3597 19.582 29.7908 19.0131C28.6872 17.9096 26.8495 17.9132 25.7463 19.0145L23.9946 20.7593L22.2485 19.0131C21.132 17.8967 19.3218 17.8967 18.2054 19.0131C16.5217 20.6968 17.6741 23.4267 18.846 25.0287C19.7456 26.2585 20.9188 27.3494 21.9831 28.2009C22.7993 28.854 23.536 29.3538 23.9981 29.6506Z" fill="#111111"/>
            <path class="filled" fill-rule="evenodd" clip-rule="evenodd" d="M31.205 17.5989C32.1591 18.553 32.6195 19.8778 32.6241 21.2127C32.6241 24.2784 30.4213 26.9386 28.4874 28.7142C27.2718 29.8303 25.933 30.8535 24.5061 31.6871L24 32.001L23.499 31.6922C22.5245 31.1296 21.6112 30.4649 20.7335 29.7626C19.5942 28.8509 18.2743 27.6347 17.2318 26.2095C16.1943 24.7912 15.3724 23.0846 15.3721 21.2138C15.3696 19.9627 15.7657 18.6244 16.7911 17.5989C18.6887 15.7014 21.7652 15.7014 23.6627 17.5989L23.9974 17.9336L24.3334 17.5989C26.2062 15.7267 29.3323 15.7262 31.205 17.5989Z" fill="#111111"/>
            </svg>`;
    }
    emailIcon(wh,vbox){
        return `<svg class="email_icon" xmlns="http://www.w3.org/2000/svg" width="${wh}" height="${wh}" viewBox="${vbox}" fill="none">
            <circle cx="24" cy="24" r="23.5" fill="white" stroke="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M34 16H14V32H34V16ZM16 19.1206V30H32V19.1207L24 24.7207L16 19.1206ZM30.1134 18H17.8867L24 22.2794L30.1134 18Z" fill="#111111"/>
            </svg>`;
    }
    likeAction(action){
        func.callAjax(
            window.location.href,
            {scController: "ArticleDetails", scAction: action},
            'json',
            'POST',
            function(res,data){ 
                //console.log(res,data);
                if(res)$(".like-share.mobile p.count, .article-social-like p.count").text(data); else console.error(data);
                if(parseInt(data) > 0)$(".like-share.mobile p.count, .article-social-like p.count").addClass('show');
            }
        );
    }
}

export default LikeShare;