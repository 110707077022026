import $ from 'jquery';

const colorYellow = "#ffdb00";

$.fn.isInViewport = function(defArea) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop() - ($(window).height() / defArea);
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

export function isElemInViewPort(elem,defArea = 2){
    return $(elem).isInViewport(defArea);
}

function checkCircleInViewPort(){
    const factCircles = document.querySelectorAll(".fact-circle-border-animation .animate-circle");
    factCircles.forEach(factCircle => {
        if(isElemInViewPort(factCircle)){
            var range = $(factCircle).parent().siblings('.fact-circle__text').find("strong.field-value").html().replace(/%/g, "");
            startCircleAnimation(factCircle,range);
        }        
    });

    $(".fact-circle-number-animation").each(function(){
        var isAnimated = $(this).attr('isanimation');
        if( isElemInViewPort($(this)) && isAnimated == 'false' ){
            var elemID = $(this).find('strong').attr('id');
            var range = $(this).data("range");
            $(this).attr('isanimation',true);
            var odo = $('#'+elemID);
            odo.text(parseFloat(range));
        }
    });
}

export function startCircleAnimation(factCircle,percent, aniTime='0.8s') {
    var circleRadius = factCircle.r.baseVal.value;
    var circumference = circleRadius * 2 * Math.PI;
    factCircle.style.strokeDasharray = `${circumference} ${circumference}`;
    factCircle.style.strokeDashoffset = `${circumference}`; 
    const offset = circumference - percent / 100 * circumference;
    var bClr = '';
    if(aniTime=='0.8s'){
        bClr = factCircle.getAttribute("stroke");
    }
    factCircle.style.stroke = bClr;
    factCircle.style.strokeDashoffset = offset;
    factCircle.style.transition = aniTime+" stroke-dashoffset";
    //$(factCircle).closest(".fact-circle-border-animation").find(".fact-circle__text strong").text(percent+'%');
}

function applyDefaultStroke(){
    $(".fact-circle circle").each(function(){
        if( !$(this).hasClass("basic-circle") ){
            var isStroke = $(this).attr('stroke');
            if( typeof isStroke == 'undefined' || isStroke==""){
                $(this).attr("stroke",colorYellow);
            }
        }
    });
    $(".fact-circle-number-animation").each(function(){
        $(this).attr('isanimation',false);
    });
}

export function initThreeFactCircles(){
    $(document).ready(function() {
        applyDefaultStroke();
        checkCircleInViewPort();
    });
    $(window).on('resize scroll', function() {
        checkCircleInViewPort();
    });
}