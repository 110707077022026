import $ from 'jquery';
import * as func from './onlyExport';
import { isElemInViewPort } from './isInViewPort';

export function startCloudinary(){    
    if( $("img.cloudinary-img").length > 0) {        
        $("img.cloudinary-img").each(function(){
            var $img = $(this);
            var currentImgSrc = $img.attr('src');
            var finalImgSrc = $img.data(func.deviceMode());
            if($(this).closest(".slide-show-container").length>0){
                $img.attr('src', finalImgSrc.replace(/.jpg/g,'').replace(/.jpeg/g,''));
                setTimeout(function(){
                    $img.addClass('w_auto');
                },100);
            }
            var isViewport = isElemInViewPort($img,5); // 5 - 20% scroll area from bottom           
            if(isViewport && (currentImgSrc != finalImgSrc) ){
                var finalImgSrc2 = finalImgSrc.replace(/.jpg/g,'').replace(/.jpeg/g,'');
                //$img.removeAttr('style');
                $img.attr('src', finalImgSrc2);
                setTimeout(function(){
                    $img.addClass('w_auto');
                },1000);
                //$img.attr('src', finalImgSrc2).addClass('w_auto'); 
                //.addClass('cloudinaryBlurImage');           
            }            
        });        
    }
}

export function initCloudinary(){
    $(document).ready(function() {
        $("img.cloudinary-img").each(function(){
            var _src = $(this).attr('src');
            if( _src.length == 0){
                var _blur = $(this).data('lowsrc');
                var blurImg = _blur.replace(/.jpg/g,'').replace(/.jpeg/g,'').replace(/.png/g,'');
                $(this).attr('src', blurImg).attr('style','width:100%;');
            }
        });
        //setTimeout(startCloudinary,1000);
        startCloudinary();
    });
    $(window).on('resize scroll',startCloudinary);
}