import $ from 'jquery';
import * as func from './onlyExport';

function numberCircleAutofill(){
    let auto_inc = 1;
    $('.number-circle').each(function(){
        $(this).html(auto_inc);
        auto_inc++;
        var getStyle = $(this).parent().attr( "style" );
        if( typeof getStyle != 'undefined'){
            $(this).attr("style",getStyle);
        }  
    });
}

function stickyOyir(){
    let topPx = func.getBannersHeight('sticky') + 24;
    $(".column-container.oyir-sticky, .two-column-container.oyir-sticky").each(function(){
        var _fc = $(this).find('.two-columns > div:first-child, .column-placeholders > div:first-child');
        var _lc = $(this).find('.two-columns > div:last-child, .column-placeholders > div:last-child');
        var _fcH = 0;
        var _lcH = 0;
        _fc.find('.component-content').each(function(){
            _fcH += $(this).outerHeight();
        });
        _lc.find('.component-content').each(function(){
            _lcH += $(this).outerHeight();
        });
        ( _fcH > _lcH )
        ? _lc.addClass('col-sticky').css('top',topPx)
        : _fc.addClass('col-sticky').css('top',topPx);        
    });
}

function startOuryearInReview(){    
    $('.ouryear-in-review').each(function(){        
        if( $(this).find('.number-circle').length > 0 ){         
            if( $(this).find('.circle-large').length > 0 ){
                $(this).addClass('extra-margin');
            }
        }        
        var getBgc = $( this ).css( "background-color" );
        $(this).find('.content-list-bargraph .figures').css('background',getBgc);
        
        // figure image - center position
        if( $(this).find('.figure-image').length > 0 ){
            // $(this).find('.figure-image').closest('.column').addClass('auto-center');
            $(this).find('.figure-image .fixed-ratio').removeAttr("style");
        }
    });
    stickyOyir();
    resetBgImageOYIR();
    resetBarGraphOYIR();  
    ikeaCardIssue();
}

function ikeaCardIssue(){
    $('.ouryear-in-review').each(function(){
        if( $(this).find('.component.ikea-card-group').length > 0){
            let _ch = parseInt($(this).height()) - 80;
            let _ach = $(this).find('.component.ikea-card-group').height();
            if(_ach > _ch && func.deviceMode() != 'mobile'){
                $(this).css('margin-bottom', (_ach - _ch) + 'px');
            } else {
                $(this).css('margin-bottom', '0px');
            }
            //console.log(_ch,_ach, _ach - _ch);
        }
    });    
}

function resetBgImageOYIR(){
    // background image component
    $('.with-background-image').each(function(){
        var $elemOyir = $(this).find('.ouryear-in-review');
        var isSwappable = false;
        if( $(this).hasClass('swap-in-mobile') )isSwappable = true;
        var elemPos = 0, inc = 0;
        $(this).find('.ouryear-in-review .two-columns .column').each(function(){
            inc++;
            if($(this).children().length == 0){
                $(this).addClass('bg-image-mobile');
                elemPos = inc;
            }
        });
        if(func.deviceMode() == 'mobile'){
            $elemOyir.css("background-image", "url(" + $elemOyir.data('mobile') + ")");
            var bgPos = "left bottom";        
            if(elemPos == 1 && !isSwappable)bgPos = "left top";
            if(elemPos == 2 && isSwappable)bgPos = "left top";
            $elemOyir.css("background-position", bgPos);
        } else {
            $elemOyir.css("background-image", "url(" + $elemOyir.data('desktop') + ")");
        }

    });    
}

function resetBarGraphOYIR(){  
    $('.ouryear-in-review').each(function(){        
        if( $(this).find('.content-list-bargraph').length > 0 ){
            var getHeight = '180';
            if( $(this).find('.rich-text').length > 0 ){
                getHeight = ($(this).find('.rich-text h2').height()) + ($(this).find('.rich-text p').height()) + 16;
            }
            if(func.deviceMode() == 'mobile')getHeight = '180';
            $(this).find('.bargraph-list__list').css({'height':getHeight+'px'});
        } 
    });
}

// function applyBgClrBubbleOnImage(){
//     $('.bubbles-on-image').each(function(){
//         var isBubbleClr = $(this).data('bubbleclr');
//         if(typeof isBubbleClr == 'undefined'){
//             isBubbleClr = $(this).find('.component-content').data('bubbleclr');
//         }
//         $(this).find('.bubble').css('background',isBubbleClr);
//     });
// }

function autoVideoPreload(){
    setTimeout(
        function(){
           $('.sxa-video-wrapper .mejs-container').find("video").removeAttr("preload");
        }, 2000)
    $('.vertical-stack').find('.ikea-col-md-6').removeClass('ikea-col-md-6').addClass('ikea-col-md-12');
}


function circleOnTopExtBackground(){
    $('.component.background').each(function(){
        if($(this).find('.number-circle').length > 0){
            $(this).find('.number-circle').closest('.component.background').addClass('existing-number-circle');
        }
    })
}


export function initOurYearInReview(){
    $(document).ready(function() {
        numberCircleAutofill();
        //applyBgClrBubbleOnImage();
        circleOnTopExtBackground();
        var count = $('.ouryear-in-review').length;
        if(count > 0)startOuryearInReview();
        autoVideoPreload();
        if(!func.checkPreviewMode()){
            $(".nospace-near,.nospace-touching,.nospace-spacious").addClass('nospace-ExpEdi');
            $('.ouryear-in-review .content-list-bargraph p').css('margin-top','6rem');
        }
        stickyOyir();
    });
    $(window).on('resize scroll',resetBarGraphOYIR);
    $(window).on('resize',function(){
        resetBgImageOYIR();
        stickyOyir();
        ikeaCardIssue();
    });    
}

