import $ from 'jquery';
import * as func from '../components/onlyExport';

class HorizontalNav {
    constructor(){
        var _this = this;
        this.movePixels = 0; 
        this.linksPos = [];    
        this.scrollEnable = true; 
        if( $(".horizontal-navigation-subtitle").length > 0){
            this.start();
            $(window).scroll(func.debounce(function(){
                if(_this.scrollEnable)
                _this.syncWithHorizNav();
            },500));
            //Mouse wheel condition, sp-152 
            $(window).on('wheel', function(){
                _this.scrollEnable = true;
            });

            $(window).resize(func.debounce(function(){
                _this.setTopPosition();
            },200));
        }        
    }
    start(){
        var _html = "";
        var _that = this;
        $(".horizontal-navigation-subtitle").each(function(i){
            var subTitle = $.trim($(this).data('subtitle'));
            _html += '<div data-srno="'+i+'" class="'+((i == 0) ? 'active' : '')+'"><a><span>'+subTitle+'</span></a></div>';
        });
        $(".horizontal-navigation .links").html(_html).find('div').click(function(){
            _that.changeActive($(this).data('srno'));
            _that.scrollEnable= false;
            var _dest = $('.horizontal-navigation-subtitle[data-subtitle="'+$(this).text()+'"]').offset().top;
            $('html,body').animate({
                scrollTop: _dest - (func.getBannersHeight('sticky') + $(".horizontal-navigation .links").outerHeight())
            },'slow');            
        });
        $(".horizontal-navigation .links").scroll(this.handleIcons);
        this.setTopPosition();
        $(".horizontal-navigation .horizontal-nav-icon").click(function(e){
            e.preventDefault();
            if($(this).hasClass('left'))_that.movePixels -= 250; else _that.movePixels += 250;
            $(".horizontal-navigation .links").animate({
                scrollLeft: _that.movePixels
            }, 'slow');
        });
    }
    changeActive(idx){
        $(".horizontal-navigation .links div.active").removeClass('active');
        $(".horizontal-navigation .links div[data-srno="+idx+"]").addClass('active');
        this.scrollIntoView();
    }
    syncWithHorizNav(){
        var _calH = func.getBannersHeight('sticky') + $(".horizontal-navigation .links").outerHeight();
        this.linksPos = [];
        var _that = this;
        var _wst = $(window).scrollTop();
        $(".horizontal-navigation-subtitle").each(function(){
            _that.linksPos.push($(this).offset().top - _calH);
        }); 
        var _len = this.linksPos.length;
        if(_wst - this.linksPos[_len - 1] > -1){
            _that.changeActive(_len - 1);
            return true;
        }
        this.linksPos.every((e,i,arr) => {
            if(_wst - e > -1 && _wst < arr[i + 1]){
                _that.changeActive(i);
                return false;
            }
            return true;
        });
    }
    setTopPosition(){
        $(".horizontal-navigation").css('top',func.getBannersHeight('sticky'));
        this.handleIcons();
    }
    handleIcons(){
        var $elem = $(".horizontal-navigation .links");
        var $width = $elem.outerWidth();
        var $scrollWidth = $elem.get(0).scrollWidth; 
        var $scrollLeft = $elem.scrollLeft();
        $(".horizontal-nav-icon").removeClass('show')
        if ($scrollLeft + $width >= $scrollWidth -1){} else { 
            $(".horizontal-nav-icon.right").addClass('show');
        }
        if ($scrollLeft > 0){
            $(".horizontal-nav-icon.left").addClass('show');
        }
    }
    scrollIntoView(){
        var $elem = $('.horizontal-navigation .links');
        var $eactive = $('.horizontal-navigation .links div.active');
        var diva = $eactive.offset().left; 
        var divOw = $eactive.outerWidth(true)/2;
        var linksLeft = $elem.scrollLeft();
        var linksLefthalf = $elem.width()/2;
        var myScrollPos = diva + divOw + linksLeft - linksLefthalf;
        $('.horizontal-navigation .links').animate( { scrollLeft: myScrollPos }, 300);
        //.scrollLeft(myScrollPos);
    }
}

export default HorizontalNav;