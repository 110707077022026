import $ from 'jquery';

export default class SearchInput {
  constructor(options) {
    this.$root = $(options.rootClass);
    this.$inputField = this.$root.find(options.inputId);
    this.$clearBtn = this.$root.find(options.clearClass);
    this.$filledClass = options.filledClass;
    this.$placeholderText = this.$root.find(options.placeholderClass);
    this.$focusClass = options.focusClass;
  }

  clearFillClass(e) {
    var $inputField = this.$inputField;
    if (!$inputField.val().length) {
      this.$root.removeClass(this.$filledClass);
    } else {
      this.$root.addClass(this.$filledClass);
    }
  }

  checkFieldOnBlur(e) {
    var $inputField = this.$inputField;
    this.$root.removeClass(this.$focusClass);
    if (!$inputField.val().length) {
      this.$root.removeClass(this.$filledClass);
    }
  }

  start() {
    this.$inputField.focus(e => {
      this.$root.addClass(this.$focusClass);
    });

    this.$inputField.blur(e => this.checkFieldOnBlur(e));

    this.$inputField.keyup(e => this.clearFillClass(e));
  }
}
