import $, { type } from 'jquery';
import { isElemInViewPort } from './isInViewPort';
import * as func from './onlyExport';

function startBargarphs(){
    if( func.checkPreviewMode() ){
        $(".content-list-bargraph:not(\".bargraph-single-column\")").each(function(){
            var isAnimation = $(this).hasClass('animation-false');
            checkBarGraph(isAnimation, $(this));
        });
    }
}

function appendFigures(){
    if( func.checkPreviewMode() ){   
        $(".content-list-bargraph:not(\".bargraph-single-column\")").each(function(){            
            if( $(this).find('.figures-list').length > 0){
                $(this).find('.figures-list').remove();
            }
            var _html = '<ul class="figures-list">';
            $(this).find('.bar-caption').each(function(){
                _html += "<li><span>"+$(this).text()+"</span></li>";
                $(this).css('display','none');
            });
            _html += "</ul>";
            $(_html).insertAfter($(this).find('.bargraph-list__list'));
            alignNewlyaddedFigures($(this));
        });
    } else { // for sitecore editor mode
        $(".content-list-bargraph:not(\".bargraph-single-column\")").each(function(){
            $(this).find('.bar').each(function(){
                $(this).css('min-height','6rem');
            });
        });        
    }
}

$(document).ready(function() { 
    $(document).on('click','th.sort-table',function(e){
        var table = $(this).parents('table').eq(0);
        var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()));
        this.asc = !this.asc  
        table.find("th.sort-table").removeClass("asc desc");
        if (!this.asc){
            rows = rows.reverse()
            $(this).addClass('desc');
        } else {
            $(this).addClass('asc');
        }
        for (var i = 0; i < rows.length; i++){
            table.append(rows[i])
        }
    })
    function comparer(index) {
        return function(a, b) {
            var valA = getCellValue(a, index), valB = getCellValue(b, index)
            return getClonetable(valA,valB);

        }
    }
    function getCellValue(row, index){
        var _int = $(row).children('td').eq(index).text().replace(/([%$])+/g, '');
        return $.isNumeric(_int) ? parseFloat(_int) : _int;
    }
    function getClonetable(valA,valB){
        return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB);
    }

    compareColorOpacity();
});

function alignNewlyaddedFigures($elem){
    var $barElem = $elem.find(".bargraph-list__list > .bar-list");
    $elem.find('.figures-list li').each(function(){
        $(this).css('width',$barElem.width());
    });
}

// function convertToThousnads(){
//     $(".content-list-bargraph").each(function(){
//         $(this).find(".bar-list .bar .figures").each(function(){
//             var getStr = $(this).text().replace(/ /g, "");
//             var valueInK = Math.floor(parseInt(getStr) / 1000);
//             $(this).html(valueInK+'K');
//         });
//     });
// }

function addMinHeight(){
    if( ! func.checkPreviewMode() ){
        $(".content-list-bargraph").each(function(){
            $(this).find(".bar-list .bar .figures").each(function(){
                $(this).find('span:last-child').css('top','-40px');
            });
        });
    }
}

function checkBarGraph(animation, $elem){
    if(!animation){
        if( isElemInViewPort( $elem ) ){
            animateBarGraph($elem,800);
        }
     } else {
         animateBarGraph($elem,0);
     }
}

function animateBarGraph($elem, durTime){
    var bargraphHeight = ($elem.find('.bargraph-list__list').height()) - 32;
    $elem.find(".bar-list .bar").each( function( key, bar ) {
        if( $(this).attr("data-isloaded") != "true"){       
            var percentage = $(this).data('percentage');
            var dataHeight = $(this).find('.figures').height();
            var divLen = $(this).find('div').length;
            if(divLen == 2){
                var _bgClr = $(this).find('div:eq(0)').css('background-color');
                $(this).css('background-color',_bgClr);
            }
            var barHeight = (bargraphHeight/100)*percentage;            
            var reqHeight = barHeight + dataHeight + 8; // 8 is padding
            $(this).animate({
                'height' : reqHeight + 'px'
            }, {duration:durTime, easing: "linear"});
            $(this).attr("data-isloaded","true");
        }
    });
}

export function initBarGraphs(){
    $(document).ready(function() {
        startBargarphs();
        //addMinHeight(); // no action , so commented. - sp59 - bar issue
        appendFigures(); // bar figure overlap issue on each bar - sp59       
        
        if(func.checkPreviewMode()){
            $(".inline-side-panel").each(function(){
                if( typeof $(this).attr('href') != 'undefined'){
                    var _getPanelId = ($(this).attr('href')).replace(/#/g, "");
                    $(this).attr('data-panelid',_getPanelId);
                }
                //$(this).attr('href','javascript:void(0);');
            });
            const objPulseBubble = new PulseBubble();
            const objBarGraphSingleColumn = new BarGraphSingleColumn();
        } else {
            $(".inline-side-panel").each(function(){
                if( typeof $(this).attr('data-panelid') != 'undefined'){
                    var _getPanelId = $(this).attr('data-panelid');
                    $(this).attr('href',_getPanelId);
                }
            });
            $('.side-panel-content').css('display','block');
            $('.bubbles-on-image.pulsating-bubble .bubble, .bubbles-on-image.pulsating-bubble .bubble .info').css('display','inline-grid');
            if(func.curLoc().origin == 'https://tst.about.ikea.com'){            
                var fileref = document.createElement("link");
                fileref.rel = "stylesheet";
                fileref.type = "text/css";
                fileref.href = "https://tst.about.ikea.com/-/media/Themes/NL/Master%20site/Master/Experience%20Editor%20Styles/experience-aboutikea.css";
                document.getElementsByTagName("head")[0].appendChild(fileref);
            }
        }
    });
    $(window).on('resize scroll',startBargarphs);
    $(window).on('resize',appendFigures);
}

class BarGraphSingleColumn {
    constructor(){
        var _this = this;
        this.start = this.start.bind(this);
        this.startAnimation = this.startAnimation.bind(this);
        this.startChildAnimation = this.startChildAnimation.bind(this);
        this.appendUnit = this.appendUnit.bind(this);
        $(window).on('scroll',this.start); 
        $(window).resize(func.debounce(function(){
            $(".content-list-bargraph.bargraph-single-column").removeClass('viewed');
            $(".content-list-bargraph.bargraph-single-column.bargraph-comparison").removeClass('viewed');
            // $(".content-list-bargraph.bargraph-single-column").each(function(){
            //     $(this).find('.bar').css('border','1px solid');
            // });
            _this.start('resize');
        },200));
        this.start('ready');
    }
    start(type){
        var _that = this;
        $(".content-list-bargraph.bargraph-single-column:not('.viewed')").each(function(){
            var isNoAnimation = $(this).hasClass('animation-false');
            var _unitVal = _that.appendUnit($(this).data('unit'));
            if(type == 'ready'){
                $(this).find('.figures').each(function(){
                    $(this).html( $(this).text() + _unitVal);
                });
            }
            var t1 = $(this).hasClass('bargraph-comparison');
            if(!isNoAnimation && isElemInViewPort($(this))){
                if(t1 == false){
                    _that.startAnimation($(this));
                }else{
                    _that.startChildAnimation($(this));
                }
                
            } else if(isNoAnimation || type == 'resize'){
                if(t1 == false){
                    _that.startAnimation($(this));
                }else{
                    _that.startChildAnimation($(this));
                }
            }
        });
    }
    appendUnit($v){
        if($v != '' && $v != '%'){
            var lastChar = $v.charAt($v.length-1);
            var _newUnit = $v.substring(0, $v.length - 1);
            return _newUnit+'<span>'+lastChar+'</span>';
        } else {
            return $v;
        }        
    }
    startAnimation($elem){
        $elem.find('.bar-list').each(function(i){
            var _that = $(this),
            _ww = $(this).width(), 
            _sw = $(this).find('span').width(),
            _fw = $(this).find('.figures').width(),
            _per = $(this).find('.bar').data('percentage'), _as;
           
            //console.log(_ww, _sw, _fw, _per, _as);
            if(func.deviceMode() == 'mobile'){
                _as = _ww - 8 - _fw;
            } else {
                _as = _ww - 32 - _sw - _fw;
            }            
            var _reqW = (_as * _per) / 100;
            setTimeout(function(){
                _that.find('.bar').css('width',_reqW);
            },i * 200);            
        });
        $elem.addClass('viewed');
    }


    startChildAnimation($elem){
        $elem.find('.bar-child-list').each(function(index){
            var _that1 = $(this),
            _ww1 = $(this).parent().parent().width(), 
            _fw1 = $(this).find('.figures').width(),
            _sw1 = $(this).parent().siblings('span').width(),
            _per1 = $(this).find('.bar').data('percentage'), _as;
            if(_per1 > 100)_per1 = 100;
           //console.log(_ww1)
            if(func.deviceMode() == 'mobile'){
                _as = _ww1 - 8 - _fw1;
            } else {
                _as = _ww1 - 32 - _sw1 - _fw1;
            }            
            var _reqW = (_as * _per1) / 100;
            //console.log(_reqW);
            setTimeout(function(){
                _that1.find('.bar').css('width',_reqW);
            },index * 200);
        });
        $elem.addClass('viewed');
    }


}

class PulseBubble {
    constructor(){          
        this.showSidePanel = this.showSidePanel.bind(this); 
        this.iconHtml = this.iconHtml.bind(this);
        this.changeBubble = this.changeBubble.bind(this);
        this.start = this.start.bind(this);
        this.autoScroll = this.autoScroll.bind(this);
        $(window).on('resize scroll',this.start); 
        this.start('ready');    
    }

    iconHtml(){
        return '<svg class="svg-icon" viewBox="0 0 24 24"><path d="M19.2937 12.7074L20.0008 12.0003L19.2938 11.2932L12.0008 3.99927L10.5865 5.41339L16.1727 11.0003H4V13.0003H16.1723L10.5855 18.5868L11.9996 20.0011L19.2937 12.7074Z"></path></svg>';
    }

    changeBubble(type, $elem){
        var clr = $elem.css('background-color').slice(0, -1);
        var smCl = $elem.parent().parent().hasClass('ise-small-bubble-map');
        var smCl2 = $elem.hasClass('ise-sm-bl');
        var t = $elem.attr('data-top');
        var l = $elem.attr('data-left');
        var bg = $elem.attr('data-bg');
        var _tl = 'top: '+t+';left: '+l+';background-color:'+bg+';box-shadow: ';
        var _style = "";
        var _isActive = $elem.hasClass('active');
        if(type == 'default' && !_isActive){
            if(func.deviceMode() == 'desktop'){
                if(smCl == true)
                    _style = _tl+'0px 0px 0px 6px '+clr+', .3)';
                else
                    _style = _tl+'0px 0px 0px 12px '+clr+', .3)';

            } else if(func.deviceMode() == 'tablet'){
                if(smCl == true)
                    _style = _tl+'0px 0px 0px 5px '+clr+', .3)';
                else
                    _style = _tl+'0px 0px 0px 10px '+clr+', .3)';
            } else {
                _style = _tl+'0px 0px 0px 5px '+clr+', .3)';
            }
        } else {
            if(func.deviceMode() == 'desktop'){
                if(smCl == true)
                    _style = _tl+'0px 0px 0px 3px '+clr+', .6)';
                else
                    _style = _tl+'0px 0px 0px 6px '+clr+', .6)';
            } else if(func.deviceMode() == 'tablet'){
                if(smCl == true)
                    _style = _tl+'0px 0px 0px 2.5px '+clr+', .6)';
                else
                 _style = _tl+'0px 0px 0px 5px '+clr+', .6)';
            } else {
                _style = _tl+'0px 0px 0px 3px '+clr+', .6)';
            }
        } 
        $elem.attr('style',_style);
        var _bubbleBg ='background-color:'+bg+'; box-shadow: 0px 0px 0px 5px '+clr+', .3)'; 
        $elem.next('.ikea-modal-dialog.side-panel').find('.bubble-dot').attr('style',_bubbleBg);
        //return true;
    }

    start(isReady){
        var _that = this;
        if(isReady == 'ready'){
            $(".bubbles-on-image.pulsating-bubble").each(function(){
                //$(this).addClass('on-preview');
                var _thisEl = $(this);
                $(this).find('a.bubble').each(function(){
                    var _sty = $(this).attr('style');
                    $(this).find('.ise-small-bubble').parent().addClass("ise-sm-bl");
                    $(this).find('.b-text-lt').parent().addClass("b-text-lt");
                    $(this).find('.b-text-rt').parent().addClass("b-text-rt");
                    $(this).find('.b-text-bt').parent().addClass("b-text-bt");
                    var _fromClrPckr = false;
                    var getCss = _sty.replace(/top: /g, "").replace(/left: /g, "").replace(/background: /g, "");
                    var _getAttr = getCss.split(';');
                    if(_getAttr[2].length == 0){
                        _fromClrPckr = true;
                        _getAttr[2] = $(this).children().first().attr('style').replace(/background: /g, "");
                    }
                    $(this).attr('data-top',_getAttr[0]);
                    $(this).attr('data-left',_getAttr[1]);
                    $(this).attr('data-bg',_getAttr[2]);
                    if(_fromClrPckr){
                        $(this).attr('style','top: '+_getAttr[0]+';left: '+_getAttr[1]+';background: '+_getAttr[2]);
                    }
                    $(this).click(function(){
                        $(this).addClass('active');
                        _that.changeBubble('active',$(this));
                        _that.showSidePanel($(this));
                        mapOnViewport(_thisEl);
                    }); 
                });                               
            });
            $(".inline-side-panel, .link-side-panel a").each(function(){
                $(this).click(function(e){
                    e.preventDefault();
                    _that.showSidePanel($(this));
                });
            });
            $(".ikea-modal-dialog.side-panel").each(function(){
                var _next = $(this).next().clone();
                var getId = _next.find('.panelid span').text();
                $(this).attr('id','panel-'+getId);
                if(_next.length == 1){
                    $(this).find('.modal-body').html(_next);
                    $(this).next().remove();
                }                              
                $(this).find(".table-wrapper").each(function(){                     
                    $(this).find('tr').each(function(){
                        var _lastCell = $(this).find('td:last-child a');
                        if(_lastCell.length == 1){
                            $(this).attr('href',_lastCell.attr('href'));
                            // $(this).click(function(){
                            //     window.location.href = _lastCell.attr('href');
                            // });
                            _lastCell.html(_that.iconHtml());
                        }
                    });        
                });                         
                if( $("a[data-panelid='"+getId+"']").length > 0){
                    var _pHtml = $("#panel-"+getId).clone();                    
                    $("#panel-"+getId).parent().parent().addClass('no-gap');
                    $("#panel-"+getId).remove();
                    $(_pHtml).insertAfter( $("a[data-panelid='"+getId+"']") );
                } 
            });
            $(".table-wrapper").each(function(){
                var _firstRow = $(this).find('tr:first').clone();                 
                if($(this).find('thead').length < 1){
                    $(this).find('table').prepend('<thead></thead>');
                    $(_firstRow).appendTo($(this).find('thead'));
                    $(this).find('tbody tr:first').remove(); 
                } 
            });                   

            $(".nc-common-bg").each(function(el){
                let ncFullcolor = $(this).attr('data-bgcolor');
                if(ncFullcolor == undefined || ncFullcolor == " ") return  ncFullcolor = "#F5F5F5";
                if($(this).length > 0 && $(this).find('.nc-carousel-items').length > 0){    
                    $(this).find('.nc-carousel-items').css('background', ncFullcolor); 
                    $(this).find('.nc-carousel-items').css('background-clip', 'content-box');            
                }              
            });
            //scroll to map on viewport
            function mapOnViewport(Elthis){
                //var targetmapDiv = $('.ise-small-bubble-map');
                var scrollTopEl = Elthis.offset().top - 100;
                $('html, body').animate({
                    scrollTop:scrollTopEl
                },500);
                $('body').css('overflow','auto');
                $('body').css('overflow','hidden');
            }

            //small bubble map for only mobile and tablet sp-153
            function iseSmallBuubleMap(){
                $(document).ready(function(){
                var carouselHtml = `<div class="navigation-carousel">
                <div class="nc-carousel">
                        <a class="icons prev"> 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.40039 12.0001L14.1854 17.7858L15.5997 16.3717L11.2286 12L15.5997 7.62835L14.1854 6.21423L8.40039 12.0001Z"></path></svg>	
                        </a>
                        <div class="nc-carousel-items "></div>
                        <div class="nc-carousel-track"></div>
                        <a class="icons next"> 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5996 11.9999L9.81456 17.7857L8.40026 16.3716L12.7714 11.9999L8.40026 7.62823L9.81457 6.21411L15.5996 11.9999Z"></path></svg>
                        </a>
                    </div>
                </div>`;
                $('.ise-small-bubble-map').each(function(){
                    var $this = $(this);
                    var arrayObj = [];
                    var uniqueArry = arrayObj;
                    $(this).find('a.bubble + .ikea-modal-dialog.side-panel').find('.modal-header').prepend('<span class="bubble-dot"></span>');
                    $(this).find('a.bubble').parent().after('<div class="show-country-info">' + carouselHtml + '</div>');
                    $(this).find('a.bubble').parent().after('<div class="clrInfo"> <ul></ul></div>');     
                    $(this).find('a.bubble').each(function(i){
                            var getBgCl =  $(this).attr("data-bg");
                            var getInfo =  $(this).attr("data-info");
                            var getbubbleName =  $(this).attr("data-bubble-name");
                            var getPanelId = $(this).attr("data-panelid");
                            arrayObj.push({id: i+1, dot : getBgCl, info : getInfo, getName: getbubbleName, panelId: getPanelId});
                        });
                        for(var i=0; i < uniqueArry.length; i++){
                            if(uniqueArry[i].dot && uniqueArry[i].info){
                                var circleImg = "<span class='colorCircle' style= ' background-color:" + uniqueArry[i].dot + ";'></span>" ;
                                $(this).find(".clrInfo ul").append(`<li>${circleImg} <p> ${uniqueArry[i].info} </p> </li>`);
                            }
                        }
                        groupItemsBasedOnScreenSize(uniqueArry,$this);
                        correspondingLinkClick();
                    function correspondingLinkClick(){
                        $('.show-country-info .nc-carousel-items').find('div.list').click(function(){
                            var dataPanelId = $(this).attr('data-panelid');
                            var correspondingLink = $this.find('a.bubble[data-panelid='+dataPanelId+']').addClass('active');
                            _that.showSidePanel(correspondingLink);
                            mapOnViewport($this);
                        });
                    };
                    function groupItemsBasedOnScreenSize(uniqueArry,$this){
                        for(var i = 0;  i<uniqueArry.length; i++){
                            var $ul = '';
                            var circleImg = "<span class='colorCircle' style='background-color:" + uniqueArry[i].dot + ";'></span>" ;
                                $ul += (`<div class="list" data-panelid="${uniqueArry[i].panelId}"> ${circleImg} <span class="info">${uniqueArry[i].getName}</span> </div>`);
                                $this.find(".show-country-info").find('.nc-carousel-items').append($ul);
                        }
                    }

                //remove duplicate items
                    function removeDuplicates(Array){
                        var uniqueArry =[];
                        $.each(Array, function(index,item){
                            var found = false;
                            $.each(uniqueArry, function(uIndex, uItem){
                                if(item.dot === uItem.dot){
                                    found = true;
                                    return false;
                                }
                            });
                            if(!found){
                                uniqueArry.push(item)
                            }
                        });
                        return uniqueArry;
                    }
                });
                });
            };
            iseSmallBuubleMap();  
            if($(".sidepanel-ajax").length > 0){
                var urlPanelID = "";
                if( func.urlParam("panelID").has )
                    if(func.urlParam("panelID").get.length > 0 && $("#panel-"+func.urlParam("panelID").get).length == 0){
                        urlPanelID = func.urlParam("panelID").get;
                        var _sidePanelReady = '<div class="ikea-modal-dialog side-panel" id="panel-docready"><div>';
                        _sidePanelReady += '<div class="modal-header">';
                        _sidePanelReady += '<a class="close" title="Close"><div class="hover-effect"></div>';
                        _sidePanelReady += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">';
                        _sidePanelReady += '<path d="M8.43,16.35,12,12.71l3.68,3.61.71-.71L12.74,12l3.61-3.65-.7-.7L12,11.29,8.39,7.68l-.71.71L11.33,12,7.72,15.65Z">';
                        _sidePanelReady += '</path></svg></a></div>';
                        _sidePanelReady += '<div class="modal-body"><div class="ikea-loader column-content"><div class="wrap"><div class="ball"></div><div class="text">Loading your results</div></div></div></div>';
                        _sidePanelReady += '<div class="modal-footer"></div>';
                        _sidePanelReady += '</div></div>';
                        $("body").append(_sidePanelReady);
                        _that.autoScroll(urlPanelID);
                        $("#panel-docready").show().addClass('showSheet');
                        $("body").css({"overflow":"hidden","padding-right": func.getScrollWidth()+"px"});
                    }
                func.callAjax(
                    window.location.href,
                    {scController: "SidePanel", scAction: "SidePanelDetails"},
                    'html',
                    'POST',
                    function(res,data){
                        $(".sidepanel-ajax").html(data,function(){
                            $(".sidepanel-ajax").find('.table-wrapper').each(function(){
                                $(this).find('tr').each(function(){
                                    var _lastCell = $(this).find('td:last-child a');
                                    if(_lastCell.length == 1){
                                        $(this).attr('href',_lastCell.attr('href'));
                                        $(this).click(function(){
                                            window.location.href = _lastCell.attr('href');
                                        });
                                        _lastCell.html(_that.iconHtml());
                                    }
                                });
                            });
                            if(urlPanelID.length != 0){
                                $("#panel-docready .modal-body").html( $("#panel-"+urlPanelID+" .modal-body").html() );
                            }
                        });
                    }
                );
            }

            if( func.urlParam("panelID").has )
                if(func.urlParam("panelID").get.length > 0){
                    _that.showSidePanel($(this), func.urlParam("panelID").get);
                    //on page load bubble map set to viewport -sp156 
                    $(".bubbles-on-image.pulsating-bubble").each(function(){
                        var hasActiveBubble = $(this).find('a.bubble').hasClass('active');
                        var parentThis = $(this).find('a.bubble.active').parent().parent();
                        if(hasActiveBubble){
                            mapOnViewport(parentThis)
                        }
                    });
                }
                
        }       

        $(".bubbles-on-image.pulsating-bubble").each(function(){
            var noSequenceLoad = $(this).hasClass('no-sequence-load');
            if( isElemInViewPort($(this),3) ){
                $(this).addClass('viewed');
                setTimeout(() => {
                    $(this).find('a.bubble').each(function(i){
                        var $bubble = $(this);
                        setTimeout(function(){
                            $bubble.addClass('pulse');                   
                            setTimeout(() => {                            
                                _that.changeBubble('default',$bubble);
                                $bubble.mouseenter(function(){
                                    _that.changeBubble('hover',$bubble);
                                }).mouseleave(function(){
                                    _that.changeBubble('default',$bubble);
                                });
                                $bubble.focus(function(){
                                    _that.changeBubble('hover',$bubble);
                                });
                                $bubble.blur(function(){
                                    _that.changeBubble('default',$bubble);
                                });
                                $bubble.addClass('extra-pulse');
                            }, noSequenceLoad ? 0 : 400);
                        }, noSequenceLoad ? 0 : i * 300);
                    });
                }, noSequenceLoad ? 0 : 500);                
            }
        });
        $(document).on('click','.ikea-modal-dialog.side-panel .side-panel-content table tr[href]',function(e){
            window.location.href = $(this).attr('href');
        });
    }
    showSidePanel($elem, $pid = null){        
        var panelId= ($pid != null) ? $pid : $elem.data('panelid');
        if($("#panel-"+panelId).length == 0){
            console.error('No side panels found');
            $elem.removeClass('active');
            this.changeBubble('default', $elem);
            return;
        }        
        window.history.replaceState(null, null, "?panelID="+panelId);
        $("#panel-"+panelId).removeClass("hideSheet").show().addClass('showSheet');
        if($("#panel-"+panelId).hasClass('showSheet')){
            $(".ikea-modal-dialog.side-panel.showSheet").prev('a.bubble').addClass('active').siblings().addClass('inactive');
        }else{
            $(".ikea-modal-dialog.side-panel.hideSheet").prev('a.bubble').removeClass('active').siblings().removeClass('inactive');
        }
        $(".ikea-modal-dialog > div, .ikea-modal-dialog .modal-body").stop().animate({scrollTop: 0},500);
        $("body").css({"overflow":"hidden","padding-right": func.getScrollWidth()+"px"});
        $('.ikea-modal-dialog.side-panel.showSheet .modal-body').find('a').last().on('blur', function() {
            $('.ikea-modal-dialog.side-panel.showSheet a.close').focus();
        });
        if($pid != null){
            this.autoScroll($pid);
            func.afCloseSet(true);
        }
    }
    autoScroll(pid){
        const scrollLocation = $("a[data-panelid="+pid+"]:eq(0)").offset().top;
        $('html,body').stop().animate({scrollTop: scrollLocation - 100 - (func.getBannersHeight())}, 500);
    }
}

//bargraph-comparison script sprint-144
function compareColorOpacity(){
    $('.bargraph-single-column.bargraph-comparison').each(function(i){
        var _that = $(this);
        $(this).find('.bargraph-list__list li.bar-list').each(function(){
            var getBgStyle = $(this).find('.bar-color').css("background-color");
            var childListLength = $(this).find('li.bar-child-list').length;
            $(this).find('li.bar-child-list .bar').css({"background-color":getBgStyle});
            if(childListLength === 2){
                $(this).find('li.bar-child-list:eq(0) .bar').css({"opacity":1, "background-color":getBgStyle});
                $(this).find('li.bar-child-list:eq(1) .bar').css({"opacity":0.5, "background-color":getBgStyle});
            } else if(childListLength === 3){
                $(this).find('li.bar-child-list:eq(0) .bar').css({"opacity":1, "background-color":getBgStyle});
                $(this).find('li.bar-child-list:eq(1) .bar').css({"opacity":0.6, "background-color":getBgStyle});
                $(this).find('li.bar-child-list:eq(2) .bar').css({"opacity":0.3, "background-color":getBgStyle});
            } else if(childListLength === 4){
                $(this).find('li.bar-child-list:eq(0) .bar').css({"opacity":1, "background-color":getBgStyle});
                $(this).find('li.bar-child-list:eq(1) .bar').css({"opacity":0.65, "background-color":getBgStyle});
                $(this).find('li.bar-child-list:eq(2) .bar').css({"opacity":0.4, "background-color":getBgStyle});
                $(this).find('li.bar-child-list:eq(3) .bar').css({"opacity":0.2, "background-color":getBgStyle});
            }
        });
    //Append color code
    var dataColorArry = [];
    $(this).find('.bargraph-list__list').after('<div class="barColorInfo"><ul></ul></div>');
    setTimeout(() => {
            $(this).find('.bargraph-list__list li.bar-list').each(function(i){
            var dataInfo = [];
            $(this).find('.bar-child-list .bar').each(function(){
                var getBgCl =  $(this).css('background-color');
                var getOpacity = $(this).css('opacity');
                var colorInfo = $(this).data('info');
                dataInfo.push({bgcolor:getBgCl, opacity: getOpacity, clInfo:colorInfo });
            });
            dataColorArry.push(dataInfo);
        });
        dataColorArry.forEach(function(innerArray){
            var list = $('<li></li>');
            innerArray.forEach(function(item){
                var emptyData = $('<div class="clInfo"></div>');
                var divData = $('<div class="clInfo"> <div class="square"></div> <div>' + item.clInfo + '</div> </div>');
                divData.find('.square').css({'background-color' : item.bgcolor, 'opacity': item.opacity});
                if(item.clInfo != ''){
                    list.append(divData);
                }else{
                    list.append(emptyData);
                }
            });
            $('.barColorInfo ul').eq(i).append(list);
        });
       
        //bar-child-list hover effects
        $(this).find('.bar-list').each(function(){
            var indx1 = $(this).index();
            $(this).find('.bar-child-list').each(function(){
                    var indx2 = $(this).index();
                    $(this).mouseover(function(){
                        _that.find('.bar-child-list, .clInfo').css("opacity", "0.2");
                        $(this).css("opacity", "1");
                        _that.find('.barColorInfo li').eq(indx1).find('.clInfo').eq(indx2).css("opacity", "1");
                    }).mouseout(function(){
                        _that.find('.bar-child-list, .clInfo').css("opacity", "1");
                        _that.find('.barColorInfo li').eq(indx1).find('.clInfo').eq(indx2).css("opacity", "1")
                    })
            })
        })
 
    },500)
 
    });
};

//Small Bubble Map container
$(document).ready(function() {
   if(window.location.href.indexOf("sc_mode=edit") > -1) {
        showBubbleInEditor();
    };
});
//End Small Bubble Map container

//bubble map coordinates analysis - sp-152
function showBubbleInEditor(){
        $(".bubbles-on-image").each(function(){
            var $el = $(this);
            var $Elwidth = $(this).find('img').width();
            var $ElHeight = $(this).find('img').height();
            $(this).find('img').mousemove(function(event){
                var offset = $(this).offset();
                var x = event.pageX - offset.left;
                var y = event.pageY - offset.top;
                var xValue = pixelToPercentage(x, $Elwidth);
                var yValue = pixelToPercentage(y, $ElHeight);
                console.log(xValue,yValue);
                $el.find('.bubble-tooltip').text('X: ' + parseInt(xValue)+ ' ' + 'Y: ' + parseInt(yValue));
                $el.find('.bubble-tooltip').css({
                    left:x,
                    top: y,
                    display:'block',
                })
            });
            $(this).find('img').mouseleave(function(){
                $el.find('.bubble-tooltip').css('display', 'none');
            });
        });
};
function pixelToPercentage(pxValue,ElWidthHeight){
    if(ElWidthHeight <=0){
        return 0;
    }
   return (pxValue / ElWidthHeight) * 100;
}
 