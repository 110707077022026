import $, {trim } from 'jquery';
import * as func from './onlyExport';
import * as global from '../globalvalues';
import { setCtaIcon } from './featured-news';
import * as expFun from './exportFunctions';


//Multiple documents download 
$('.multiple-documents a.multi-links-path').each(function (i) {
$(this).on('click', function(e) {
    e.preventDefault();
    closeListpop(); 
    $('body').css('overflow', 'hidden'); 
    var $ParentItem =  $(this).parent().parent();
   var listcount = $ParentItem.children('list').length;
   var list = $ParentItem.children('list');
   var itemTitle = $ParentItem.find('.title').text();
   var link = $(this).attr('href');   
   $ParentItem.find(".ikea-modal-dialog").addClass("side-panel");
   $ParentItem.find(".ikea-modal-dialog").addClass("showSheet").removeClass("hideSheet").show();
   var heading = `<p class="popup-heading">Download options</p>`;
   $ParentItem.find(".ikea-modal-dialog .modal-header").append(heading);
    var oldurl= link;
    const url = new URL(oldurl);
    $(list).each(function() {
        var langValue = $(this).attr('value');
        var langName = $(this).text().split(' ')[0];
        var finalDocUrl = "https://" + window.location.host + "/" + langValue + url.pathname;  
       var checkboxInfo = `<div class="multi-checkbox">
                        <div class="checkbox">
                            <div class="checkContainer">
                                    <input type="checkbox" class="multilangue" id="`+ langValue +`"  value="`+ langValue +`" data-link="`+ link +`">
                                    <span class="checkbox-icon"></span>
                                    <span class="checkbox__label checkbox-label multi-check-icon">
                                        <label for="`+ langValue +`"  id="" class="h4 text-bold">`+ langName +`</label>
                                    </span>
                            </div>
                            <div class="viewContainer">
                                <a class="view-icon tooltip" href="`+ finalDocUrl +`" target="_blank" >
                                    <span class="tooltip-text">Preview</span>
                                    <i>
                                        <div class="hover-effect"></div>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9995 8C9.79035 8 7.99949 9.79086 7.99949 12C7.99949 14.2091 9.79035 16 11.9995 16C14.2086 16 15.9995 14.2091 15.9995 12C15.9995 9.79086 14.2086 8 11.9995 8ZM9.99949 12C9.99949 10.8954 10.8949 10 11.9995 10C13.1041 10 13.9995 10.8954 13.9995 12C13.9995 13.1046 13.1041 14 11.9995 14C10.8949 14 9.99949 13.1046 9.99949 12Z" fill="#111111"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2041 12C22.0131 12.2843 21.8262 12.5712 21.6241 12.8478C21.4956 13.0236 21.3087 13.2712 21.0694 13.5668C20.5921 14.1563 19.9 14.9444 19.0409 15.7356C17.3585 17.2851 14.87 19 11.9995 19C9.129 19 6.6405 17.2851 4.95809 15.7356C4.09902 14.9444 3.40687 14.1563 2.92957 13.5668C2.69029 13.2712 2.50342 13.0236 2.37495 12.8478C2.17288 12.5713 1.98582 12.2842 1.79492 12L2.16969 11.442C2.40632 11.0939 2.66487 10.7602 2.92957 10.4332C3.40687 9.84372 4.09902 9.05563 4.95809 8.26443C6.6405 6.71494 9.129 5 11.9995 5C14.87 5 17.3585 6.71494 19.0409 8.26443C19.9 9.05563 20.5921 9.84372 21.0694 10.4332C21.3087 10.7288 21.4956 10.9764 21.6241 11.1522C21.8261 11.4287 22.0132 11.7157 22.2041 12ZM4.23989 12C4.66123 11.4555 5.38347 10.5916 6.31299 9.73557C7.88794 8.28506 9.89944 7 11.9995 7C14.0996 7 16.1111 8.28506 17.686 9.73557C18.6155 10.5917 19.3378 11.4555 19.7591 12C19.3378 12.5445 18.6155 13.4083 17.686 14.2644C16.1111 15.7149 14.0996 17 11.9995 17C9.89944 17 7.88794 15.7149 6.31299 14.2644C5.38347 13.4083 4.66123 12.5445 4.23989 12Z" fill="#111111"/>
                                        </svg>
                                    </i>
                                </a>
                            </div>
                        </div>
                        
                    </div>`;

    $ParentItem.find(".ikea-modal-dialog .modal-body").append(checkboxInfo);

    });

    // var downloadBtn = `<div id="divBtns">
    //                     <button class="btn mptn" id="mult-btndownload" type="button" disabled><span role="button" name="btndownload"   class="btn btnBlack multilistdown">Download</span></button>
    //                 </div>`;
    var downloadBtn = `<div id="divBtns">
                        <button class="btn btn-primary btn-small mptn" id="mult-btndownload" type="button" disabled><span class="btn__copy btn-inner multilistdown"><span class="btn__label btn-text-label">Download</span></span></button>
                    </div>`;

        var i_title = `<p class="item-title">`+ itemTitle +`</p>`;
        $ParentItem.find(".ikea-modal-dialog .modal-body").prepend(i_title);
        $ParentItem.find(".ikea-modal-dialog .modal-footer").append(downloadBtn); 
        
        //Sidepanel change checkbox and view icon
        $ParentItem.find(".multilangue").change(function(){
            const parentCheck = $(this).closest(".multi-checkbox");
            const viewIcon = parentCheck.find(".viewContainer");
            if($(this).is(":checked")){
                viewIcon.css("display","block");
            }else{
                viewIcon.css("display","none");
            }
        })
    });
});
$(document).ready(function() { 
    $(document).on('click','.ikea-modal-dialog .multilistdown',function(e){
        var lannglist = document.querySelectorAll('.multilangue:checked');
        if(lannglist.length > 0){
            var arrayListLinks = []
            $(lannglist).each(function() {
                var lisvalue = $(this).attr('value').toLowerCase();
                var oldurl= $(this).attr('data-link');
                const url = new URL(oldurl);
                var finalurl = "https://" + window.location.host + "/" + lisvalue + url.pathname;      
                arrayListLinks.push(finalurl);                
            });
            arrayListLinks.forEach(function(value, index) {
                setTimeout(function(){
                    var anchor=document.createElement('a');
                    anchor.href=value;
                    anchor.download =value.split("/").pop();
                    document.body.appendChild(anchor);
                    anchor.click();
                    //if(index + 1 == arrayListLinks.length){
                        showToast('Downloading...');
                    //}
                    setTimeout(function(){
                        document.body.removeChild(anchor);
                    },1000);                    
                }, index * 2000);
                
            });
            closeModalDialog();
            closeListpop(); 
        }

    });
});

//End Multiple documents download 

export function showToast(message){
    var toastMsg = $('<div class="toast"> <span> ' + message + ' </span> <span class="close-toast"><div class="hover-effect"></div><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.00057 6.4148L7.82887 9.24311L9.24308 7.8289L6.41478 5.00058L9.24308 2.17227L7.82886 0.758057L5.00057 3.58636L2.17203 0.757812L0.75782 2.17203L3.58636 5.00058L0.757812 7.82914L2.17202 9.24336L5.00057 6.4148Z" fill="white"/></svg> </span> </div>');
    $('body').append(toastMsg);
    toastMsg.fadeIn();
    toastMsg.find('.close-toast').on('click', function(){
        toastMsg.fadeOut(function(){
            $(this).remove();
        });
    });
    setTimeout(function(){
        toastMsg.fadeOut(function(){
            $(this).remove();
        });
    }, 2000)
}
 
$(document).ready(function() {
    $(".img-carousel-container").each(function(e){
        let currentIndex = 0;
        let autoScrollInterval;
        let imglastcount = 1;
        const carouselImages = $(this).find('.carousel .ca-slide');
        const dotsContainer = $(this).find('.dots');
        const that = this;
    
        function startAutoScroll() {
        if ($('.on-page-editor').length == 0) {
            autoScrollInterval = setInterval(next, 5000);
        }
        }
    
        function next() {
        const carouselDivs = $(that).find('.carousel .ca-slide');
        carouselDivs.eq(currentIndex).css('display', 'none');
        $(that).find(".next-button").addClass('active');
        currentIndex = (currentIndex + 1) % carouselDivs.length;
        carouselDivs.eq(currentIndex).css('display', 'inline-block');
        imgcount(currentIndex);
        $(that).find(".next-button.active").removeClass('active');
        const dots = $(that).find('.dot');
        dots.removeClass('active');
        dots.eq(currentIndex).addClass('active');
        }
    
        $(that).find('.next-button').click(function() {
        clearInterval(autoScrollInterval);
        next();
        startAutoScroll();
        });
    
        $(that).find('.prev-button').click(function() {
        clearInterval(autoScrollInterval);
        //setTimeout(startAutoScroll, 1000);
        const carouselDivs = $(that).find('.carousel .ca-slide');
        carouselDivs.eq(currentIndex).css('display', 'none');        
        $(that).find(".next-button").addClass('active');
        currentIndex = (currentIndex - 1 + carouselDivs.length) % carouselDivs.length;
        carouselDivs.eq(currentIndex).css('display', 'inline-block');     
        imgcount(currentIndex);
        $(that).find(".next-button.active").removeClass('active');
    
        const dots = $(that).find('.dot');
        dots.removeClass('active');
        dots.eq(currentIndex).addClass('active');
        startAutoScroll();
        });
    
        carouselImages.each(function(i) {
        const dot = $('<span>').addClass('dot');
        dotsContainer.append(dot);
        dot.click(function() {
            changeImage(i);
        });
    
        function changeImage(index) {
            clearInterval(autoScrollInterval);
            startAutoScroll();
            currentIndex = index;
            carouselImages.each(function() {
            const carouselDivs = $(that).find('.carousel .ca-slide');
            carouselDivs.css('display', 'none');
            });
            carouselImages.eq(currentIndex).css('display', 'block');
    
            const dots = $(that).find('.dot');
            dots.removeClass('active');
            dots.eq(currentIndex).addClass('active');
        }
        });

        $(that).find('.dots').children().first().addClass('active');
        function dotswidthcal(){
                const carousel = $(that).find('.carousel');
                const carouselWidth = carousel.outerWidth() - 150;
                const dotWidth = carouselWidth / carouselImages.length;
                const dots = $(that).find('.dot');
                dots.each(function() {
                $(this).css('width', dotWidth + 'px');
                });
            }
        dotswidthcal();
    
        
        function imgcount(currentIndex) {
        const imageCount = $(that).find('#imageCount');
        const carouselDivs = $(that).find('.carousel .ca-slide');
    
        if (currentIndex < carouselDivs.length - 1) {
            imglastcount = currentIndex + 2;
        } else {
            imglastcount = 1;
        }
        imageCount.html("<b> " + (currentIndex + 1) + " </b> " + (imglastcount));
        }
        startAutoScroll();
        imgcount(currentIndex);             
        //$(that).find(".next-button").addClass('active');

        $(window).resize(function() {
            dotswidthcal();   
        });
        
    });
   
});
   
$('.multi-list_links a.download').each(function (i) {
    $(this).on('click', function(e){
       // e.preventDefault();
        showToast('Downloading...');
    })
});

$('.rt-cta-button').each(function() {
    var alllink = $(this).find('a');
    var link = $(this).find('a').first();
    
    if (alllink.length == 1){
        $(this).addClass("rthover");
        // $(this).find('a').first().css('display','none');
    }
   
});

$(document).on('click','.rthover',function(e){
    $(this).off('click', e);
    //var link = $(this).closest('a').first();
    var link = $(this).find('a:first');
    if(link.attr('href').length != undefined ){
    window.open(link.attr('href'),"_self");
    }
});


// $('.page-column-container').each(function() {
//     var $this = $(this);
//     var divs = $(this).find('div[data-width]');
//     var combinedWidths = '';
//     function convertToFr(percentage) {
//         return parseFloat(percentage) / 100;
//        }       
       
//     divs.each(function(index, element) {
//         var dataAttribute = $(element).attr('data-width');  
//         let widthAsFr = convertToFr(dataAttribute);    
//         combinedWidths += widthAsFr + 'fr' + ' ';
//     });
//     combinedWidths = combinedWidths.trim();
//     $this.find('.component-content').css('grid-template-columns', combinedWidths);

// });

$(document).on('click','input:checkbox',function(e){
    if ($(this).is(':checked')) {
        $('#mult-btndownload').prop("disabled", false);
    } else {
        if ($('.multilangue').filter(':checked').length < 1){
        $('#mult-btndownload').attr('disabled',true);}
    }
});

$(document).on('click','.multiple-documents .ikea-modal-dialog #btnCancel',function(e){
     closeListpop();
    closeModalDialog();
});



function startNewsroom(){    
    $(".media-asset-library, .asset-popup-view, .download-press").each(function(){
        var _this = $(this);
        _this.find("a.download").each(function(){
            var getHref = $(this).attr('href');
            var index = getHref.indexOf("/-/media/");    
            if(index !== -1){
                var imageNameWithQuery = getHref.substring(getHref.lastIndexOf('/') + 1);
                var imageName = imageNameWithQuery.split('?')[0];
                var imgFormates = /\.(jpg|jpeg|png|gif|svg)(\?.*)?$/i;
                var newImageName;
                if(imgFormates.test(imageName)){
                    newImageName = imageName.replace(/-\w+\./, '.');
                }else{
                    newImageName = imageName;
                }
               // _this.attr('href', getHref.replace(imageNameWithQuery, newImageName));
                //$(this).attr("download", newImageName);

                fetchDownloadBlob(getHref, newImageName);

            } else{
                console.error("error: you cannot download this file.");
            }            
        });
    });
}

function openAssetDetails(itemid,isPageLoad=false){
    var url = window.location.href;
    //url ="http://localhost:3000/aboutikea/sample-pages/asset-detailed-view-popup.html";
    $(".ikea-modal-dialog").show();
    $(".ikea-modal-dialog .modal-body").html(global._loaderHtml);
    $("body").css('overflow','hidden');
    if(!isPageLoad){
        var itemid2 = itemid.replace(/{/g, '').replace(/}/g, '');
        window.history.replaceState(null, null, "?assetID="+itemid2);
    } else {
        itemid = "{"+itemid+"}";
    }
    // GET for dev, post for prod
    func.callAjax(
        url,
        {scController: "galleryasset", scAction: "getassetdetails", itemId: itemid},
        'html',
        'POST',
        function(res,data){
            $(".ikea-modal-dialog .modal-body").html(data,function(){
                if(res){  
                    var setCta = new setCtaIcon();
                    var isVideo = $(".ikea-modal-dialog .asset-info video").length;
                    if(isVideo > 0){
                        //setVideoHeight();
                        func.getVideoDuration("asset-video-player",$(".ikea-modal-dialog section.length > p:last-child"));                    
                    } else {
                        imageDimension();
                    }
                    valueConvertMB();
                    setTimeout(startNewsroom,500);
                }
            });
        }
    );
}

function openProfileCardDetails(itemid,isPageLoad=false){
    var url = window.location.href;
    //url ="http://localhost:3000/aboutikea/sample-pages/profile-card-detailed-view-popup.html";
    $(".ikea-modal-dialog.profile-card-modal").show().css("display","flex");
    $(".ikea-modal-dialog.profile-card-modal .modal-body").html(global._loaderHtml);
    $("body").css('overflow','hidden');
    if(!isPageLoad){
        var itemid2 = itemid.replace(/{/g, '').replace(/}/g, '');
        window.history.replaceState(null, null, "?memberID="+itemid2);
    } else {
        itemid = "{"+itemid+"}";
    }
    // GET for dev, post for prod
    func.callAjax(
        url,
        {scController: "memberprofile", scAction: "membercarddetails", itemId: itemid},
        'html',
        'POST',
        function(res,data){
            $(".ikea-modal-dialog.profile-card-modal .modal-body").html(data,function(){
                var _str500 = $('.profile-card-modal .asset-popup-view .detailed-info p').text().substring(0,500);
                $('.profile-card-modal .asset-popup-view .detailed-info p').text(_str500);
            });
        }
    );
}

function applyCloudinary(){
    var $img = $(".ikea-modal-dialog .asset-info img");
    //var currentImgSrc = $img.attr('src');
    var finalImgSrc = $img.data(func.deviceMode());  
    //if( currentImgSrc != finalImgSrc ){
    $img.attr('src', finalImgSrc);//.addClass('w_auto');           
    //} 
}

function setVideoHeight(){    
    var newHeight = ($(".ikea-modal-dialog .asset-info").width()) * (9/16);
    $(".ikea-modal-dialog .asset-info video").css('height',newHeight+'px');    
}

function imageDimension(){
    var getDimenstion = $(".ikea-modal-dialog .modal-body .dimensions p:nth-child(2)").text().trim().slice(0, -2).split('x');
    var widthDim = parseInt(getDimenstion[0]);
    var heightDim = parseInt(getDimenstion[1]);
    //if(heightDim > widthDim)
       // $('.asset-popup-view .asset-info').addClass('asset-two-column');    
    setTimeout(applyCloudinary,500);
}

function showHideassets(){
    $(".show-hide-assets").click(function(){
        var action = $(this).data('display');
        $(".show-hide-assets").css('display','block');
        $(".show-hide-assets[data-display="+action+"]").css('display','none');
        if(action=='block')action='flex';
        $(".media-asset-library.extends ul").css('display',action);
    });
}
function doActiveCategories(){
    var searchParams = window.location.search.substr(1).split('=');
    var getName = searchParams[0] ? searchParams[0] : searchParams[0].toLowerCase();
    var _category = func.urlParam(getName);
    if(_category.get !== null){
        var _categorys = _category.get.split(',');
        _categorys.forEach(function(val){
            $(".news-tag-list ul a").each(function(){
                if( $(this).next().find('span').text().toLowerCase() === val.toLowerCase()){
                    $(this).addClass('active');
                }
            });
        });        
    }
    doActiveTabs();
}




function doActiveTabs(){
    var _tabs = func.urlParam('tabs');    
    if(_tabs.get !== null && _tabs.get != ''){
        //$(".tabs ul li").removeClass('active');
        $(".tabs ul li").each(function(){
            if( $(this).find('.field-heading').text() == _tabs.get){
                $(this).click(); //addClass('active');
            }
        }); 
    }
}


function manageCategoryTabs(){    
    doActiveCategories();
    $(".news-tag-list ul a").click(function(e){        
        e.preventDefault();
        $(this).toggleClass('active');        
        var _id = "";
        if( $(this).hasClass('active') ){
            _id = $(this).next().find('span').text();
        }        
        var _isMulti = $(this).closest('.multiselection').length;
        if( _isMulti > 0){
            var _ids = [];
            $(".news-tag-list ul a.active").each(function(){
                _ids.push($(this).next().find('span').text());
            });
            _id  = _ids.join(',');
        }        
        var _tabs = $(".tabs ul li.active .field-heading").text();
        var _url = func.curLoc().onlyUrl + "?Category=" + _id;
        if(_tabs.length > 0)_url += "&tabs="+_tabs;
        var _isTopics = $(this).closest('.topics-redirection').length;
        if(_isTopics > 0){
            _url = $(this).attr('href') + "?Category=" + _id;
            /* commented as its not moving to Prod *** */ 
            //let topicTitle = $(this).attr('title');
            //_url = $(this).attr('href') + "#all_topics=" +topicTitle+ "&stories_topics=" +topicTitle+ "&news_topics=" + topicTitle;
        }
        window.open(_url,"_self");
    });
}

function autoCenterActivePill(){
	if( $('.news-tag-list__lists').length > 0 && func.deviceMode() == "mobile"){
		var lengthM = $('.news-tag-list__lists a.active').length;
		if(lengthM == 0)return false;		
		var diva = $('.news-tag-list__lists a.active').eq(lengthM - 1).offset().left; 
		var divOw = $('.news-tag-list__lists a.active').eq(lengthM - 1).outerWidth(true)/2;
		var linksLeft = $('.news-tag-list__lists').scrollLeft();
		var linksLefthalf = $('.news-tag-list__lists').width()/2;
		var myScrollPos = diva + divOw + linksLeft - linksLefthalf;
		$('.news-tag-list__lists').scrollLeft(myScrollPos);
	}
}

function setVideoPoster(){
    $("video").each(function(){
        var _poster = $(this).data('poster');
        if(_poster){
            var spRev = $(this).data('poster').trim().split("?rev");
            var fn = spRev[0].split("/");
            var actUrl = spRev[0].replace(fn[ fn.length - 1 ], '');
            var fn2 = fn[ fn.length - 1 ].split(".");
            var srcUrl = actUrl+fn2[0]+"/"+fn2[0];
            if(func.deviceMode() == "mobile"){
                srcUrl = srcUrl+"-gallery-img-des";
            } else {
                srcUrl = srcUrl+"-card-variation-img";
            }
            $(this).attr('poster',srcUrl);      
        }        
    });
}

function topicsLoadmore(){
   if($(".topics-loadmore").length > 0){           
       var isLiTotalLength = $(".topics-loadmore ul > li").length;
       if(isLiTotalLength > 5){
           var remainAmt = isLiTotalLength - 5;
           var _htmlLoadmore = '<li class="tag-list__title news-topics plus-x-more">'
               + '<a title="loadmore" href="javascript:void(0)">+ ' +remainAmt+ ' more</a></li>';
           $(".topics-loadmore ul").append(_htmlLoadmore);
       }
       $(".topics-loadmore ul li.plus-x-more").click(function(){
           $(this).remove();
           $(".topics-loadmore ul > li").css('display','inline-block');            
       });
   }     
}

export function initNewsroom(){
    $(document).ready(function() {
        if( !func.checkPreviewMode() ){
            $(".page-entrance.read-only a").each(function(){
                $(this).css({"cursor":"pointer","pointer-events":"auto"});
            });
        }
        const assetsDocs = new AssetsDocuments();
        const tabsDesignForDesktop = new TabsDesignForDesktop();
        topicsLoadmore();
        setVideoPoster();
        setTimeout(startNewsroom,2000);
        if( $('.media-asset-library').length > 0){
            if( func.urlParam("assetID").has )
                if(func.urlParam("assetID").get.length > 0)
                    openAssetDetails(func.urlParam("assetID").get,true);
        }
        if( $('.extends-profile-card').length > 0){
            if( func.urlParam("memberID").has )
                if(func.urlParam("memberID").get.length > 0)
                openProfileCardDetails(func.urlParam("memberID").get,true);
        }
        $(".ikea-modal-dialog .close").click(function(e){
            e.preventDefault();            
            closeModalDialog();
        });
        showHideassets();
        if(!func.checkPreviewMode()){            
            $(".load-more").addClass('blockImp');
        }
        manageCategoryTabs();
        addClassTabActiveMobile();
        fieldEmptyText();
        initValueConvertKB();
		autoCenterActivePill();

        $(".page-entrance.ext-link a").each(function(){
            $(this).attr('target','_blank');
        });
    });

    $(document).on('keyup', function(event) {
        if (event.key == "Escape") {
            closeModalDialog();
            closeImgPopup();
        }
    });

    $(document).on('click','.media-asset-library ul li .img, .media-asset-library ul li .title', function(e) {
        var itemid = $(this).closest('li').find('.assetid > span').text();
        openAssetDetails(itemid);
    });
    $(document).on('click','.serp-page .ikea-card.images, .serp-page .ikea-card.videos', function(e) {
        let _isDwnld = ($(e.target).hasClass('asset-download') ) ? true : false;
        //console.log('TAG:',e.target.tagName, _isDwnld);
        if(!_isDwnld) {
            e.preventDefault();
            let _obj = {
                url: '',
                title: $(this).find('h3').text(),
                fs: $(this).find('.filesize').text(),
                ft: $(this).find('.filetype').text(),
                linkUrl: $(this).find('a.asset-download').attr('href')
            };
            let _html = '';
            if($(this).hasClass('images')){
                _obj.url = $(this).find('img').attr('src');
                _html = '<div class="image-info"><img src="'+_obj.url+'" ></div>';
            } else {
                _obj.url = $(this).find('video source').attr('src');
                _html = '<div class="video-info"><video controls id="asset-video-player"><source src="'+_obj.url+'"></video></div>';
            }
            // if(linkUrl != null || linkUrl !=''){
            //     _obj.url = linkUrl;
            // }
            $(".ikea-modal-dialog .modal-body").html(serpPageAsset(_obj, _html)).parent().parent().css('display','block');
           // var _showBtnCl = $(this).closest('.serp-page').hasClass('show-download-btn');
            $('.asset-popup-view.extends-serp .asset-info').each(function(){
                var $parentThis = $(this).find('section.button');
                    // if(_showBtnCl){
                    //     $parentThis.find('a.download').css("display", "inline-block")
                    // }
                    $parentThis.find('a.download').click(function(e){
                    expFun.downloadAssert($(this),$parentThis,e);
                });
            });
        } 
        
    });
    $(document).on('click','.serp-page.show-download-btn li .ikea-card object.cta a.download ', function(e) {
        var $parentThis = $(this).closest('.ikea-card').find('.component-content');
        expFun.downloadAssert($(this),$parentThis,e);
    });
    $(document).on('click','.extends-profile-card ul li', function(e) {
        var itemid = $(this).closest('li').find('.assetid > span').text();
        openProfileCardDetails(itemid);
    });
    $(document).on('click','.ikea-modal-dialog',function(e){
        closeModalDialog();
    });
    $(document).on('click','.ikea-modal-dialog > div > *',function(e){
        e.stopPropagation();
    });
    $(document).on('click','.ikea-modal-dialog > .close',function(e){
        closeModalDialog();
    });
    $(document).on('click','.ikea-img-download-popup .close',function(e){
        e.preventDefault();
        closeImgPopup()
       
    });

    $(window).on('resize', setVideoHeight,addClassTabActiveMobile,setVideoPoster);
}

function serpPageAsset(d,htm){
    let _split = d.url.split('/');
    let _fname = _split[_split.length - 1];
    return '<div class="component asset-popup-view extends-serp column-wide">'+
        '<div class="component-content">'+
            '<div class="asset-info asset-two-column">'+
                htm +
                '<div class="detailed-info">'+
                    '<section class="heading">'+
                        '<h2>'+d.title+'</h2>'+
                    '</section>'+
                    // '<section class="dimensions">'+
                    //     '<p>Dimensions</p>'+
                    //     '<p>1575 x 2099 px</p>'+
                    // '</section>'+
                    '<section class="format">'+
                        '<p>Format</p>'+
                        '<p>'+d.ft+'</p>'+
                    '</section>'+
                    '<section class="size">'+
                        '<p>File size</p>'+
                        '<p>'+d.fs+'</p>'+
                    '</section>'+
                    '<section class="button">'+
                        '<a href="'+d.linkUrl+'" class="btn btn-primary btn-leading-icon download">'+
                            '<span class="btn-copy btn-inner">'+
                                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="svg-icon">'+
                                    '<path d="M21,10V21H3V10H5v9H19V10Zm-5.29,1.29L14.29,9.88,13,11.17V3.88H11v7.29L9.71,9.88,8.29,11.29,12,15Z"></path>'+
                                '</svg>'+
                                '<span class="btn-label">Download</span>'+
                            '</span>'+
                        '</a>'+
                        expFun.downloadTxtCode(d.linkUrl) + 
                    '</section>'+
                '</div>'+
            '</div>'+
        '</div>'+
    '</div>';
}

 function closeListpop(){
     $(".multiple-documents .ikea-modal-dialog .modal-body").html('');  
     $(".multiple-documents .ikea-modal-dialog .modal-header p").remove();   
     $(".multiple-documents .ikea-modal-dialog .modal-footer").html(''); 
 }

function closeModalDialog(){    
    $(".ikea-modal-dialog:not(\".side-panel\") .modal-body").html('');
    $(".ikea-modal-dialog.side-panel").removeClass("showSheet").addClass("hideSheet"); 
    setTimeout(function(){
        $(".ikea-modal-dialog.side-panel").hide();
        closeBtn();
    },300);
    $(".ikea-modal-dialog:not('.side-panel')").hide();
    $("body").css({"overflow":"auto","padding-right":"0px"});
    window.history.replaceState(null, null, func.curLoc().onlyUrl); 
    $("a.bubble.active").each(function(){
        $(this).removeClass('active');
        $(this).siblings().removeClass('inactive');
        var clr = $(this).css('background-color').slice(0, -1);
        var hasCl = $(this).parent().parent().hasClass('ise-small-bubble-map');
        var _tl = 'top: '+$(this).attr('data-top')+';left: '+$(this).attr('data-left')+';background-color:'+$(this).attr('data-bg')+';box-shadow: ';
        var _aniWidth = "5px";
        if(func.deviceMode() == 'desktop'){
            if(hasCl)
             _aniWidth = '6px';
            else
             _aniWidth = '12px';
        } else if(func.deviceMode() == 'tablet'){
            if(hasCl)
                _aniWidth = '5px';
               else
                _aniWidth = '10px';
        }
        var _style = _tl+'0px 0px 0px '+_aniWidth+' '+clr+', .3)';
        $(this).attr('style',_style);
    });
   
}

 export function initValueConvertKB(){
     $('.doc-size').each(function(){
        var getValue= $.trim($(this).text());
        var setValue = func.bytesConvertion(parseInt(getValue));
        $(this).text(setValue);
    });
 }

function addClassTabActiveMobile(){
    if(func.deviceDimensions().winWidth <= 991) 
        $('.tabs .tabs-heading').addClass('mobileHorizontalTab');
    else 
        $('.tabs .tabs-heading').removeClass('mobileHorizontalTab');
}



 
//Auto center Tab pill in Mobile and Tablet mode
export function tabAutoCenterPill(){
    $('.tabs .tabs-heading li').each(function(){
        $(this).click(function(){
            setTimeout(function(){
                if(func.deviceDimensions().winWidth <= 991){
                    var diva = $('.tabs .tabs-heading.mobileHorizontalTab').find('.active').offset().left; 
                    var divOw = $('.tabs .tabs-heading.mobileHorizontalTab .active').outerWidth(true)/2;
                    var linksLeft = $('.tabs .tabs-heading.mobileHorizontalTab').scrollLeft();
                    var linksLefthalf = $('.tabs .tabs-heading.mobileHorizontalTab').width()/2;
                    var myScrollPos = diva + divOw + linksLeft - linksLefthalf;
                    $('.tabs .tabs-heading.mobileHorizontalTab').scrollLeft(myScrollPos);
                }                
            },500);                      
        })       
    })
}


function valueConvertMB(){
    var getConvertedValue = $('.asset-popup-view .detailed-info .size p:nth-child(2)').text();
    var setValue = func.bytesConvertion(parseInt(getConvertedValue));
    $('.asset-popup-view .detailed-info .size p:nth-child(2)').text(setValue);
 }


 function fieldEmptyText(){
    $(".container-gray-background").each(function() {
        var getContext = $(this).find('.field-body').text();
        if($.trim(getContext).length == 0){
            $(this).hide();
        }
    });
 }

 export class TabsDesignForDesktop{
    constructor(){
        var _that = this;
        this.autoTabTrigger = this.autoTabTrigger.bind(this);        
        this.start = this.start.bind(this);
        this.scrollValue = [];
        this.incValue = 500;
        this.oWidth = [];
        // setTimeout(function(){
        //     _that.start('ready');
        // },4000);
        this.start('ready');
        
        //console.log('con....');
        $(window).resize(func.debounce(function(){
            _that.start('resize');
        },200));
    }
    start(doc){
        var _that = this;
        if( $(".tabs .tabs-heading").length > 0 ){            
            if(doc == 'ready'){
                $(".tabs .tabs-heading").each(function(idx){
                    //console.log(idx);
                    var _copyElem = $(this).clone().html();
                    $(this).addClass('tabs-heading-shadow shadow-'+idx);
                    var _html = "<div class=\"tabs-overflow-desktop tod-"+idx+"\">";
                    _html += _that.leftButton();
                    _html += '<ul class="tabs-heading">'+_copyElem+'</ul>';
                    _html += _that.rightButton()+'</div>';
                    $(_html).insertAfter($(this));
                    _that.scrollValue.push(0);

                    $(".tod-"+idx+" .tabs-heading li").click(function(){
                        $(".tod-"+idx+" .tabs-heading li.active").removeClass('active');
                        $(this).addClass('active');
                        _that.autoTabTrigger($(this).index(),idx);
                    });            
                    $(".tabs-overflow-desktop.tod-"+idx+" button").click(function(e){
                        e.preventDefault();
                        _that.btnClick($(this),idx);
                    }); 
                    $(".tabs-overflow-desktop.tod-"+idx+" .tabs-heading").scroll(function(){
                        _that.manageButtons(idx);
                    });
                });                
            } 
            if(doc == 'resize'){
                _that.oWidth = [];
            }
            _that.scrollValue.forEach(function(cv,i,arr){
                _that.oWidth.push($(".tod-"+i).outerWidth());
                _that.manageButtons(i);
            });   
            if(doc == 'ready'){
                _that.watchCount(_that.scrollValue.length);
            }
            
        }
    }
    watchCount(elems){
        var _that = this;
        var callCount = 0;
        var repeater = setInterval(function(){
            if (callCount < 8) {
              _that.updateCount(elems);
              callCount += 1;
            } else {
              clearInterval(repeater);
            }
        }, 1000);
    }
    updateCount(elems){        
        for(var i=0; i<elems; i++){
            $(".shadow-"+i+" .results-count").each(function(idx){
                $(".tod-"+i).find(".results-count").eq(idx).html($(this).html());
            });
        }
    }
    btnClick(elem,idx){
        if(elem.hasClass("prev")){
            this.scrollValue[idx] -= this.incValue;
        } else {
            this.scrollValue[idx] += this.incValue;
        }       
        $(".tod-"+idx+" .tabs-heading").animate({
            scrollLeft: this.scrollValue[idx]
        });
    }

    getPosition(idx){
        return $(".tod-"+idx+" .tabs-heading").scrollLeft();
    }
    
    manageButtons(idx){
        var _that = this;
        var _prefix = ".tod-"+idx;        
        $(_prefix+" .overflow-carousel__button").css('display','none'); 
        var _noBtn = true; 
                  
        if(_that.getPosition(idx) > 0){
            $(_prefix+" .overflow-carousel__button.prev").css('display','block');
            _noBtn = false;
        }
        var scrollWidth = $(_prefix+" .tabs-heading").get(0).scrollWidth; 
        //console.log(_that.getPosition(idx),_that.oWidth[idx],scrollWidth);
        if( (_that.getPosition(idx) + _that.oWidth[idx] + 0.5) < scrollWidth){
            $(_prefix+" .overflow-carousel__button.next").css('display','block');
            _noBtn = false;
        }    
        
        if(_noBtn && (func.deviceMode() == 'desktop') ){
            $(".shadow-"+idx).css('height','auto');
            $(_prefix).css('display','none');            
        }  
        
    }
    autoTabTrigger(i,idx){
        $(".tabs-heading-shadow.shadow-"+idx+" li").eq(i).click();
    }
    leftButton(){
        return '<button type="button" class="overflow-carousel__button prev" aria-controls="overflow-carousel__default-prev-btn" aria-label="See previous items"><span class="btn__inner"><svg focusable="false" viewBox="0 0 24 24" class="svg-icon btn__icon" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="m8.4004 12.0007 5.785 5.7857 1.4143-1.4141-4.3711-4.3716 4.3711-4.3717-1.4143-1.4142-5.785 5.7859z"></path></svg></span></button>';
    }
    rightButton(){
        return '<button type="button" class="overflow-carousel__button next" aria-controls="overflow-carousel__default-next-btn" aria-label="See next items"><span class="btn__inner"><svg focusable="false" viewBox="0 0 24 24" class="svg-icon btn__icon" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="m15.5996 12.0007-5.785 5.7857-1.4143-1.4141 4.3711-4.3716L8.4003 7.629l1.4143-1.4142 5.785 5.7859z"></path></svg></span></button>';
    }
 }

 export class AssetsDocuments{
     constructor(){
        this.elem = $(".media-asset-library.download-press");
        this.totDocs = 0;
        this.totMedia = 0;
        this.clicks = 0;
        this.defItems = 12;
        this.showNext12 = this.showNext12.bind(this);
        this.noMore = this.noMore.bind(this);
        const islen = this.elem.length;
        if(islen > 0)this.start();
     }
     start(){  
        if( (this.elem.find("ul").length > 0) || !func.checkPreviewMode() ){
            this.elem.find("h2").css('display','block');
        }       
        this.totDocs = this.elem.find("ul > li.document-asset").length;
        this.totMedia = this.elem.find("ul > li.media-asset").length;
        for(var i = (this.totDocs+1); i <= (this.defItems + this.totDocs); i++){
            this.elem.find('ul > li:nth-child('+i+')').css('display','flex');
        }
        if(this.totMedia > this.defItems){
            this.elem.find('ul').append(this.renderShowMore());
            $(".media-asset-library.download-press .load-more").click(this.showNext12);
            this.noMore();
        }
     }
     showNext12(){
        this.clicks++;
        var upto = this.totDocs+this.defItems+(this.clicks * this.defItems);
        for(var i = 1; i <= upto; i++){
            this.elem.find('ul > li:nth-child('+i+')').css('display','flex');
        }
        this.noMore();
     }
     noMore(){
        const isNone = $( ".media-asset-library.download-press ul > li:hidden" ).length;
        if(isNone == 0)$( ".media-asset-library.download-press .load-more").css('display','none');
     }
     renderShowMore(){
        return '<div class="component load-more">'+
            '<div class="component-content">'+
            '<div class="ikea-loader column-content">'+
            '<div class="wrap">'+
            '<div class="ball"></div>'+
            '<div class="text">Loading your results</div>'+
            '</div>'+
            '</div>'+
            '<input type="button" value="Show more">'+
            '</div>'+
            '</div>';
     }
}


//Option to image download US sprint-145
export function getHref(_this,e){
    e.preventDefault();
    var hrefValue = _this.attr('href');
    var imageNameWithQuery = hrefValue.substring(hrefValue.lastIndexOf('/') + 1);
    var imageName = imageNameWithQuery.split('?')[0];
    var imgFormates = /\.(jpg|jpeg|png|gif|svg)(\?.*)?$/i;
    var newImageName;
    if(imgFormates.test(imageName)){
        newImageName = imageName.replace(/-\w+\./, '.');
    }else{
        newImageName = imageName;
    }
    // _this.attr('href', hrefValue.replace(imageNameWithQuery, newImageName));
    //_this.attr("download", newImageName);

    /* new function for Content-Disposition overriding download image */

    fetchDownloadBlob(hrefValue, newImageName);
}

//Fetch the image as a blob to bypass the Content-Disposition header and trigger download
function fetchDownloadBlob(hrefValue, newImageName){
    fetch(hrefValue)
    .then(response => {
        if(!response.ok){
            throw new Error(`HTTP error Status: ${response.status}`)
        }
        return response.blob();
    })
    .then(blob => {
        //create temp blob url
        const blobUrl = URL.createObjectURL(blob);
        //temp anchor tag
        const tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.download = newImageName;
        //Append link to DOM
        document.body.appendChild(tempLink);
        //click the link to trigger
        tempLink.click();
        document.body.removeChild(tempLink);
        //Remove blob url to free memory
        URL.revokeObjectURL(blobUrl);
    })
    .catch(error => console.error('Error downloading:',error));
};

export function closeImgPopup(){    
    $(".ikea-img-download-popup").hide();
    $("body").css({"overflow":"auto","padding-right":"0px"});
}
function closeBtn(){
    $('.ikea-modal-dialog.side-panel').find('.image-download-info').hide();
}
$(document).on('click','.imgDownload-dialog.ikea-img-download-popup',function(e){
       if($(this).hasClass('imgDownload-dialog') === $(e.target).hasClass('imgDownload-dialog')){
            closeImgPopup();
       }
});
$(document).on('click','.imgDownload-dialog.ikea-img-download-popup',function(e){
    e.stopPropagation();
});

export function imageDownload(){
    $(document).ready(function() {
        $('.show-download-btn:not(\".slide-show, .serp-page\")').each(function(indx){
            var indx = indx;
            var _that = $(this);
            var hasSPanel = $(this).parents('.ikea-modal-dialog').hasClass('side-panel');
              _that.find('.figure-image__caption-wrapper .figure-image__download a.download').click(function(e){
                if(hasSPanel == true){
                    _that.find('.imgDownload-dialog').removeClass('ikea-img-download-popup').addClass("showContent");
                    _that.find(".imgDownload-dialog .modal-body").removeClass('modal-body');
                    _that.find(".imgDownload-dialog .modal-header").hide();
                }
                if(localStorage.getItem('useRestriction') === 'true'){
                    getHref($(this),e);
                    showToast('Downloading...');
                }else{
                    e.preventDefault();
                    if(hasSPanel == true){
                        _that.find(".imgDownload-dialog").show().css("display","block");
                        _that.find(".imgDownload-dialog  .image-download-info").show().css("display","block");
                    }else{
                        _that.find(".ikea-img-download-popup.imgDownload-dialog").show().css("display","flex");
                        $("body").css('overflow','hidden');
                    }
                }
                    
                 _that.find('.figure-image__caption-wrapper .figure-image__download > .imgDownload-dialog .btn-down > a').click(function(e){
                    var isCheck = _that.find('.figure-image__caption-wrapper .figure-image__download > .imgDownload-dialog .gdpr-text #donotAsk').is(':checked');
                    if(isCheck === true){
                        localStorage.setItem('useRestriction', true);
                    }
                    getHref($(this),e);
                    showToast('Downloading...');
                    if(hasSPanel == true){
                      closeBtn();
                    }else{
                        closeImgPopup();
                    }
                });
            });
    
            $(this).find(".imgDownload-dialog .backArrow").click(function(){
                closeBtn();
            });
            

       });
    });
};



