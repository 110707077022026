import 'raf/polyfill';
import $ from 'jquery';

//import ReadingIndicator from './components/reading-indicator';
import StickyNavbar from './components/sticky-navbar';
import NavMenu from './components/nav-menu';
import NavMenuCollapse from './components/nav-menu-collapse';
import { initAccordions } from './components/accordion';
import { initZoomOnHover } from './components/zoom-on-hover';

import ContentNavigation from './components/content-navigation';
import SearchInput from './components/search-input';
//import { IfObservable } from 'rxjs/observable/IfObservable';
import { initSocialLinks } from './components/social-sharing';
import { initThreeFactCircles, startCircleAnimation } from './components/isInViewPort';
import { initBarGraphs } from './components/integrate-bar-graphs';
import { initCloudinary } from './components/cloudinary';
import { initSideNavigation } from './components/side-navigation';
import { initOurYearInReview } from './components/ouryear-in-review';
import { initNewsroom, initValueConvertKB, tabAutoCenterPill, imageDownload } from './components/newsroom';
import { initFeaturedNewsColumns } from './components/featured-news';
//import { initValueConvertKB } from './components/featured-news';
import { initSubscription } from './components/subscription';
import * as global from './globalvalues';
import EventBanner from './components/event-banner';
import * as func from './components/onlyExport';
import DonutChart from './components/donut-chart';
import BackToTop from './components/back-to-top';
import LikeShare from './components/like-share';
import MarketingArea from './components/marketing-area';
import VbsPage from './components/vbs-page';
import { SingleEvent } from './components/single-event';
import { SlideShow, NavigationCarousel } from './components/slide-show';
import { MembersPage, RecentlyMembers } from './components/memberspage';
import HorizontalNav from './components/horizontal-nav';
import * as exportFun from './components/exportFunctions';


// redefine .html function
(function ($) {
  var htmlOriginal = $.fn.html;  
  $.fn.html = function(html,callback){
    var ret = htmlOriginal.apply(this, arguments);
    if(typeof callback == "function"){
      setTimeout(callback,1000);
    }
    return ret;
  }
})($);
// redefine .html function -- END

var searchPage = $('.search-page-main-body').length;
$(document).ajaxComplete(function(e,xhr,options){
    if(searchPage == 1){
        var arrayList = xhr.responseJSON.Results;
        if(arrayList.length == 0){
            $(".search-page-main-body").find('.no-results').html('');
        }   
    }
});

// IKEA today migration improvements
function blockQuote(){
  $(".ikea-today-migration blockquote").each(function(){
      var leftColon = '“';
      var rightColon = '”';
      var isP = $(this).find("p").length;
      if(isP == 0){
          var noP = $(this).text().trim();
          var isExist = noP.charAt(0);
          if(isExist != leftColon){
            $(this).html(leftColon+noP+rightColon);
          }          
      } else if(isP == 1){
          var firstP = $(this).find("p").text().trim();
          var isExist = firstP.charAt(0);
          if(isExist != leftColon){
            $(this).find("p").html(leftColon+firstP+rightColon);
          }
      } else if(isP > 1){
          var firstP = $(this).find("p:first").text().trim();
          var isExist = firstP.charAt(0);
          if(isExist != leftColon){
            $(this).find("p:first").html(leftColon+firstP);
          }
          var lastP = $(this).find("p:last").text().trim();
          var isExist2 = lastP.charAt(0);
          if(isExist2 != rightColon){
            $(this).find("p:last").html(lastP+rightColon);
          }
      }
  });
}

/* play videos on viewport only *****/ 
/* 
  document.addEventListener('DOMContentLoaded', function() {
   const videos = document.querySelectorAll('video');
   const observer = new IntersectionObserver((entries) => {
   entries.forEach(entry => {
   if (entry.isIntersecting) {
   entry.target.play();
   } else {
   entry.target.pause();
   }
   });
   });
 videos.forEach(video => {
 observer.observe(video);
 });
}); 

*/
/* play videos on viewport only --  END  *****/ 


function playPauseVideo() {
  let videos = document.querySelectorAll(".video-content");
  videos.forEach((video, i) => {
    var video = videos[i].children[0];
    let playPromise = video.play();
    let playState = null;
    if (playPromise !== undefined) {
      playPromise.then((_) => {
        let observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!entry.isIntersecting) {
                video.pause();
                playState = false;
              } else {
                video.play();
                playState = true;
              }
            });
          },
          { threshold: 0.2 }
        );
        observer.observe(video);
      });
    }
  });
}

function compareClrs(){
  $(".hsl").each(function(){
    var _clrHash = '#'+func.urlParam('clr').get; //$(this).attr('bg-clr');
    var clr2 = func.urlParam('clr').get;
    console.log(_clrHash, clr2);
    var _inc = 0;
    var _b2 = func.lightDarkColor(_clrHash,0);
    $(this).find('div').each(function(i){
      if(i == 0){
        $(this).css('background-color', func.lightDarkColor(_clrHash,0) ).text(func.lightDarkColor(_clrHash,0));
      } else if(i ==1){
        $(this).css('background-color', func.lightDarkColor(_clrHash,30) ).text(func.lightDarkColor(_clrHash,30));
      } else if(i ==2){
        $(this).css('background-color', func.lightDarkColor(_clrHash,60) ).text(func.lightDarkColor(_clrHash,60));
      } else if(i ==3){
        $(this).css('background-color', func.lightDarkColor(_clrHash,95) ).text(func.lightDarkColor(_clrHash,95));
      }      
    });    
  });
}

// after loading images

// Grid layout screen width scroll bar issue
// function resetScrnWidthComp(){
//   var $elems = $(".image-banner, .video-banner, .column-container, .img-carousel-container, .ikea-card-group.ikea-card-sixty-forty-layout:not(.column-fullbleed), .background");
//   $elems.removeClass('scrbarIssue');
//   if(func.getScreenWidth() > func.getViewPortWidth() ){
//     $elems.addClass('scrbarIssue');
//   }
// }
// $(window).resize(func.debounce(resetScrnWidthComp,300));
// Grid layout screen width scroll bar issue

function searchPageDivider(){
  if( $(".search-page .navigation-carousel").length > 0){
    $("<div class='component divider column-wide'></div>").insertBefore( $(".search-page .navigation-carousel") );
  }
}

// Initialize side nav menu
$(document).ready(function() {
  searchPageDivider();
  $(".component").each(function(){
    if( $(this).find('div').length == 1 && $(this).find('.number-circle').length == 1){
      $(this).addClass('nc-top-margin');
    }
  });

  const mArea = new MarketingArea();

  // search-results news-topics-results entrepreneur-members
  if( $(".search-results.entrepreneur-members").length > 0){
    const rjm = new RecentlyMembers();
  }
  
  //compareClrs();
  func.colorPicker();
  playPauseVideo();
  // Close menu functionality on Search Popup 
  var _mobSrcHtml = '<div class="component-content">'+$(".js-search-mobile-menu").html()+'</div>';
  //$(".js-search-mobile-menu").html(_mobSrcHtml);
  var _tabHtmlNav = "<div class='"+$(".js-search-mobile-menu").attr('class')+" for-tablet'>"+_mobSrcHtml+"</div>";
  $(_tabHtmlNav).insertAfter(".js-search-mobile-menu");
  const singleEve = new SingleEvent();
  // End - Close menu functionality on Search Popup 

  func.socialMinisite();
  func.imageAutoSlider();
  exportFun.animatedScrollArrow();
  //resetScrnWidthComp();

  $(window).resize(func.debounce(func.socialMinisite,200));
  if(window.location.pathname.toLowerCase().includes('/life-at-home/behind-the-scenes')){
    $("li.level1.active").removeClass('active');
    var _bts = "Behind the Scenes";
    $("li.level1").each(function(){
      if( $(this).find('a').attr('title').toLowerCase() == _bts.toLowerCase() ){
        $(this).addClass('active');
      }
    });
  }
  //Crossposting US
  // $(document).on('click', '.articles-btn', function(){
  //   func.callAjax(
  //     'https://preview.dev.aboutikea.com',
  //     {scController: "NewsArticles", scAction: "GetNewsArticles"},
  //     'json',
  //     'POST',
  //     function(res,data){
  //       $(".artciles-list").html(data);        
  //     }
  //   );
  // });
  //Crossposting US -- END
  // if(window.location.host.includes('ikeasocialentrepreneurship.org')){
  //   $(".expand-serach-box .tt-menu .search-looking-something").css('display','none');
  // }

  if($(".breadcrumb").length == 0){
    $("main").addClass("add-margin");
  }
  // show live event banner
  const eventBanner = new EventBanner({
    liveEvent : '.source-event-banner'
  });
  eventBanner.start();

  // Members Page
  if(typeof studioConfig != 'undefined'){
    const memsPage = new MembersPage();
  }

  // Donut Chart
  // Added video control 
  if(func.checkPreviewMode()){

    // $(".content").each(function(){
    //   if( $(this).find('.component-content > div').length == 0 && $(this).find('.component-content > span').length == 0 && $(this).find('.component-content > article').length == 0){
    //     $(this).css('display','none');
    //   }
    // });
    // Hotfix for empty content div - sprint 138
    $('.component-content').each(function(){
      if($(this).find('*').length == 0 && $(this).text().trim().length == 0 ){
        $(this).parent().addClass('dis_none');
      }
     });
     $('.content.dis_none, .container.dis_none').each(function(){
      $(this).css('display','none');
     });
     // Hotfix end --- for empty content div

    if( $('.component.title').length > 0 && $('.breadcrumb').length > 0){
      if( ($('.component.title').offset().top) - ($('.breadcrumb').offset().top) < 50){
        $('.component.title').addClass('mt-gap');
      }
    }
     

    //Video file code paly/pause button - Sprint 116
    var videoElement = document.querySelectorAll('.video-content');
    $(videoElement).each(function (i) {
      $(this).on("click", function () {
        var video = $(this).find('.looped-video__video').get(0);
        var svgIcon = $(this).find(".btn-video-icon .btn-inner > svg > path");
        if (video.paused == false) {
          video.pause();
          svgIcon.attr("d", "M19 12 7 19V5l12 7z");
        } else {
          video.play();
          svgIcon.attr("d", "M5.998 4.996h4v14.0001h-4v-14zm8 0h4v14.0001h-4v-14z");
        }
        return false;
      })
    });
    //Video file code paly/pause button -- END
    const donutChart = new DonutChart();
    donutChart.start();
    const oVbsPage = new VbsPage();
    new SlideShow();
    new NavigationCarousel();
    new HorizontalNav();
  } 

  // Back to Top
  const btt = new BackToTop();
  const likeShare = new LikeShare();
  
  // search filter - auto click filter button
  // filxed filter issue - added code in component search results filter , sitecore js file 
  // $(document).on('click', '.contentContainer .facet-value', function(){
  //   //$(".filterButton").click();
  // });

  // remove elements for empty child elements
  var emptyElem = ['.container .background > .component-content'];
  emptyElem.forEach(e => {
    $(e).each(function(i){
      if( $(this).children().length == 0){
        $(this).closest('.component').css('display','none');
      }
    });    
  });
  
  //initNotification();
  blockQuote();

  if( $('#cookiebanner').is(':visible') ){
    cookieBannerImprovements();
  }
  if( $(".featured-news").length > 0){
    var isEmpty = $.trim($(".header-banner__title").text()).length;
    if(isEmpty == 0){
        $(".header-banner__text-only").css('display','none');
    }        
  }

  $(".no-results").html(global._loaderHtml);
  consoleLog();
  const navMenu = new NavMenu({
    navbar: '.js-navbar',
    sectionsNav: '.js-navbar-sections',
    subsectionsNav: '.js-navbar-subsections',
    toggleMenu: '.js-navbar-toggle-menu',
    toggleSearch: '.js-search-toggle-menu',
    mobileMenu: '.js-navbar-mobile-menu',
    searchMenu: '.js-search-mobile-menu',

    focusTrap: '.js-navbar-focus-trap',
    backdrop: '.js-nav-mobile-backdrop',
    

    primaryNavLabel: 'nav-label-primary',
    secondaryNavLabel: 'nav-label-secondary'
  });
  navMenu.start();
  navMenu.ifMoreItems();

  // Make navbar sticky
  const stickyNavbar = new StickyNavbar({
    navbar: '.js-navbar'
  });
  stickyNavbar.start();
  
  updateYearinFooter();
  //setVideoThumbnail();

  $(document).on('click', '.js-cookie-accept, .js-cookie-settings-close, .js-cookie-necessary', function(){
    //$("body").css('overflow','auto');
  });
  $(document).on('click', '.js-cookie-settings, a.change-cookie-settings', function(elem){
    //setTimeout(cookieBannerImprovements,500);
  });

 
  // on exp editor, hghlight gear icon
  if(!func.checkPreviewMode()){
    //expEdiBanner();
    $(".cspace-section").removeClass('cspace-section');
    let gearHtml = $(".commandRow").html();
    $("body").append('<div class="position:fixed;top:200;right:10;z-index:999999;border:2px solid red;">'+gearHtml+'</div>');
  }

  /* detect scroll up and down ***/ 
  // As per discussion, no need. Sprint 140
  // var lastScrollTop = 0, delta = 5;
  // $(window).scroll(function(){
  //     var nowScrollTop = $(this).scrollTop();
  //     if(Math.abs(lastScrollTop - nowScrollTop) >= delta && func.deviceMode() == 'mobile'){
  //         if (nowScrollTop > lastScrollTop || nowScrollTop <= 10){
  //             //navMenu.togMemberLogin('none');
  //           $('.navbar.js-navbar .mob-member-login.extends').slideUp("fast",function(){
  //             $('.navbar.js-navbar .mob-member-login.extends').removeClass('only-iseplus');
  //           });
  //         } else {
  //           $('.navbar.js-navbar .mob-member-login.extends').slideDown("fast",function(){
  //             $('.navbar.js-navbar .mob-member-login.extends').addClass('only-iseplus');
  //           });
  //         }
  //         lastScrollTop = nowScrollTop;
  //     }
  // });
  /* END --- detect scroll up and down ***/ 
  rtTableAlign();
  //fetchAPI();
});

function fetchAPI(){
  console.error('Fetch...');
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ title: 'Fetch PUT Request Example' })
  };
  fetch("likes.json/article1",requestOptions)
  .then(response => response.json())
  .then(data => console.log(data))
  console.error('Fetch...end');
}

function rtTableAlign(){ 
  $('.rich-text table.ipw-ikea-table').each(function(){
    var _this = $(this);
    $(this).find('tr th.left-align-col').each(function (index){
      _this.find('tr td:nth-child(' + ($(this).index() + 1) + ')').addClass('left-align-col');
    });
    $(this).find('tr th.right-align-col').each(function (index){
      _this.find('tr td:nth-child(' + ($(this).index() + 1) + ')').addClass('right-align-col');
    });
  });
 }

function cookieBannerImprovements(){  
    var _isActive = $("#cookiebanner .modal > .modal__tab:eq(0)").hasClass('active');
    if(_isActive){
      $("#cookiebanner .modal").css('top','unset');
    } else {
      $("#cookiebanner .modal").css('top',0);
    }
    $("body").css('overflow','hidden');  
    //$(".js-cookie-accept .btn__label").text('I accept');
}

// setVideoThumbnail 
// function setVideoThumbnail(){
//   document.querySelectorAll('video').forEach((v) => {
//     v.addEventListener("playing", function() {
//         if( $(v).hasClass('playat0') ){
//             v.currentTime = 0;
//             $(v).removeClass('playat0');
//         }
//     });            
//   });
// }

// Initialize nav collapsible elements
$(document).ready(function() {
  [
    { selectors: { menu: '.js-navbar-mobile-menu' }, showActive: true },
    { selectors: { menu: '.js-search-mobile-menu' }, showActive: true },
    { selectors: { menu: '.js-site-nav-footer' }, showActive: false }
  ]
    .map(config => new NavMenuCollapse(config))
    .forEach(navMenuCollapse => navMenuCollapse.start());

  //Initialize scrolling animation for Content Navigation
  const contentNavigation = new ContentNavigation({
    selectors: { root: '.js-content-navigation' }
  });
  contentNavigation.start();

  pocTiles();

});

function pocTiles(){
  if( $(".height-adjustable-container").length > 0 ){
    $(".column-placeholders > div").each(function(){
      let getPerc = $(this).data('height');
      console.log(getPerc);
      $(this).find('div:first').css('height',getPerc+'%');
      $(this).find('div:first').next().css('height',(100 - parseInt(getPerc))+'%');
    });
  }
}

function expEdiBanner(){
  if( $(".experience-editor-banner").length > 0 ){
    $(".experience-editor-banner").clone().prependTo($("#wrapper"));
    $(".experience-editor-banner")[1].remove();
    $(".experience-editor-banner").addClass('show');
  }
}

// Initialize accordions
initAccordions({
  selectors: {
    accordion: '.js-accordion',
    item: '.js-accordion-item',
    button: '.js-accordion-button',
    panel: '.js-accordion-panel',
    icon: '.js-accordion-icon'
  }
});

// Initialize social sharing links
initSocialLinks();


// Initialize zoom on hover
initZoomOnHover({
  widgetRoot: '.js-zoom-on-hover',
  hoverArea: '.js-zoom-on-hover-trigger',
  target: '.js-zoom-on-hover-target'
});

//Enable search field behavior
$(document).ready(function() {
  var searchInput = new SearchInput({
    rootClass: '.input-wrap',
    //the .tt- prefix comes from the Typeahead.js library
    //Unable to use input class due to conflict with typeahead
    inputId: '.tt-input',
    clearClass: '.js-search-clear',
    filledClass: 'input-wrap--labelled',
    placeholderClass: '.input-wrap__placeholder',
    focusClass: 'input-wrap--focus'
  });
  searchInput.start();
});

function showInEditor(){
  if (window.location.href.indexOf("sc_mode=edit") > -1) {
    $('.table-mobile').css('display', 'block');
  }
}


$(document).ready(function(){
  //column-content class added for width 800px in power messages block and removed column-wide with 1024px class
  $('p.power-message').parent().parent().addClass('column-content').removeClass('column-wide');

  showInEditor();

  //Detect links with .js-external as external links
  $('.js-external > a').attr('target','_blank');
  // image flickering -- bug
  function ifIE(){
    if (document.documentMode || /Edge/.test(navigator.userAgent)) 
    {
      document.querySelector(".navbar__brand img").style.transition = "height 0s";
    }      
  }    
  ifIE();
  // image flickering -- bug -- end

  //cookie boat realted second tab open
  $(".change-cookie-settings").click(function(){
    // Cookiebot.renew();
    // setTimeout(function(){
    //   $('.js-cookie-settings').click();
    //     console.log("cookie-changed");
    // },550) 
    if( $(".onetrust-pc-dark-filter").length > 0 ){
      $(".onetrust-pc-dark-filter.ot-hide, #onetrust-pc-sdk.ot-hide").removeClass("ot-hide");
      $(".onetrust-pc-dark-filter, #onetrust-pc-sdk").removeAttr('style');
      $("#onetrust-pc-sdk.max-h").removeClass("max-h");
      if($(".onetrust-pc-dark-filter").is(":visible")){
        ///$("#onetrust-pc-sdk").addClass("max-h"); //142 change
      }
    }
  });

  // append classes to a tag - btn components
  linkWithIcon();
});

// $(document).on('DOMNodeInserted', '.table-mobile', function () {
//   showInEditor();
// });
/***   DOMNodeInserted issue on browser console */
// document.addEventListener('DOMContentLoaded', function() {
//   var observer = new MutationObserver(function(mutations) {
//     console.log(mutations);
//   });

//   observer.observe($(".table-mobile"), {
//     childList: true,
//     subtree: true
//   });
// });
/***   END ----- DOMNodeInserted issue on browser console */

function linkWithIcon(){
  $("div.link-component-with-icon").each(function(){
    var _that = this;
    var classList = $(this).attr('class').split(/\s+/);
    var svgUrl = '<i><svg class="svg-icon"><use xlink:href="/-/media/Themes/NL/AboutIKEA/AboutIKEA/svg/symbol/svg-sprite.svg#SVG_ICON_TYPE"></use></svg></i>';
    //var svgUrl = '<i><svg class="svg-icon"><use xlink:href="/assets/aboutikea/svg/symbol/svg-sprite.svg#SVG_ICON_TYPE"></use></svg></i>';
    
    var leftORright = '';
    $(classList).each(function(){      
      if( (this.indexOf('sprite-icon-')) == 0 ){
        leftORright = this.split('-');
        linkWithIconSub(this,_that);
      }      
      else if( (this.indexOf('btn--')) == 0){
        linkWithIconSub('btn ' + this,_that);
      }
      else if( (this.indexOf('ikea_')) == 0 && (this.indexOf('_spriteicon')) > 0  ){
        var getIconType = this.replace('ikea_','').replace('_spriteicon','');
        var htmlOfAtag = svgUrl.replace('SVG_ICON_TYPE',getIconType);
        linkWithIconSub(this,_that,htmlOfAtag,leftORright[2]);
        leftORright = '';
      }
    });
  });
}

function linkWithIconSub(dis,dat,icon,leftORright){
  $(dat).find('a').addClass(dis);
  $(dat).removeClass(dis);
  // console.log(icon);
  if(icon != null || typeof(icon) != 'undefined'){
    var getText = $(dat).find('a').text().trim();
    $(dat).find('a').html(getText+icon);
    if(leftORright == 'left'){
      $(dat).find('a').html(icon+getText);
    }
  }  
}



//Remove Underlink for standalone link
$(document).ready(function(){
  var anchorTagUnderline = $('.rich-text, .js-accordion-panel').find('p, div, ul li');
  $(anchorTagUnderline).each(function(){
    var getHtmlWithText = $(this).html().trim();
    if((getHtmlWithText.substr(0,2) == '<a' && getHtmlWithText.substr(-4) == '</a>') || (getHtmlWithText.substr(0,6) == '<span>' && getHtmlWithText.substr(-7) == '</span>')){
      $(this).addClass('link-nounderline');
    }
  });
});

//Set the year in footer
function updateYearinFooter(){  
  var dt = new Date();
  // console.log('Year: '+dt.getFullYear());
  $(".footer__copyright .getYear").html(dt.getFullYear());
}

function consoleLog(){
  var hostName = window.location.hostname;
  var envs = ['localhost','tst'];
  var isConsole = false;
  envs.forEach( (env) => {
    if(hostName.search(env) >= 0)isConsole = true;
  });
  if(!isConsole){
    console.log = function(){};
  }
}

/* ---- test newsletter ---- */
function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}
function btnToggleAnalytics(className,prop){
  document.getElementsByClassName(className).style.display = prop;
}
var cookieStatistics = getCookie('CookieConsent');
if(cookieStatistics != null){
  var isCheckedStatistics = cookieStatistics.indexOf('statistics:true');
}
if(isCheckedStatistics === -1){
  $(".test-sitecore-btn").hide();
  $(".test-btn").show();   
} else {
  $(".test-sitecore-btn").show();
  $(".test-btn").hide();
}
/*test modal popup end*/

/* -- three fact circles ---*/
const factCircles = document.querySelectorAll(".fact-circle-border-animation .animate-circle");
factCircles.forEach(factCircle => {  
  startCircleAnimation(factCircle,0,'0s');
});
initThreeFactCircles();
/* -- three fact circles - END ---*/

initCloudinary();

/* integrate graph */
initBarGraphs();

/* Content Navigation */
initSideNavigation();

initOurYearInReview();

initNewsroom();

initFeaturedNewsColumns();

tabAutoCenterPill();

imageDownload();


initSubscription();
