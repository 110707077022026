import $ from 'jquery';
import * as func from '../components/onlyExport';

class EventBanner {
    constructor(selectors) {
        this.elem = selectors.liveEvent;
        this.sdt = "";
        this.edt = "";
        this.ihb = [];
        this.hideBanner = this.hideBanner.bind(this);
        this.hideHeaderBanner = this.hideHeaderBanner.bind(this);
        this.resetBreadcrumb = this.resetBreadcrumb.bind(this);
        this.alterElems = ["#wrapper > header",".nav-mobile",".js-search-mobile-menu"];
        if(typeof(Storage) == "undefined") {
            console.error("Sorry! Your browser doesn't support Web Storage.");
        }
        $(window).resize(this.resetBreadcrumb);
    }
    start(){ 
        var _that = this; 
        if( $(this.elem).length != 0){
            this.sdt = this.convertTime( ($(this.elem +" .event-startdate").text()) + " " + ($(this.elem +" .event-starttime").text()));
            var noEndTime = $(this.elem +" .event-enddate").text().trim().length;
            this.edt = this.convertTime( ($(this.elem +" .event-enddate").text()) + " " + ($(this.elem +" .event-endtime").text()));
            var cTime = this.getCurrentUTCtime();
            if( (this.sdt <= cTime && cTime <= this.edt) || ( this.sdt <= cTime && noEndTime == 0))
                this.showBanner($(this.elem));
        }
        if($(".source-header-banner .banner-content").length > 0){
            var _isExistArr = ( typeof(sessionStorage.header_banner) != 'undefined' ) ? JSON.parse(sessionStorage.header_banner) : [];
            if( typeof(sessionStorage.header_banner) == 'undefined' || (_isExistArr.length != $(".source-header-banner .banner-content").length) ){
                for(var idx=0; idx < $(".source-header-banner .banner-content").length; idx++){
                    this.ihb[idx] = "on";
                }
                sessionStorage.header_banner = JSON.stringify(this.ihb);
            }
            $(".source-header-banner .banner-content").each(function(i){
                _that.showHeaderBanner($(this),i);
            });
            $(".ikea-header-banner svg, .ikea-header-banner a").click(_that.hideHeaderBanner);
        }
    }
    getCurrentUTCtime(){
        var d = new Date();  
        var nd = this.addHoursToDate(d,1); // for cet time zone
        var _ct = new Date(nd.getUTCFullYear(),nd.getUTCMonth(),nd.getUTCDate(),nd.getUTCHours(),nd.getUTCMinutes(),nd.getUTCSeconds());
        return _ct.getTime();
    }

    addHoursToDate(date, hours) {
        return new Date(new Date(date).setHours(date.getHours() + hours));
    }

    convertTime(d){
        var dSplit = d.split(" ");
        var _date = dSplit[0].split("/");
        var _Time = dSplit[1].split(":");       
        var oDate = new Date(_date[2],(_date[1]-1),_date[0],_Time[0],_Time[1],_Time[2]);
        return oDate.getTime();
    }
    showBanner($elem){
        var _isEvent = this.getSession();
        if( (typeof(_isEvent) == 'undefined') || (_isEvent == "on") ){
            this.setSession("on");
            $(this.loadBanner($elem)).insertBefore(".navbar__main-bar").hide().fadeIn(500);
            $(".event-banner svg, .event-banner a").click(this.hideBanner);
            this.resetBreadcrumb();
        }   
    }
    showHeaderBanner($elem,i){
        var _isEvent = this.getHeaderSession(i);
        if( (typeof(_isEvent) == 'undefined') || (_isEvent == "on") ){
            $(this.loadHeaderBanner($elem,i)).insertBefore(".navbar__main-bar").hide().fadeIn(500);
            this.resetBreadcrumb();
        }   
    }
    hideBanner(){
        this.setSession("off");
        $(".event-banner").slideUp(300);
        setTimeout(this.resetBreadcrumb, 300);        
    }
    hideHeaderBanner(e){
        //const bannerOrder = $(e.target).attr('order');
        const bannerOrder = $(e.target).closest('.ikea-header-banner').attr('order');
        //console.error('banner idx: ', bo)
        this.setHeaderSession("off",bannerOrder);
        $(".ihb-"+bannerOrder+".ikea-header-banner").slideUp(300); //.delay(400).remove();
        setTimeout(function(){
            $(".ihb-"+bannerOrder+".ikea-header-banner").remove();
        }, 500);
        setTimeout(this.resetBreadcrumb, 500); 
    }
    setSession(boolVal){
        sessionStorage.live_event = boolVal;
    }
    getSession(){
        return sessionStorage.live_event;
    }
    setHeaderSession(boolVal,i){
        var _sessArr = JSON.parse(sessionStorage.header_banner);
        _sessArr[i] = boolVal;
        sessionStorage.header_banner = JSON.stringify(_sessArr);
    }
    getHeaderSession(i){
        var _sessArr = JSON.parse(sessionStorage.header_banner);
        return _sessArr[i];
    }
    loadHeaderBanner($elem,i){
        const _whitetheme = ( $elem.find(".white-theme").length > 0) ? " white-theme" : "";
        return '<div class="ihb-'+i+' ikea-header-banner'+_whitetheme+'" order="'+i+'"><div>' + 
            $elem.find(".event-message").html() +
                '</div><div class="close"><a><svg order="'+i+'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path order="'+i+'" d="M12.0001 13.4143L14.8284 16.2426L16.2426 14.8284L13.4143 12.0001L16.2426 9.17179L14.8284 7.75757L12.0001 10.5859L9.17154 7.75732L7.75733 9.17154L10.5859 12.0001L7.75732 14.8287L9.17153 16.2429L12.0001 13.4143Z"></path></svg></a></div></div>';
        
    }
    loadBanner($elem){
        var fontClr = ( $elem.find(".black-font").length != 0) ? " black-font" : "";
        var _bgClr = $elem.find(".banner-bgcolor").css('background-color');
        return '<div class="event-banner'+fontClr+'" style="background-color:'+_bgClr+';"><div>' + 
                $(this.elem + " .event-message").html() +
                '</div><div class="close"><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.0001 13.4143L14.8284 16.2426L16.2426 14.8284L13.4143 12.0001L16.2426 9.17179L14.8284 7.75757L12.0001 10.5859L9.17154 7.75732L7.75733 9.17154L10.5859 12.0001L7.75732 14.8287L9.17153 16.2429L12.0001 13.4143Z"></path></svg></a></div></div>';
    }
    resetBreadcrumb(){
        var reqHeight = ( ($(".event-banner").length == 1) &&  ($(".event-banner").css('display') == 'block' || $(".event-banner").css('display') == 'flex') ) ? $(".event-banner").outerHeight() : 0;
        if( $(".ikea-header-banner").length > 0 ){
            $(".ikea-header-banner").each(function(){
                reqHeight += $(this).outerHeight();
            });
        }
        this.alterElems.forEach(elem => {
            if( $(elem).length > 0){
                $(elem).css('margin-top',reqHeight);
            }
        });
    }
}

export default EventBanner;